import * as React from 'react';
import {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {WorkflowBuilder} from "../automation/WorkflowBuilder";
import {getWorkflowById, updateWorkflow} from "../../reducers/savedWorkflows";
import {useNavigate, useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import {Typography} from "@mui/material";
import {useIntl} from "react-intl";
import _ from 'lodash';
import {createEdges, positionNodes, workflowDefaults} from "../../reducers/newWorkflow";
import useDeepCompareEffect from "use-deep-compare-effect";

const styles = {
    backWrapper: {
        padding: '1rem',
        display: 'flex',
        cursor: 'pointer',
    },
    backText: {
        marginLeft: '8px',
        color: 'textColorPrimary.main',
        fontWeight: 500,
        fontSize: '14px'
    },
};

export const EditWorkflow = () => {

    const intl = useIntl();
    const {workflowId} = useParams();
    const id = parseInt(workflowId);
    const navigate = useNavigate();
    const workflow = useSelector(getWorkflowById(id));
    const dispatch = useDispatch();
    const [workflowState, setWorkflowState] = useState(workflowDefaults);

    useDeepCompareEffect(() => {
        setWorkflowState(_.mergeWith({}, workflowDefaults, workflow, (a, b) => b === null ? a : undefined))
    }, [workflow]);

    if (!workflow.id) {
        navigate('/usecase1/workflow/list');
        return null;
    }

    const resetBuilder = () => {
        setWorkflowState({...workflow});
    };

    const saveWorkflowHandler = () => {
        dispatch(updateWorkflow({id, toUpdate: workflowState}));
        navigate('/usecase1/workflow/list');
    };

    const handleNameChange = event => {
        setWorkflowState({
            ...workflowState,
            name: event.target.value
        });
    };

    const setActive = event =>
        setWorkflowState({
            ...workflowState,
            active: event.target.checked
        });

    const onInit = (builderWidth, onLeafNodeClick) => {

        const nodes = positionNodes(workflowState.nodes, builderWidth)
            .map(node => {
                if (node.type === 'addMoreNode') {
                    return {
                        ...node,
                        data: {
                            onClick: onLeafNodeClick(node.parentId)
                        }
                    }
                }
                return node;
            });

        setWorkflowState({
            ...workflow,
            nodes,
            builderWidth,
        });
    };

    const addNewNodeHandler = (parentId, onLeafNodeClick) => (node, nodeAction) => {
        const nodes = [...workflowState.nodes];
        const counter = workflowState.idCounter + 1;
        const newNodeId = `workflow-node-${counter}`;
        const currentTreeNodeIndex = nodes.findIndex(n => n.parentId === parentId);
        const currentTreeNode = {
            ...nodes[currentTreeNodeIndex],
            parentId: newNodeId,
            data: {
                onClick: onLeafNodeClick(newNodeId)
            },
        };

        const newNode = {
            id: newNodeId,
            type: 'workflowStepNode',
            draggable: false,
            position: {x: 0, y: 0},
            data: {
                name: node.name,
                description: node.description,
                iconFactory: node.iconFactory,
                logoPath: node.logoPath,
                nodeAction
            },
            parentId
        };

        nodes.splice(currentTreeNodeIndex, 1, newNode, currentTreeNode);
        const repositionedNodes = positionNodes(nodes, workflowState.builderWidth);
        const repositionedEdges = createEdges(repositionedNodes);

        setWorkflowState({
            ...workflow,
            nodes: repositionedNodes,
            edges: repositionedEdges,
            idCounter: counter
        });
    };

    return <>
        <Box sx={styles.backWrapper} onClick={() => navigate('/usecase1/workflow/list')} component={'span'}>
            <ArrowBackRoundedIcon fontSize={'small'}/>
            <Typography sx={styles.backText} component={'span'}>
                {intl.formatMessage({id: 'saved_workflows'})}
            </Typography>
        </Box>
        <WorkflowBuilder nodes={workflowState.nodes}
                         edges={workflowState.edges}
                         onInit={onInit}
                         addNewNodeHandler={addNewNodeHandler}
                         saveWorkflowHandler={saveWorkflowHandler}
                         workflowName={workflowState.name}
                         active={workflowState.active}
                         setActive={setActive}
                         setWorkflowName={handleNameChange}
                         resetBuilder={resetBuilder}/>
    </>;
};