import {createSlice} from '@reduxjs/toolkit'

export const savedWorkflowsSlice = createSlice({
    name: 'savedWorkflows',
    initialState: {
        data: []
    },
    reducers: {
        saveWorkflow: (state, action) => {
            const workflow = action.payload;

            let newId = state.data.length;
            let match = false;
            do {
                newId = newId + 1;
                match = state.data.some(wf => wf.id === newId);
            } while (match);

            const workflowWithId = {
                id: newId,
                ...workflow
            };

            state.data.push(workflowWithId);
            localStorage.setItem('fluximaSavedWorkflows', JSON.stringify(state.data));
        },
        updateWorkflow: (state, action) => {
            const {id, toUpdate} = action.payload;

            state.data = state.data.map(wf => {
                if (wf.id === id)
                    return {
                        ...wf,
                        ...toUpdate
                    };

                return wf;
            });
            localStorage.setItem('fluximaSavedWorkflows', JSON.stringify(state.data));
        },
        removeWorkflow: (state, action) => {
            const workflowId = action.payload;
            state.data = state.data.filter(wf => wf.id !== workflowId);
            const localState = localStorage.getItem('fluximaSavedWorkflows');
            if (localState)
                localStorage.setItem('fluximaSavedWorkflows', JSON.stringify(state.data));
        },
        initState: (state, action) => {
            const localState = localStorage.getItem('fluximaSavedWorkflows');
            if (localState)
                state.data = JSON.parse(localState);
        },
    }
});

export const getWorkflowById = id => state => state.savedWorkflows.data.find(wf => wf.id === id) || {};
export const getSavedWorkflows = state => state.savedWorkflows.data;

export const {
    saveWorkflow,
    removeWorkflow,
    initState,
    updateWorkflow
} = savedWorkflowsSlice.actions;
export default savedWorkflowsSlice.reducer;