import * as React from 'react';
import {Collapse, Grid, List, ListItem, Paper, Typography} from "@mui/material";
import {useIntl} from "react-intl";
import Box from "@mui/material/Box";
import {CommonDivider} from "../common/CommonDivider";
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

const styles = {
    paper: {
        boxShadow: 'rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px',
        borderRadius: '1rem',
    },
    activationLabelWrapper: {
        padding: '1.5rem'
    },
    activationLabel: {
        fontWeight: 600,
        fontSize: '18px'
    },
    list: {
        margin: 0,
        padding: 0
    },
    listItem: beforeLast => [
        {
            padding: '12px 24px'
        },
        beforeLast && {
            borderBottom: '1px solid rgb(230, 232, 240)'
        }
    ],
    expandIcon: expanded => ([
        {
            color: 'textColorPrimary.main',
            transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        },
        expanded && {
            transform: 'rotate(-180deg)',
        }
    ]),
    actionPaper: {
        boxShadow: 'none',
        borderRadius: '1rem',
        bgcolor: '#f3f4f6',
        padding: '1rem',
        margin: '1rem 0'
    },
    requestBodyText: {
        fontSize: '12px',
        fontWeight: '600'
    },
    requestBodyParamName: {
        fontSize: '12px',
    }
};

export const ConnectorDetailAvailableActions = props => {

    const {actions} = props;
    const [expandedAction, setExpandedAction] = React.useState('');

    const intl = useIntl();

    const onClick = actionName => () => setExpandedAction(expandedAction === actionName ? '' : actionName);

    const renderBody = body => {
        const bodyEntries = Object.entries(body);
        if (!bodyEntries.length)
            return <ListItem>
                <Typography sx={styles.requestBodyText}
                            component={'span'}>
                    {intl.formatMessage({id: 'body'})}
                </Typography>
                <Typography sx={styles.requestBodyParamName}
                            component={'span'}>
                    &nbsp;&#123;&#125;
                </Typography>
            </ListItem>;
        else
            return <>
                <ListItem>
                    <Typography sx={styles.requestBodyText}
                                component={'span'}>
                        {intl.formatMessage({id: 'body'})}
                    </Typography>
                </ListItem>
                <ListItem disablePadding>
                    <List style={{padding: '0 16px'}}>
                        {bodyEntries.map(([key, value]) =>
                            <ListItem>
                                <Typography sx={styles.requestBodyText} component={'span'}>
                                    {key}
                                </Typography>
                                <Typography sx={styles.requestBodyParamName}
                                            component={'span'}>
                                    &nbsp;{value.type}
                                </Typography>
                            </ListItem>)}
                    </List>
                </ListItem>
            </>;
    };

    return <Paper sx={styles.paper}>
        <Box sx={styles.activationLabelWrapper}>
            <Typography sx={styles.activationLabel}>
                {intl.formatMessage({id: 'available_actions'})}
            </Typography>
        </Box>
        <CommonDivider/>
        <List sx={styles.list}>
            {actions.map((action, index) => <ListItem sx={styles.listItem(index !== actions.length - 1)}>
                <Grid container
                      justifyContent="center"
                      alignItems="center">
                    <Grid item xs={12} container onClick={onClick(action.label)} style={{cursor: 'pointer'}}>
                        <Grid item xs>
                            <Typography>
                                {intl.formatMessage({id: action.label})}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <ExpandMoreRoundedIcon sx={styles.expandIcon(expandedAction === action.label)}/>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Collapse in={expandedAction === action.label}>
                            <Paper sx={styles.actionPaper}>
                                <Grid container
                                      justifyContent="center"
                                      alignItems="center">
                                    <Grid item xs={12}>
                                        <Typography sx={styles.requestBodyText}>
                                            {intl.formatMessage({id: 'request_body'})}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <List>
                                            {Object.entries(action.request.body).map(([key, value]) =>
                                                <ListItem>
                                                    <Typography sx={styles.requestBodyText} component={'span'}>
                                                        {key}
                                                    </Typography>
                                                    <Typography sx={styles.requestBodyParamName}
                                                                component={'span'}>
                                                        &nbsp;{value.type}
                                                    </Typography>
                                                </ListItem>)}
                                        </List>
                                    </Grid>
                                </Grid>
                            </Paper>
                            <Paper sx={styles.actionPaper}>
                                <Grid container
                                      justifyContent="center"
                                      alignItems="center">
                                    <Grid item xs={12}>
                                        <Typography sx={styles.requestBodyText}>
                                            {intl.formatMessage({id: 'response_body'})}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <List>
                                            <ListItem style={{display: 'block'}}>
                                                <Typography sx={styles.requestBodyText}>
                                                    {intl.formatMessage({id: 'success'})}
                                                </Typography>
                                                <List>
                                                    <ListItem>
                                                        <Typography sx={styles.requestBodyText}
                                                                    component={'span'}>
                                                            {intl.formatMessage({id: 'status'})}
                                                        </Typography>
                                                        <Typography sx={styles.requestBodyParamName}
                                                                    component={'span'}>
                                                            &nbsp;{action.response.success.code}
                                                        </Typography>
                                                    </ListItem>
                                                    {renderBody(action.response.success.body)}
                                                </List>
                                            </ListItem>
                                            <ListItem style={{display: 'block'}}>
                                                <Typography sx={styles.requestBodyText}>
                                                    {intl.formatMessage({id: 'error'})}
                                                </Typography>
                                                <List>
                                                    <ListItem>
                                                        <Typography sx={styles.requestBodyText}
                                                                    component={'span'}>
                                                            {intl.formatMessage({id: 'status'})}
                                                        </Typography>
                                                        <Typography sx={styles.requestBodyParamName}
                                                                    component={'span'}>
                                                            &nbsp;{action.response.error.code}
                                                        </Typography>
                                                    </ListItem>
                                                    {renderBody(action.response.error.body)}
                                                </List>
                                            </ListItem>
                                        </List>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Collapse>
                    </Grid>
                </Grid>
            </ListItem>)}
        </List>
    </Paper>;
};