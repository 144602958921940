import * as React from 'react';
import ListRoundedIcon from '@mui/icons-material/ListRounded';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Badge from '@mui/material/Badge';
import Box from "@mui/material/Box";
import {Typography} from "@mui/material";
import {useIntl} from "react-intl";
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';

const styles = {
    appBar: {
        bgcolor: 'white',
        boxShadow: 'rgb(100 116 139 / 12%) 0px 1px 4px'
    },
    toolbar: {
        alignItems: 'center'
    },
    notifications: {
        color: 'textColorPrimary.main',
        '& span.MuiBadge-badge': {
            bgcolor: 'violet.main',
            color: 'white',
        },
    },
    signOutWrapper: {
        display: 'flex',
        marginRight: '10px',
        cursor: 'pointer',
        alignItems: 'center'
    },
    signOutText: {
        fontSize: '0.875rem',
        lineHeight: 1.2,
        fontWeight: 'bold',
        color: 'textColorPrimary.main'
    },
    spacer: {
        flexGrow: 1
    }
};

export const BasicToolbar = props => {

    const {toggleDrawer} = props;
    const intl = useIntl();

    return <AppBar position="static" sx={styles.appBar}>
        <Toolbar sx={styles.toolbar}>
            <Box style={{lineHeight: 0}}>
                <ListRoundedIcon onClick={toggleDrawer}/>
            </Box>
            <Box sx={styles.spacer}/>
            <Box sx={styles.signOutWrapper}>
                <AccountCircleRoundedIcon fontSize={'small'} style={{marginRight: '5px'}}/>
                <Typography sx={styles.signOutText} component={'span'}>
                    {intl.formatMessage({id: 'sign_out'})}
                </Typography>
            </Box>
            <Box>
                <IconButton sx={styles.notifications} disableRipple>
                    <Badge badgeContent={2}>
                        <NotificationsRoundedIcon fontSize={'small'}/>
                    </Badge>
                </IconButton>
            </Box>
        </Toolbar>
    </AppBar>;
};