import * as React from 'react';
import {Button, CircularProgress} from "@mui/material";
import {useIntl} from "react-intl";
import {LoadingButton} from "@mui/lab";

const styles = {
    button: (variant, uppercase, color) => [
        {
            cursor: 'pointer',
            fontSize: '0.75rem',
            fontWeight: 'bold',
            minHeight: '2.5rem',
            borderRadius: '0.5rem',
            padding: '6px 16px'
        },
        variant === "contained" && color === "violet" && {
            color: 'textColorPrimaryDarkTheme.main',
            bgcolor: 'violet.main',
            boxShadow: 'rgb(0 0 0 / 11%) 0rem 0.25rem 0.4375rem -0.0625rem, rgb(0 0 0 / 7%) 0rem 0.125rem 0.25rem -0.0625rem',
            '&:hover': {
                bgcolor: 'violet.dark',
            },
        },
        variant === "contained" && color === "purple" && {
            color: 'textColorPrimaryDarkTheme.main',
            bgcolor: 'purple.main',
            boxShadow: 'rgb(0 0 0 / 11%) 0rem 0.25rem 0.4375rem -0.0625rem, rgb(0 0 0 / 7%) 0rem 0.125rem 0.25rem -0.0625rem',
            '&:hover': {
                bgcolor: 'purple.dark',
            },
        },
        !uppercase & {
            textTransform: 'none',
        },
        variant !== "contained" && color === "violet" && {
            color: 'violet.main',
            '&:hover': {
                bgcolor: '#5048e514',
            },
        },
        variant !== "contained" && color === "purple" && {
            color: 'purple.main'
        },
    ],
    buttonProgress: {
        color: 'textColorPrimaryDarkTheme.main',
    }
};

export const RegularButton = props => {

    const intl = useIntl();
    const {
        ref,
        onClick,
        text,
        customStyles = {},
        startIcon,
        endIcon,
        variant = "contained",
        uppercase = true,
        fullWidth,
        disabled,
        color = "violet",
        loadingButton,
        loading
    } = props;

    const commonProps = {
        ref,
        variant,
        sx: [
            ...styles.button(variant, uppercase, color),
            customStyles
        ],
        fullWidth,
        startIcon,
        endIcon,
        disableElevation: true,
        onClick,
        disabled
    };

    if (loadingButton)
        return <LoadingButton {...commonProps}
                              loading={loading}
                              loadingIndicator={<CircularProgress size={24}
                                                                  sx={styles.buttonProgress}/>}>
            {intl.formatMessage({id: text})}
        </LoadingButton>

    return <Button {...commonProps}>
        {intl.formatMessage({id: text})}
    </Button>;
};
