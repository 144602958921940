import * as React from 'react';
import {useEffect, useMemo, useRef} from 'react';
import {Box, Grid} from "@mui/material";
import 'reactflow/dist/style.css';
import {Background, MiniMap, ReactFlow, useReactFlow} from "reactflow";
import {WorkflowStepNode} from "./nodeTypes/WorkflowStepNode";
import {AddMoreNode} from "./nodeTypes/AddMoreNode";
import useDeepCompareEffect from "use-deep-compare-effect";
import {ConfigureWorkflowStepDialog} from "./ConfigureWorkflowStepDialog";
import {useDispatch} from "react-redux";
import {WorkflowBuilderControls} from "./WorkflowBuilderControls";
import {WorkflowStartNode} from "./nodeTypes/WorkflowStartNode";
import {EDGE_LENGTH, NODE_HEIGHT} from "./common";

const styles = {
    builderWrapper: {
        height: '100%',
        border: '1px dashed #bbb',
        borderRadius: '8px'
    },
    paper: {
        width: '280px',
        padding: '1rem',
        position: 'relative',
        borderRadius: '1rem',
        boxShadow: 'rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px',
    },
    caption: {
        fontWeight: 'bold',
        fontSize: '16px',
    },
    subCaption: {
        fontSize: '14px',
    }
};

export const WorkflowBuilder = props => {

    const {
        nodes,
        edges,
        onInit,
        saveWorkflowHandler,
        resetBuilder,
        workflowName,
        setWorkflowName,
        addNewNodeHandler,
        active,
        setActive
    } = props;

    const builderRef = useRef({});
    const dispatch = useDispatch();
    const [builderWidth, setBuilderWidth] = React.useState(0);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [parentId, setParentId] = React.useState(false);
    const reactFlowInstance = useReactFlow();

    const onModalClose = () => {
        setModalOpen(false);
    };

    const nodeTypes = useMemo(() => ({
        workflowStartNode: WorkflowStartNode,
        workflowStepNode: WorkflowStepNode,
        addMoreNode: AddMoreNode,
    }), []);

    const onLeafNodeClick = childOf => () => {
        setParentId(childOf);
        setModalOpen(true);
    };

    useEffect(() => {
        if (reactFlowInstance.viewportInitialized && builderWidth !== 0)
            onInit(builderWidth, onLeafNodeClick);
    }, [builderWidth, reactFlowInstance.viewportInitialized]);

    useDeepCompareEffect(() => {
        if (reactFlowInstance.viewportInitialized) {
            setBuilderWidth(builderRef.current ? builderRef.current.offsetWidth : 0);
        }
    }, [builderRef, reactFlowInstance.viewportInitialized]);

    useDeepCompareEffect(() => {
        reactFlowInstance.setNodes(nodes);
        reactFlowInstance.setEdges(edges);
        if (nodes.length > 3) {
            const viewport = reactFlowInstance.getViewport();
            reactFlowInstance.setViewport({
                x: viewport.x,
                y: viewport.y - EDGE_LENGTH - NODE_HEIGHT,
                zoom: viewport.zoom
            });
        }
    }, [nodes]);

    return <>
        <Grid container
              style={{height: '100%'}}
              spacing={2}
              alignItems="center">
            <Grid item style={{height: '100%'}}>
                <WorkflowBuilderControls saveWorkflowHandler={saveWorkflowHandler}
                                         workflowName={workflowName}
                                         setWorkflowName={setWorkflowName}
                                         resetBuilder={resetBuilder}
                                         active={active}
                                         setActive={setActive}/>
            </Grid>
            <Grid item xs style={{height: '100%'}}>
                <Box ref={builderRef} sx={styles.builderWrapper}>
                    <ReactFlow zoomOnScroll={false}
                               zoomOnDoubleClick={false}
                               nodeTypes={nodeTypes}
                               nodes={nodes}
                               edges={edges}>
                        <MiniMap/>
                        <Background/>
                    </ReactFlow>
                </Box>
            </Grid>
        </Grid>
        <ConfigureWorkflowStepDialog open={modalOpen} onClose={onModalClose}
                                     addNewNodeHandler={addNewNodeHandler(parentId, onLeafNodeClick)}/>
    </>;
};