import {createTheme} from "@mui/material";

export const muiTheme = createTheme({
    palette: {
        violet: {
            main: '#6366f1',
            dark: '#5048e5',
            light: '#828df8'
        },
        purple: {
            main: '#9e77ed',
            dark: '#774ad3',
            light: '#ac90ec',
        },
        green: {
            main: '#16b364',
            dark: '#0b815a',
            light: '#3fc79a'
        },
        anthracite: {
            main: '#344767',
        },
        backgroundPrimary: {
            main: '#292929',
            purple: "#3a2d49",
            purpleDark: '#332940',
        },
        backgroundSecondary: {
            main: '#f9fafc',
        },
        textColorPrimary: {
            main: '#344767'
        },
        textColorSecondary: {
            main: '#d1d5db'
        },
        textColorPrimaryDarkTheme: {
            main: 'rgba(255,255,255,0.87)'
        },
        textColorSecondaryDarkTheme: {
            main: 'rgba(255,255,255,0.6)'
        },
        textColorTertiary: {
            main: '#6b7280'
        },
        hover: {
            main: '#e9ecef'
        }
    },
    typography: {
        fontFamily: 'Inter'
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 768,
            lg: 1024,
            xl: 1280,
            xxl: 1440
        }
    }
});