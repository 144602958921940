import React, {useEffect, useState} from 'react';
import {useIntl} from "react-intl";
import {Box, Container, Grid, Typography} from "@mui/material";
import {usePrevious} from "../../hooks/usePrevious";
import {useViewportDimensions} from "../../hooks/useViewportDimensions";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay} from 'swiper';
import {RegularButton} from "../common/RegularButton";
import {useSelector} from "react-redux";
import {demoTypeSelector} from "../../reducers/demoType";

const styles = {
    container: {
        paddingTop: {xs: '50px', sm: '100px'},
        paddingBottom: {xs: '50px', sm: '100px'},
        margin: {xs: 0, md: '0 24px'},
        borderRadius: '40px',
        background: 'radial-gradient(131.66% 109.77% at 50% 97.75%,transparent 37.41%,#16b364 69.27%,#fff 100%)',
    },
    caption: animate => [
        {
            fontSize: {xs: '40px', sm: '55px', md: '55px', lg: '60px'},
            fontWeight: 500,
            color: 'textColorPrimaryDarkTheme.main',
            textAlign: {xs: 'center', md: 'left'},
            transition: 'transform 0.7s ease 0s, opacity 0.7s ease 0s',
            opacity: 0,
            transform: 'translate3d(0px, 100px, 0px)',
            marginBottom: {xs: '20px', sm: '20px'},
        },
        animate && {
            opacity: 1,
            transform: 'translate3d(0px, 0px, 0px)'
        }
    ],
    desc: animate => [
        {
            fontSize: {xs: '16px', sm: '20px', md: '22px', lg: '24px'},
            marginBottom: '24px',
            color: 'textColorPrimaryDarkTheme.main',
            textAlign: {xs: 'center', md: 'left'},
            transition: 'transform 0.7s ease 0s, opacity 0.7s ease 0s',
            opacity: 0,
            transform: 'translate3d(0px, 100px, 0px)',
        },
        animate && {
            opacity: 1,
            transform: 'translate3d(0px, 0px, 0px)'
        }
    ],
    imagesWrapper: animate => [
        {
            transition: 'transform 0.7s ease 0s, opacity 0.7s ease 0s',
            opacity: 0,
            transform: 'translate3d(0px, 50px, 0px)',
        },
        animate && {
            opacity: 1,
            transform: 'translate3d(0px, 0px, 0px)'
        }
    ],
    image: {
        borderRadius: '16px',
        width: '100%',
        height: 'auto'
    },
    buttonWrapper: animate => [
        {
            transition: 'transform 0.7s ease 0s, opacity 0.7s ease 0s',
            opacity: 0,
            transform: 'translate3d(0px, 50px, 0px)',
            textAlign: 'center'
        },
        animate && {
            opacity: 1,
            transform: 'translate3d(0px, 0px, 0px)'
        }
    ],
};

export const ScreenshotsSection = () => {

    const intl = useIntl();
    const [animateCaption, setAnimateCaption] = useState(false);
    const [animateDesc, setAnimateDesc] = useState(false);
    const [animateSlides, setAnimateSlides] = useState(false);
    const [animateButton, setAnimateButton] = useState(false);
    const [animate, setAnimate] = useState(false);
    const [scrollListener, setScrollListener] = useState(() => () => void 0);
    const previousListener = usePrevious(scrollListener);
    const [scrollTarget, setScrollTarget] = useState(undefined);
    const {viewportHeight} = useViewportDimensions();
    const [swiper, setSwiper] = useState(null);
    const demoType = useSelector(demoTypeSelector);

    useEffect(() => {
        window.removeEventListener('scroll', previousListener);
        window.addEventListener('scroll', scrollListener);
        return () => window.removeEventListener('scroll', scrollListener);
    }, [scrollListener]);

    useEffect(() => {
        if (!scrollTarget)
            return;

        const listener = () => {
            const {y} = scrollTarget.getBoundingClientRect();
            if (viewportHeight >= y + 100) {
                setAnimate(true);
            }
        };
        listener();

        setScrollListener(() => listener);
    }, [scrollTarget, viewportHeight]);

    useEffect(() => {
        if (animate) {
            swiper.slideToLoop(0);
            setTimeout(() => setAnimateCaption(true), 200);
            setTimeout(() => setAnimateDesc(true), 600);
            setTimeout(() => setAnimateButton(true), 800);
            setTimeout(() => setAnimateSlides(true), 400);
        }
    }, [animate]);

    return <Box sx={styles.container}>
        <Container maxWidth="xl">
            <Grid container
                  justifyContent="center"
                  alignItems="start"
                  spacing={2}>
                <Grid item xs={12} lg={5} ref={node => {
                    if (node) {
                        setScrollTarget(node);
                    }
                }}>
                    <Typography sx={styles.caption(animateCaption)}>
                        {intl.formatMessage({id: `${demoType}_screenshots_caption`}, {br: <br/>})}
                    </Typography>
                    <Typography sx={styles.desc(animateDesc)}>
                        {intl.formatMessage({id: `${demoType}_screenshots_desc`})}
                    </Typography>
                    <Box sx={styles.buttonWrapper(animateButton)}>
                        <RegularButton text={'book_demo'} onClick={() => window.open("https://calendly.com/fluxima-demo", "_blank")} color={'purple'}/>
                    </Box>
                </Grid>
                <Grid item xs={12} lg={7}>
                    <Box sx={styles.imagesWrapper(animateSlides)}>
                        <Swiper modules={[Autoplay]} autoplay={{delay: 4000, disableOnInteraction: false}} speed={700}
                                onSwiper={setSwiper}>
                            <SwiperSlide>
                                <Box component="img" src={`/${demoType}_slide1.png`} sx={styles.image}/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Box component="img" src={`/${demoType}_slide2.png`} sx={styles.image}/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Box component="img" src={`/${demoType}_slide3.png`} sx={styles.image}/>
                            </SwiperSlide>
                        </Swiper>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    </Box>;
};