import * as React from 'react';
import {Avatar, Box, Grid, List, ListItem, Paper, Typography} from "@mui/material";
import {useIntl} from "react-intl";
import {RegularButton} from "../common/RegularButton";
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import IconButton from "@mui/material/IconButton";
import {useSelector} from "react-redux";
import {getCustomers} from "../../reducers/customers";
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import EastRoundedIcon from '@mui/icons-material/EastRounded';
import {CommonTabs} from "../common/CommonTabs";
import {CommonDivider} from "../common/CommonDivider";
import {Search} from "../common/Search";
import {useEffect} from "react";

const styles = {
    captionWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    caption: {
        flex: '1 1 0',
        padding: '1rem',
        fontSize: '26px'
    },
    paper: {
        boxShadow: 'rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px',
        borderRadius: '1rem',
        margin: '1.5rem 0',
        overflow: 'hidden'
    },
    sectionWrapper: {
        padding: '1rem'
    },
    list: {
        margin: 0,
        padding: 0
    },
    listItem: beforeLast => [
        {
            padding: '12px 24px',
            '&:hover': {
                backgroundColor: '#3741510a'
            },
        },
        beforeLast && {
            borderBottom: '1px solid rgb(230, 232, 240)'
        }
    ],
    tableCellText: {
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: 1.57,
        color: '#374151'
    },
    tableCellSubtext: {
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: 1.57,
        color: '#65748b'
    },
    greenText: {
        color: '#14b8a6'
    },
    buttonWrapper: {
        textAlign: 'end'
    },
    tableHeader: {
        backgroundColor: '#f3f4f6',
        padding: '24px'
    },
    tableHeaderText: {
        textTransform: 'uppercase',
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: 1
    },
};

function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
}

function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

export const Customers = () => {

    const intl = useIntl();
    const customers = useSelector(getCustomers);
    const [tabValue, setTabValue] = React.useState(0);
    const [searchString, setSearchString] = React.useState('');
    const [searchFilteredCustomers, setSearchFilteredCustomers] = React.useState(customers);

    const tabs = [
        {label: 'all'},
        {label: 'accepts_marketing'},
        {label: 'prospecting'},
        {label: 'cold_lead'},
    ];

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        let filteredItems = customers;
        if (searchString !== '') {
            const sanitizedSearchString = searchString.toLowerCase();
            filteredItems = customers.filter(customer =>
                customer.name.toLowerCase().includes(sanitizedSearchString) ||
                customer.email.toLowerCase().includes(sanitizedSearchString) ||
                customer.location.toLowerCase().includes(sanitizedSearchString)
            );
        }
        setSearchFilteredCustomers(filteredItems);
    }, [searchString]);

    const header = <ListItem sx={styles.tableHeader}>
        <Grid container
              spacing={2}
              justifyContent="center"
              alignItems="center">
            <Grid item xs={3}>
                <Typography sx={styles.tableHeaderText}>
                    {intl.formatMessage({id: 'name'})}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography sx={styles.tableHeaderText}>
                    {intl.formatMessage({id: 'location'})}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography sx={styles.tableHeaderText}>
                    {intl.formatMessage({id: 'orders'})}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography sx={styles.tableHeaderText}>
                    {intl.formatMessage({id: 'spent'})}
                </Typography>
            </Grid>
            <Grid item xs/>
            <Grid item xs={1}>
                <Typography sx={styles.tableHeaderText} style={{textAlign: 'end'}}>
                    {intl.formatMessage({id: 'actions'})}
                </Typography>
            </Grid>
        </Grid>
    </ListItem>;

    return <Box>
        <Box sx={styles.captionWrapper}>
            <Typography sx={styles.caption}>
                {intl.formatMessage({id: 'customers'})}
            </Typography>
            <RegularButton startIcon={<AddRoundedIcon/>} text={'add'}/>
        </Box>
        <Box>
            <RegularButton startIcon={<FileUploadRoundedIcon/>} text={'import'} variant={'text'}
                           customStyles={{marginRight: '10px'}}/>
            <RegularButton startIcon={<DownloadRoundedIcon/>} text={'download'} variant={'text'}/>
        </Box>
        <Paper sx={styles.paper}>
            <Box sx={{padding: '0 1rem'}}>
                <CommonTabs tabs={tabs} tabValue={tabValue} handleChange={handleTabChange}
                            customStyles={{marginTop: '10px'}}/>
            </Box>
            <CommonDivider/>
            <Box sx={styles.sectionWrapper}>
                <Search value={searchString} setValue={setSearchString} width={'50%'}/>
            </Box>
            <List sx={styles.list}>
                {header}
                {searchFilteredCustomers.map((customer, index) => <ListItem sx={styles.listItem(index !== customers.length - 1)}>
                    <Grid container
                          spacing={2}
                          justifyContent="center"
                          alignItems="center">
                        <Grid item xs={3} container spacing={1}>
                            <Grid item>
                                <Avatar {...stringAvatar(customer.name)} />
                            </Grid>
                            <Grid item xs>
                                <Typography sx={styles.tableCellText}>
                                    {customer.name}
                                </Typography>
                                <Typography sx={styles.tableCellSubtext}>
                                    {customer.name}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography sx={styles.tableCellText}>
                                {customer.location}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography sx={styles.tableCellText}>
                                {customer.orders}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography sx={{...styles.tableCellText, ...styles.greenText}}>
                                €{customer.totalSum}
                            </Typography>
                        </Grid>
                        <Grid item xs/>
                        <Grid item xs={1}>
                            <Box sx={styles.buttonWrapper}>
                                <IconButton color="primary" component="label">
                                    <OpenInNewRoundedIcon/>
                                </IconButton>
                                <IconButton color="primary" component="label">
                                    <EastRoundedIcon/>
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>
                </ListItem>)}
            </List>
        </Paper>
    </Box>;
};