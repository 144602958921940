export const messagesSk = {
    sk: "Slovensky",
    en: "English",
    default_locale: "Východzí jazyk",
    introduction: "Predstavenie",
    benefits: "Benefity",
    pricing: "Ceny",
    for_businesses: "Pre firmy",
    for_public: "Pre verejnosť",
    login: "Prihlásenie",
    email: "Email",
    confirmEmail: "Potvrdiť email",
    contact: "Kontakt",
    subscription: "Predplatné",
    main_products: "Plienky",
    additional_products: "Doplnkové produkty",
    premium_products: "Prémiové plienky",
    free_sample: "Vzorka zdarma",
    review_sample: "Recenzia vzorky",
    order_sample: "Objednať vzorku",
    thank_you: "Ďakujeme.",
    cookie_consent: "Tento web používa na poskytovanie služieb, personalizáciu reklám a analýzu návštevnosti súbory cookie. Používaním tohto webu s tým súhlasíte.",
    more_info: "Viac informácií",
    not_eligible_for_a_free_sample: "Tento email už nemá nárok na vzorku zdarma.",
    not_sure_about: "Nie ste si istá, či vám budú naše plienky vyhovovať? My áno! A preto vám radi zašleme vzorku za symbolické 1 euro. Stačí odpovedať na 4 krátke otázky, vybrať si veľkosť a vyplniť údaje.",
    check_your_email_free_sample: "Skontrolujte si email a potvrďte váš záujem o vzorku zdarma. Následne vám poštou odošleme 2ks našich úžasných plienok v zvolenej veľkosti.",
    sample_order_completed: "Sme radi, že ste sa rozhodli vyskúšať vzorku našich úžasných plienok. Zásielku obdržíte o pár dní.",
    verify_free_sample_email: "Overenie emailu pre vzorku zdarma",
    free_sample_email_verifying: "Overujem email, prosím čakajte.",
    activation_token_does_not_exist: "Toto nevyzerá ako platný aktivačný link.",
    activation_token_already_used: "Tento aktivačný link bol už použitý.",
    free_sample_verification_successful: "Email úspešne overený! Vaša vzorka je na ceste k Vám.",
    free_sample_already_used: "Tento email si už vzorku zdarma vyžiadal.",
    select_interval: "Výber intervalu",
    interval: "Interval",
    contact_details: "Kontaktné údaje",
    payment_details: "Platobné údaje",
    mini_monthly_pack: "Mini box",
    midi_monthly_pack: "Midi box",
    maxi_monthly_pack: "Maxi box",
    junior_monthly_pack: "Junior box",
    mini_single_pack: "Mini",
    midi_single_pack: "Midi",
    maxi_single_pack: "Maxi",
    junior_single_pack: "Junior",
    cotte_mini_single_pack: "Cotte Mini",
    cotte_midi_single_pack: "Cotte Midi",
    cotte_maxi_single_pack: "Cotte Maxi",
    cotte_junior_single_pack: "Cotte Junior",
    baby_weight_cotte_mini_single_pack: "veľkosť {size} (3 - 6 kg)",
    baby_weight_cotte_midi_single_pack: "veľkosť {size} (4 - 9 kg)",
    baby_weight_cotte_maxi_single_pack: "veľkosť {size} (7 - 18 kg)",
    baby_weight_cotte_junior_single_pack: "veľkosť {size} (12 - 25 kg)",
    mini_sample: "Mini",
    midi_sample: "Midi",
    maxi_sample: "Maxi",
    junior_sample: "Junior",
    junior_pants_single_pack: "Junior pants",
    junior_xl_pants_single_pack: "Junior pants XL",
    product_description_junior_pants_single_pack: "Nohavičkové plienky vo veľkosti JUNIOR",
    product_description_junior_xl_pants_single_pack: "Nohavičkové plienky vo veľkosti JUNIOR XL",
    product_description_mini_monthly_pack: "Výhodné balenie plienok vo veľkosti MINI",
    product_description_midi_monthly_pack: "Výhodné balenie plienok vo veľkosti MIDI",
    product_description_maxi_monthly_pack: "Výhodné balenie plienok vo veľkosti MAXI",
    product_description_junior_monthly_pack: "Výhodné balenie plienok vo veľkosti JUNIOR",
    product_description_mini_single_pack: "Balenie plienok vo veľkosti MINI",
    product_description_midi_single_pack: "Balenie plienok vo veľkosti MIDI",
    product_description_maxi_single_pack: "Balenie plienok vo veľkosti MAXI",
    product_description_junior_single_pack: "Balenie plienok vo veľkosti JUNIOR",
    baby_weight_mini_monthly_pack: "veľkosť {size} (3 - 6 kg)",
    baby_weight_midi_monthly_pack: "veľkosť {size} (4 - 9 kg)",
    baby_weight_maxi_monthly_pack: "veľkosť {size} (7 - 18 kg)",
    baby_weight_junior_monthly_pack: "veľkosť {size} (12 - 25 kg)",
    baby_weight_mini_single_pack: "veľkosť {size} (3 - 6 kg)",
    baby_weight_midi_single_pack: "veľkosť {size} (4 - 9 kg)",
    baby_weight_maxi_single_pack: "veľkosť {size} (7 - 18 kg)",
    baby_weight_junior_single_pack: "veľkosť {size} (12 - 25 kg)",
    baby_weight_junior_pants_single_pack: "veľkosť {size} (12 - 18 kg)",
    baby_weight_junior_xl_pants_single_pack: "veľkosť {size} (16+ kg)",
    replaced_product_with_package_deal: "Vybrali sme za Vás výhodné mesačné balenie.",
    baby_weight_mini_sample: "veľkosť {size} (3 - 6 kg)",
    baby_weight_midi_sample: "veľkosť {size} (4 - 9 kg)",
    baby_weight_maxi_sample: "veľkosť {size} (7 - 18 kg)",
    baby_weight_junior_sample: "veľkosť {size} (12 - 25 kg)",
    product_description_wetwipes: "Vlhčené obrúsky s aloe vera",
    product_description_wetwipes_cotton: "Bavlnené vlhčené obrúsky s harmančekom",
    product_description_change_pads: "Jednorazové prebaľovacie podložky 60x60cm",
    wetwipes: "Vlhčené obrúsky",
    wetwipes_cotton: "Bavlnené vlhčené obrúsky",
    change_pads: "Prebaľovacie podložky",
    nappies_pieces: "{pieces} plienok v balení",
    pieces: "{pieces} kusov v balení",
    most_popular: "Najobľúbenejšie",
    help: 'Pomoc',
    free_trial_package: 'Vzorka zdarma',
    flexible_subscription: 'Predplatné',
    intro: 'Úvod',
    navbar_order: "Objednajte si predplatné",
    manage_subscription: "Manažovať predplatné",
    order: "Objednať",
    order_a_subscription: "Objednajte si predplatné",
    marketing_newsletter: "Marketing newsletter",
    subscribe_to_newsletter_caption: "Floflo newsletter",
    subscribe_to_newsletter_text: "Zostavili sme zoznam esenciálnych rodičovských tipov šetriacich veľa času, návod, ako si zlacniť predplatné Floflo a mnoho ďalšieho. Pridali sme aj zľavu, len pre vás",
    subscribed_to_newsletter: "Úspešne sme vás prihlásili na odber Floflo newslettera. Ďakujeme.",
    subscribe_to_newsletter_button_confirm: "Chcem zľavy a tipy!",
    subscribe_to_newsletter_button_cancel: "Nie ďakujem, radšej ostanem nemoderným rodičom",
    do_you_really_want_to_unsubscribe: "Skutočne chcete zrušiť odber marketingového newslettera?",
    unsubscribed_from_newsletter: "Odber marketingového newslettera bol zrušený.",
    order_summary: 'Zhrnutie objednávky',
    confirm_order_statement: "By clicking on “Order for a fee” you accept our terms and conditions (including right of withdrawal and consent to the storage of payment data for recurring payments) and our data protection provisions.",
    summary: 'Sumár',
    back_to_address: "< Späť k adrese",
    back_to_payment: "< Späť k platbe",
    upsell_header_text: 'Pre uľahčenie prebaľovania Vám odporúčame pridať aj tieto produkty:',
    products: 'Naše produkty',
    chosen_products: 'Zvolené produkty',
    intro_main_text: 'Flexibilné predplatné na plienky pre vaše dieťa',
    intro_secondary_text: "Vyberte si jednu zo štyroch veľkostí našich plienok.",
    intro_text_list1: "Naše plienky sú šetrné k pokožke",
    intro_text_list2: "Rozložia sa v prírode do 10 rokov",
    intro_text_list3: "Pravidelné doručovanie",
    intro_text_list4: "100% flexibilita / možnosť kedykoľvek zrušit alebo upraviť predplatné",
    intro_button_text: "Viac informácií",
    discover_now: "Viac informácií",
    order_free_sample_now: "Objednať vzorku zdarma",
    order_sample_now: "Objednať vzorku",
    pick_a_size: "Vyberte si veľkosť",
    benefits_main_text1: "Už žiadne starosti s plienkami",
    benefits_main_text2: "Už žiadne starosti",
    benefits_main_text3: "s plienkami",
    benefits_secondary_text: "od €42.00 mesačne",
    benefits_one: "Predplatné prispôsobené vašim potrebám. Kedykoľvek ho môžete zrušiť.",
    benefits_two: "Doprava a vrátenie zdarma.",
    benefits_three: "Výhodné zľavy na naše mesačné balíky.",
    discover_what_other: "Pozrite sa, čo o nás hovoria iní rodičia",
    we_know_how_difficult: "Vieme ako náročné je vychovávať deti. Každá úspora času a peňazí sa počíta. Prečítajte si, čo o predplatnom na plienky od floflo hovoria naši zákaznici.",
    not_convinced_yet: "Ešte stále váhate?",
    not_convinced_yet_list1: "Naše plienky uspokoja najnáročnejších zákazníkov.",
    not_convinced_yet_list2: "Vyrobené na Slovensku, navrhnuté s ohľadom na prírodu a udržateľnosť.",
    not_convinced_yet_list3: "100% garancia vrátenia peňazí.",
    try_for_free_now: "Chcem vzorku",
    get_your_free: "Objednajte si vzorku!",
    learn_more: "Viac informácií",
    more_for_you: "Sme tu pre vás! Ak máte akékoľvek otázky o našom predplatnom či našich produktoch, neváhajte nás kontaktovať.",
    service_mail: "ahoj@floflo.sk",
    service_phone_number: "+421 904 943 833",
    your_individual: "Už žiadne starosti s plienkami",
    address: "Adresa",
    address_created: "Adresa pridaná",
    address_updated: "Adresa upravená",
    address_deleted: "Adresa zmazaná",
    select_address: "Zvoľte si adresu",
    payment: 'Platba',
    many_thanks: "Vážime si vašu dôveru.",
    thank_you_for: "Ďakujeme za vašu objednávku.",
    you_can_manage: "Nastavenia svojho predplatného si môžete upraviť v sekcii Moje floflo.",
    you_will_be_redirected: "Za 5 sekúnd vás presmerujeme na úvodnú stránku.",
    subscription_already_exists: "Vaše predplatné je už aktívne.",
    visit_your: "Navštívte",
    to_manage_it: "ak potrebujete upraviť predplatné.",
    new_customer: "Nový zákazník",
    personal_details: "Osobné údaje",
    choose_your_size: "Zvoľte si veľkosť",
    choose_products: "Vyberte si z našich produktov",
    you_might_also_want: "Mohli by ste chcieť aj tieto produkty",
    use_an_existing_address: "Použiť existujúcu adresu",
    use_a_new_address: "Vytvoriť novú adresu",
    register_using: "Zaregistrovať sa pomocou Facebook, alebo Google účtu.",
    register_manually: "Zaregistrovať sa pomocou zadania údajov",
    referral_code: "Affiliate kód",
    enter_referral_code: "Zadajte odporúčací kód",
    enter_referral_code_desc: "Odporučila vám floflo známa? Zadajte jej odporúčací kód, aby sme ju mohli odmeniť!",
    what_diaper_brand_do_you_use: "Akú značku plienok používate?",
    cloth_diapers: "Látkové",
    what_criteria_do_you_use_to_choose_diapers: "Podla čoho vyberáte plienky?",
    low_environmental_impact: "Nízka záťaž pre životné prostredie",
    how_diapers_fit: "Ako plienky sedia",
    no_perfumes_added: "Zloženie bez parfémov",
    price: "Cena",
    diaper_pattern: "Vzor",
    do_you_know_the_brand: "Poznáte značku BabyBaby Soft?",
    how_do_you_shop_for_diapers: "Ako primárne nakupujete plienky?",
    brick_mortar_stores: "V predajniach",
    online_e_shop: "Online v e-shopoch",
    did_you_get_a_free_sample: "Dostali ste od nás vzorku plienok?",
    were_you_satisfied_with_the_diapers: "Boli ste spokojní s plienkami?",
    why_havent_you_placed_an_order: "Prečo ste si plienky neobjednali?",
    not_a_good_fit: "Nesedia dobre bábätku",
    we_are_satisfied_with_current_brand: "Sme spokojní so súčasnými plienkami",
    we_were_discouraged_by_price: "Odradila nás cena",
    we_dont_like_the_pattern: "Nepáči sa nám vzor",
    question_validation_answer_empty: "Odpoveď nemôže byť prázdna",
    question_validation_option_empty: "Minimálne jedna možnosť musí byť označená",
    enter_your_answer_here: "Sem prosím vyplňte svoju odpoveď",
    other: "Iné",
    questionnaire_answered: "Odpovede uložené.",
    free_sample_followup_caption: "Recenzia vzorky",
    free_sample_followup_desc: "Sme radi, že ste sa rozhodli vyskúšať naše plienky. Pred pár dňami ste si vyžiadali vzorku a nás zaujíma, či Vám prišla a ako ste s plienkami boli spokojná.",
    subscription_service_survey_caption: "30-sekundový prieskum na šetrenie rodičovského času",
    subscription_service_survey_desc: "Deti sú radosť, ale zaberajú mame všetok čas. FloFlo pracuje tvrdo na tom, aby vnieslo mamičkám viac voľnosti do ich dennej rutiny.",
    questionnaire_summary_thank_you: "Ďakujeme za Vaše odpovede.",
    subscription_service_survey_question_1: "Zmenili by ste značku plienok pri zjednodušení ich nakupovania?",
    subscription_service_survey_question_2: "Na koľko si ceníte úsporu času a zvýšenie pohodlia pri zmiznutí starostí s nakupovaním plienok?",
    subscription_service_survey_question_2_option_1: "Do 5 eur mesačne",
    subscription_service_survey_question_2_option_2: "Do 10 eur mesačne",
    subscription_service_survey_question_2_option_3: "Do 15 eur mesačne",
    subscription_service_survey_question_2_option_4: "Času mám dostatok, nemám záujem si priplácať za komfort.",
    subscription_service_survey_question_3: "Ake je pre Vás prvotné kritérium výberu plienky?",
    subscription_service_survey_question_3_option_1: "Nízka záťaž pre životné prostredie",
    subscription_service_survey_question_3_option_2: "Ako plienky sedia",
    subscription_service_survey_question_3_option_3: "Zloženie bez parfémov",
    subscription_service_survey_question_3_option_4: "Cena",
    subscription_service_survey_question_4: "Ake je pre Vás druhotné kritérium výberu plienky?",
    subscription_service_survey_question_4_option_1: "Nízka záťaž pre životné prostredie",
    subscription_service_survey_question_4_option_2: "Ako plienky sedia",
    subscription_service_survey_question_4_option_3: "Zloženie bez parfémov",
    subscription_service_survey_question_4_option_4: "Cena",
    subscription_service_survey_question_5: "Ako nakupujete plienky?",
    subscription_service_survey_question_5_option_1: "V predajniach",
    subscription_service_survey_question_5_option_2: "Online menšie balenia podľa potreby",
    subscription_service_survey_question_5_option_3: "Online veľké balenia v akcii",
    subscription_service_survey_question_6: "Zvyknete platiť za iné služby pre uľahčenie života a zvýšenie pohodlia? Napríklad Netflix, Spotify, Bolt, upratovacie služby.",
    subscription_service_survey_question_7: "Koľko máte v súčasnosti malých detičiek, alebo ste v očakávaní?",
    subscription_service_survey_question_7_option_1: "Máme jedno / Očakávame prvé",
    subscription_service_survey_question_7_option_2: "Máme dve / Očakávame druhé",
    subscription_service_survey_question_7_option_3: "Máme tri a viac / Očakávame tretie a viac",
    questionnaire_token_does_not_exist: "Kód dotazníka neexistuje.",
    questionnaire_token_already_used: "Kód dotazníka už nie je platný.",
    questionnaire_already_answered: "Na tento dotazník ste už odpovedali.",
    discount_code: "Zľavový kód",
    enter_discount_code: "Zadajte zľavový kód",
    discount_code_already_used: "Zľavový kód ste už použili v minulosti.",
    discount_code_doesnt_exist: "Zľavový kód neexistuje.",
    discount_code_not_valid_yet: "Zľavový kód ešte nie je platný.",
    discount_code_not_valid_anymore: "Zľavový kód už nie je platný.",
    discount_code_added: "Zľavový kód bol pridaný.",
    how_it_works_main_text: "Predplatné vytvorené pre vaše potreby",
    how_it_works_secondary_text: "Ako to funguje",
    how_it_works_point_1_main_text: "Vyberte si veľkosť",
    how_it_works_point_1_sub_text: "Vyberte si jednu zo štyroch veľkostí našich plienok.",
    how_it_works_point_2_main_text: "Vyberte si doplnkové produkty",
    how_it_works_point_2_sub_text: "Potrebujete vlhčené utierky, či prebaľovaciu podložku? Žiaden problém.",
    how_it_works_point_3_main_text: "Doručenie",
    how_it_works_point_3_sub_text: "Prepočet počtu plienok na deň vám pomôže vybrať vhodný interval doručovania. A dopravu samozrejme neplatíte.",
    how_it_works_button_text: "Viac informácií",
    choose_subscription: "Vyberte si predplatné",
    menu: "Menu",
    profile: "profil",
    affiliate: 'Floflo bonus',
    current_subscription: "Aktívne predplatné",
    next_subscription: "Ďaľšie predplatné",
    subscription_start: "Začiatok predplatného",
    subscription_end: "Koniec predplatného",
    subscription_renewal_date: "Termín nasledujúcej platby",
    subscription_delivery_date: "Doručenie nasledujúcej zásielky",
    subscription_price: "Cena predplatného",
    subscription_created: "Predplatné vytvorené",
    subscription_updated: "Predplatné zmenené",
    free_sample_requested: "Vyžiadaná vzorka plienok.",
    free_sample_error: "Nepodarilo sa vyžiadať vzorku plienok.",
    subscription_cancelled: "Predplatné zrušené",
    cancel_subscription: "Ukončiť predplatné",
    cancel_subscription_desc: "V tejto sekcii môžte ukončiť svoje predplatné.",
    cancel_subscription_confirmation: "Určite chcete zrušiť svoje predplatné?",
    change_subscription: "Zmeniť typ predplatného",
    renew_subscription: "Obnoviť predplatné",
    no_active_subscription: "Predplatné nie je aktuálne aktívne",
    validation_address_product: "Vyberte si produkt",
    validation_address_street_empty: "Ulica je povinný údaj",
    validation_address_street_no_number: "Ulica neobsahuje popisné číslo",
    validation_address_postalCode: "PSČ je povinný údaj",
    validation_address_city: "Mesto je povinný údaj",
    validation_address_country: "Krajina je povinný údaj",
    copyright: "floflo.",
    monthly: "mesačne",
    logout: "Odhlásiť sa",
    logout_successful: "Boli ste úspešne odhlásená.",
    facebook_login_no_email: "Váš Facebook účet neobsahuje emailovú adresu. Prosím vyplňte ju.",
    facebook_login_error: "Prihlásenie cez Facebook zlyhalo. Vyberte si prosím inú metódu.",
    validation_login_email: "Email nie je v správnom formáte.",
    validation_login_password: "Heslo vyzerá byť prázdne.",
    invalid_username_password: "Nesprávne prihlasovacie údaje.",
    user_does_not_exist: "Užívateľ neexistuje.",
    log_in_oauth: "Prihláste sa pomocou Google alebo Facebooku.",
    account_not_verified: "Účet nie je overený. Prosím skontrolujte si email a dokončite aktiváciu.",
    register: "Zaregistrujte sa",
    registration: "Registrácia",
    to_login: "Prihlásiť sa",
    forgot_password: "Zabudnuté heslo",
    reset_password: "Obnoviť heslo",
    forgot_password_email_sent: "Email s odkazom na obnovenie hesla bol odoslaný.",
    password_reset_token_verifying: "Overujem heslo, prosím čakajte.",
    password_reset_token_invalid: "Požiadavka na zmenu hesla nie je správna.",
    password_reset_token_used: "Požiadavka na zmenu hesla už nie je platná.",
    password_reset_token_success: "Vaše heslo bolo úspešne zmenené! Môžete sa vrátiť k prihláseniu do floflo.",
    password: "Heslo",
    current_password: "Súčasné heslo",
    new_password: "Nové heslo",
    confirm_new_password: "Potvrďte nové heslo",
    confirm_password: "Potvrdiť heslo",
    phone_number: "Telefón",
    name: "Meno a priezvisko",
    street: "Ulica",
    city: "Mesto",
    country: "Krajina",
    postal_code: "PSČ",
    dob: "Dátum narodenia",
    date: "Dátum",
    time: "Čas",
    validation_account_name: "Meno je povinný údaj.",
    validation_account_avatar: "Tento typ súboru nie je podporovaný. Iba JPG, JPEG, GIF a PNG súbory je možné nahrať.",
    account_update_successful: "Váš účet bol aktualizovaný.",
    change_password: "Zmeniť heslo",
    set_password: "Nastaviť heslo",
    validation_current_password: "Súčasné heslo nemôže byť prázdne.",
    validation_change_password_mismatch: "Zlé súčasné heslo.",
    change_password_successful: "Zmena hesla prebehla úspešne.",
    set_password_successful: "Heslo bolo úspešne nastavené.",
    credit_cards: "Platobné karty",
    credit_card: "Platobná karta",
    select_credit_card: "Zvoľte platobnú kartu",
    add_new_credit_card: "Pridať novú platobnú kartu",
    use_existing_credit_card: "Použiť existujúcu platobnú kartu",
    enter_payment_details: "Zadať platobné údaje",
    please_authorize_payment: "Prosím potvrďte platbu",
    no_payment_details_required: "Platobné údaje nie sú potrebné. Prejdite prosím na sumár objednávky.",
    no_credit_cards_yet: "Žiadne platobné karty",
    no_active_credit_card: "Žiadna aktívna platobná karta",
    credit_card_brand: "Card brand",
    credit_card_number: "Číslo karty",
    credit_card_expiration: "Dátum expirácie",
    credit_card_cvv: "CVC",
    credit_card_incorrect_number: "Číslo karty nie je správne",
    credit_card_incorrect_expiry: "Nesprávny dátum expirácie",
    credit_card_incorrect_cvc: "Nesprávne CVC číslo",
    credit_card_payment_failed: "Platba zlyhala. Prosím zadajte inú kartu.",
    credit_card_error: "Platba zlyhala. Prosím zadajte inú kartu.",
    credit_card_full_name: "Full name",
    validation_credit_card_name_empty: "Meno karty je povinný údaj",
    make_default: "Zvoliť ako prednastavenú",
    make_default_credit_card: "Zvoliť túto kartu ako prednastavenú",
    credit_card_deleted_new_default: "Karta bola zmazaná a nová karta bola nastavená ako prednastavená.",
    credit_card_added: "Platobná karta bola pridaná.",
    validation_email: "Emailová adresa nie je platná.",
    validation_email_in_use: "Emailová adresa sa už používa.",
    validation_domain_not_allowed: "Doména vášho emailu nie je povolená.",
    validation_confirmEmail: "Emailové adresy sa nezhodujú",
    validation_name_empty: "Meno je povinný údaj.",
    validation_name_incomplete: "To nevyzerá ako celé meno.",
    validation_name_is_email: "Meno nemôže mať tvar emailu.",
    validation_password: "Heslo nespĺňa požiadavky.",
    validation_confirmPassword: "Heslá sa nezhodujú.",
    validation_phoneNumber: "Toto nevyzerá ako platné telefónne číslo pre Slovensko.",
    password_rules: "Heslo musí mať 8 až 25 znakov, musí obsahovať veľké aj malé písmeno, číslo a špeciálny znak. " +
        "Nesmie obsahovať medzery.",
    agree_terms_of_service_register: "Registráciou súhlasíte s našimi",
    agree_terms_of_service_subscribe: "Objednaním súhlasíte s našimi",
    terms_of_service: "Obchodné podmienky",
    terms_of_service_inflected_1: "obchodnými podmienkami",
    privacy_policy: "Zásady použitia osobných údajov",
    privacy_policy_inflected_1: "zásadami použitia osobných údajov",
    and_the: "a",
    registration_successful: "Registrácia prebehla úspešne. Budete presmerovaní na prihlásenie.",
    registration_activation_link_sent: "Registrácia bola úspešná. Na váš email bol zaslaný aktivačný link s ďaľšími inštrukciami.",
    registration_activation_token_verifying: "Overujem aktivačný token, prosím čakajte.",
    registration_activation_token_invalid: "Aktivačný token nie je platný.",
    registration_activation_token_used: "Tento aktivačný token bol už použitý.",
    registration_activation_token_success: "Váš účet bol úspešne overený! Môžete pokračovať na prihlasovaciu stránku.",
    my_account: "Moje floflo",
    my_user_account: "Moje floflo",
    edit_my_personal_info: "Zmeniť moje osobné údaje",
    user_settings_newsletter: "Newsletter",
    user_settings_newsletter_subscribed: "Odoberať marketingový newsletter",
    edit_my_address: "Zmeniť moju adresu",
    my_credit_card: "Moja platobná karta",
    add_credit_card: "Pridať platobnú kartu",
    update_credit_card: "Zmeniť platobnú kartu",
    update_subscription: "Zmeniť predplatné",
    update_subscription_cant_proceed: "Predplatné sa nedá zmeniť",
    update_subscription_no_product_selected: "Je nevyhnutný aspoň jeden produkt z radu plienok.",
    update_subscription_please_confirm: "Prosím potvrďte zmenu.",
    update_subscription_are_you_sure: "Ste si istí, že chcete zmeniť predplatné?",
    update_subscription_new_price: "Cena predplatného sa zmení z {oldPrice}€ na {newPrice}€ každý interval.",
    my_affiliate: "Môj floflo bonus",
    my_affiliate_desc: "Zdieľajte váš floflo bonus link a zlacnite si predplatné. Ak odporučíte známeho alebo známu a stane sa predplatiteľom, za každého získate zľavu 1€ mesačne do konca trvania vášho predplatného!",
    affiliate_stats_total_views: "Počet zobrazení",
    affiliate_stats_total_registered_users: "Počet registrácií",
    affiliate_stats_total_subscribers: "Počet predplatných",
    affiliate_stats_active_subscribers: "Aktívne predplatné",
    affiliate_stats_amount_saved: "Celkom ušetrené",
    affiliate_stats_total_free_samples: "Počet vzoriek",
    affiliate_code: "Affiliate kód",
    validation_affiliate_code_in_use: "Affiliate kód sa už používa",
    validation_affiliate_code_your_own: "Tento affiliate kód patrí vám",
    validation_affiliate_code_doesnt_exist: "Affiliate kód neexistuje",
    validation_affiliate_code_empty: "Affiliate kód nemôže byť prázdny",
    validation_affiliate_code_long: "Affiliate kód musí mať menej ako 20 znakov",
    validation_affiliate_reserved_word: "Affiliate kód nemôže byť vyhradené slovo.",
    validation_affiliate_code_invalid: "Affiliate kód môže obsahovať len písmená a číslice",
    update_my_affiliate: "Zmeniť môj affiliate kód",
    my_affiliate_link: "Affiliate link na zdieľanie:",
    link_copied_to_clipboard: "Link bol skopírovaný",
    affiliate_email_sent: "Email s odpurúčaním bol poslaný.",
    affiliate_email_not_sent: "Táto emailová adresa už mala interakciu s floflo.",
    send_affiliate_email_desc: "Pošlite email s vašim odporúčaním priamo svojim známym a užívajte si výhody bonusu floflo.",
    about_floflo: "Naše plienky",
    about_intro_caption: 'O produkte',
    about_intro_text: 'Uvedomujeme si, že zvýšenie pohodlia nákupu plienok pre vaše bábätko je len časť dôvodu pre využitie našich služieb. To najdôležitejšie je samotný produkt - a preto sme strávili mesiace hľadaním a testovaním tých najlepších produktov. Rozhodli sme sa pre spoluprácu so slovenským výrobcom plienok\n' +
        'BabyBabySoft, ktorý splnil všetky naše náročné kritériá pre zdravotnú nezávadnosť, komfort, použitie ekologických materiálov a udržateľnosť. Prečítajte si viac o našich plienkach. Ak napriek vyčerpávajúcim informáciam budete mať nejaké otázky, obráťte sa s dôverou na našu zákaznícku podporu.',
    about_product_1: 'Neobsahujú parfumy, chlór, latex',
    about_product_2: 'Ultra absorbčné jadro',
    about_product_3: 'Hypoalergénne',
    about_product_4: 'Neboli testované na zvieratách',
    technology_main_1: 'NOVÁ TECHNOLÓGIA: FAST & DRY SYSTEM (VEĽKOSŤ 4 A 5)',
    technology_main_2: 'TOPSHEET – 3 TENUČKÉ PRIEDUŠNÉ VRSTVY',
    technology_main_3: 'BACKSHEET – EXTRA MÄKKÝ POVRCH',
    technology_main_4: 'VYSOKOABSORBČNÉ 3D JADRO',
    technology_main_5: 'PRUŽNÝ BOČNÝ PÁS',
    technology_main_6: 'PRIEDUŠNÉ BOČNÉ MANŽETY',
    technology_sub_1: 'Poskytuje ešte väčšiu ochranu proti úniku. Tekutina sa rýchlo distribuuje z povrchu do jadra plienky, kde sa uzamkne. Štruktúra plienky po naplnení ' +
        'tekutinami nestráca tvar a na tele dieťatka drží oveľa pevnejšie. Deti sú aktívnejšie ako batoľatá, práve tu je táto vlastnoť mimoriadne dôležitá. Vzduchové ' +
        'priestory zabezpečujú cirkuláciu vzduchu a dieťa zostáva suché až 12 hodín - dokonca aj v noci.',
    technology_sub_2: 'Vyrobené veľmi jemným spôsobom z netkanej textílie, ktorá je priedušná a ľahká. Vďaka tomu umožňuje hebkej detskej pokožke voľne dýchať a ' +
        'súčasne rýchlo vstrebáva vlhkosť. Neobsahuje žiadne parfumy ani farbivá.',
    technology_sub_3: 'Vyrobený z vodeodolnej priedušnej fólie, ktorá je laminovaná netkanou textíliou. Je pokrytá mäkkým závojom s potlačou. Pri tlači používame iba ' +
        'farbivá bez obsahu kovov, ktoré sú maximálne bezpečné pre vaše bábätko.',
    technology_sub_4: 'Pozostáva z mikrojemných absorbčných guličiek (SAP), ktoré dokážu vstrebať až niekoľko-násobok svojej váhy. SAP je antibakteriálny, netoxický a nezaťažuje prírodu. ' +
        'Druhá zložka je buničina, ktorá odvádza tekutinu do jadra plienky umiestnenej po celej absorbčnej ploche. Buničina je rozložiteľná, pochádza z certifikovaného udržateľného lesného ' +
        'hospodárstva (PEFC), je bielená vzduchom a neobsahuje žiaden chlór.',
    technology_sub_5: 'Vďaka prepracovaným elastickým prvkom poskytuje účinnú a jemnú ochranu. Dokonalým prispôsobením sa telu dieťatka je tlak vyvíjaný na pokožku až 4-násobne menší. ' +
        'Súčasne predchádza podráždeniu pokožky a samozrejme úniku tekutiny.',
    technology_sub_6: 'Perfektne kopírujú nožičky bábätka, dovnútra plienky vpúšťajú vzduch a zabraňujú pretekaniu.',
    main_features_main_1: 'Ultra absorbčné',
    main_features_main_2: 'Jemné a šetrné',
    main_features_main_3: 'Ekologicky zodpovední',
    main_features_main_4: 'Priedušné a pohodlné',
    main_features_sub_1: 'Špeciálne vyvinutý absorbčný materiál, ktorý udrží až 30- násobok vlastnej váhy. Nová technológia absorbcie uzamkne tekutinu v spodných vrstvách plienky a tým chráni citlivú detskú pokožku pred podráždením.',
    main_features_sub_2: 'Extra jemné na dotyk, vďaka materiálu podobnému mäkkej textílie. Naše detské plienky sú vyrobené len z ekologicky čistých a bezpečných materiálov, neobsahujú chlór, parfumy, ani latex. ' +
        'Sú hypoalergénne a vhodné aj na citlivú detskú pokožku.',
    main_features_sub_3: 'Rešpektujeme životné prostredie a pri výrobe dbáme na celý životný cyklus - od výberu surovín, cez dopravu až po recykláciu. Naše plienky, ktoré su vyrábané z certifikovanej PEFC buničiny,získavanej z obnoviteľných zdrojov, ' +
        'ktoré zabraňujú likvidácii lesov a tak sa starajú o ich zachovanie pre ďalšie generácie. Vyrobené v ♡ Slovenska, naše plienky k vám putujú v priemere len 300km, čím spoločne znižujeme uhlikovú stopu dopravy.',
    main_features_sub_4: 'Priedušný vonkajší povrch umožňuje citlivej detskej pokožke voľne dýchať a znižuje tak riziko zaparenín. Anatomický tvar a flexibilné prvky zaisťujú dokonalé uchytenie plienky a voľnosť pohybu v každom štádiu vývoja dieťatka.',
    close: "Zavrieť",
    to_verification: "Overiť",
    submit: "Odoslať",
    pay: "Zaplatiť",
    post: "Odoslať",
    save: "Uložiť",
    update: "Zmeniť",
    reset: "Resetovať",
    confirm: "Potvrdiť",
    send: 'Poslať',
    copy: 'Skopírovať',
    continue: "Ďalej",
    continue_to_payment: "Pokračovať na platbu",
    cancel: "Zrušiť",
    edit: "Zmeniť",
    accept: "Prijať",
    or: "alebo",
    complete: "Complete",
    back: "Späť",
    next: "Ďalej",
    upload: "Upload",
    delete: "Delete",
    default: "Default",
    roles: "Roles",
    rows_per_page: "Rows per page",
    all_rows: "All",
    of: "of",
    on: "on",
    yes: "Áno",
    no: "Nie",
    enabled: "Enabled",
    disabled: "Disabled",
    enable: "Enable",
    disable: "Disable",
    status: "Status",
    created_by: "Created by",
    on_day: 'on',
    created_on: "Created on",
    default_error: "The action could not be completed. Please try again later.",
    optimistic_locking_error: "Someone else has modified the information while you were viewing it. Please refresh the page.",
    terms_of_service_headline: "Všeobecné obchodné podmienky",
    terms_of_service_I: "Všeobecné ustanovenia a základné pojmy",
    terms_of_service_I_1: "Predmetom týchto všeobecných obchodných podmienok (ďalej ako „VOP“) je úprava vzájomných práv a povinností Poskytovateľa služby „floflo - plienky bez starostí“, (ďalej aj ako „služba“) a s tým spojenými právami a povinnosťami pri dodaní predplatného na plienky pre registrovaného Užívateľa najmä pri:",
    terms_of_service_I_1_1: "prevádzkovaní služieb, ktoré poskytuje Poskytovateľ služby prostredníctvom internetu na webovej stránke floflo.sk a všetkých jej podstránkach (ďalej aj ako „floflo.sk“),",
    terms_of_service_I_1_2: "využívaní Poskytovateľom poskytovaných služieb zo strany registrovaného Užívateľa,",
    terms_of_service_I_1_3: "dodaní Predplatného na plienky spojenom s poskytovaním služieb prostredníctvom floflo.sk;",
    terms_of_service_I_1_4: "iných právnych vzťahoch súvisiacich s hlavným zmluvným vzťahom medzi Poskytovateľom služby a Užívateľom alebo z neho vyplývajúcich.",
    terms_of_service_I_2: "Poskytovateľ služby a prevádzkovateľ webovej stránky floflo.sk je obchodná spoločnosť Extropian s.r.o., so sídlom: Idanská 775/27, 040 11 Košice, IČO: 53179927, zapísaná v obchodnom registri Okresného súdu Košice I, oddiel Sro, vložka č. 49528/V (ďalej ako „Poskytovateľ“ alebo „Predávajúci“).",
    provider_contact_details: "Kontaktné údaje Poskytovateľa:",
    provider_contact_email: "Email: ahoj@floflo.sk",
    provider_contact_phone: "Telefónne číslo: +421 904 943 833",
    provider_contact_address: "Adresa: Idanská 775/27, 040 11 Košice, Slovenská republika",
    terms_of_service_I_3: "Registrovaný Užívateľ je fyzická osoba, ktorá prostredníctvom vyplnenia registračného formuláru a vykonania elektronickej objednávky Predplatného bez viazanosti má vôľu využívať služby Poskytovateľa, teda stať sa zákazníkom „floflo - plienky bez starostí“ a splnila nižšie uvedené podmienky registrácie:",
    terms_of_service_I_3_1: "riadne sa zaregistrovala na stránke floflo.sk,",
    terms_of_service_I_3_2: "uhradila cenu za príslušné predplatné,",
    terms_of_service_I_3_3: "vyjadrila bezvýhradný súhlas s týmito VOP a ďalšími dokumentami Poskytovateľa,",
    terms_of_service_I_3_4: "prípadne splnila ďalšie podmienky uvedené v elektronickej objednávke príslušného predplatného.",
    terms_of_service_I_3_hereinafter: "(ďalej ako „Užívateľ“ alebo „Kupujúci“)",
    terms_of_service_I_4: "Neregistrovaný užívateľ je fyzická osoba, ktorá nesplnila podmienku podľa článku I bodu 3 písm. a), a b) týchto VOP a nestala sa predplatiteľom „floflo -plienky bez starostí“. Neregistrovaný užívateľ je oprávnený na využívanie dostupných bezplatných funkcií služieb „Testovacie plienky zdarma“ v rozsahu vymedzenom Poskytovateľom. Poskytovateľ je oprávnený jednostranne meniť, upravovať, odoberať, či úplne zrušiť bezplatné funkcie ním poskytovaných služieb.",
    terms_of_service_I_5: "Splnením podmienok uvedených v bode 3 tohto článku VOP dochádza medzi Poskytovateľom a Užívateľom, ktorý je spotrebiteľom – fyzickou osobou k uzatvoreniu zmluvy uzavretej na diaľku v zmysle § 2 ods. 1 a 3 zákona č. 102/2014 Z. z. o ochrane spotrebiteľa pri predaji tovaru alebo poskytovaní služieb na základe zmluvy uzavretej na diaľku alebo zmluvy uzavretej mimo prevádzkových priestorov predávajúceho a o zmene a doplnení niektorých zákonov v znení neskorších predpisov (ďalej len „zákon č. 102/2014 Z. z. “). Uzatvorením zmluvy podľa tohto bodu za podmienok uvedených v týchto VOP dochádza medzi Poskytovateľom a Užívateľom súčasne k uzatvoreniu Dohody o predplatnom v zmysle § 2 ods. 4 zákona č. 102/2014 Z. z.",
    terms_of_service_I_6: "Poskytovateľ spracúva a používa osobné údaje Užívateľa v súvislosti s poskytovaním služieb v súlade s Vyhlásením o ochrane osobných údajov (Privacy Policy), ktoré sa nachádza na webovej stránke floflo.sk.",
    terms_of_service_II: "Popis poskytovaných služieb a zodpovednosť",
    terms_of_service_II_1: "Služba „floflo - plienky bez starostí“ poskytovaná Poskytovateľom prostredníctvom internetu na webovej stránke floflo.sk je spoplatnené predplatné na plienky a súvisiace produkty, ktoré slúži na pravidelnú bezstarostnú dodávku plienok podľa preferencií Užívateľa, a to vo frekvencii dodania podľa preferencií Užívateľa (ďalej ako „predplatné“). Ide o predplatné, do ktorého môže vstúpiť Užívateľ vo vybraných intervaloch dodania určených Poskytovateľom na webovej stránke floflo.sk. Užívateľ „floflo - plienky bez starostí“ si po registrácii v prípade záujmu objedná službu predplatného, pričom v elektronickej objednávke si zvolí a) typ a veľkosť plienok, b) frekvenciu dodania a c) prípadne „preferencie Užívateľa“, ďalej vyplní údaje potrebné k dodaniu služby a uhradí vygenerovanú cenu predplatného v závislosti od vyššie uvedených zvolených parametrov. Poskytovateľ sa zaväzuje podľa vyššie uvedených zvolených parametrov Užívateľa dodávať Užívateľovi konkrétne Poskytovateľom profesionálne vybrané produkty za podmienok uvedených v týchto VOP. Bližšie informácie o tejto službe a o produkte je možné nájsť na webovej stránke floflo.sk alebo ďalej v týchto VOP.",
    terms_of_service_II_2: "Poskytovateľ nezodpovedá za nepretržitú a bezchybnú dostupnosť a funkčnosť webovej stránky floflo.sk a zároveň je oprávnený z dôvodu potreby údržby alebo z iných technických príčin obmedziť Užívateľovi možnosť využívania webovej stránky na nevyhnutne potrebný čas. V prípadoch prerušenia pripojenia podľa predchádzajúcej vety alebo technickej poruchy na serveroch Poskytovateľa alebo tretích strán, v dôsledku ktorej nebolo možné využívať služby Poskytovateľa nemá Užívateľ právo na náhradu škody, domáhanie sa vrátenia zaplatených peňažných prostriedkov alebo inej formy kompenzácie.",
    terms_of_service_II_3: "Poskytovateľ je oprávnený jednostranne vykonať akékoľvek úpravy dizajnu, zamerania, obsahu, pravidiel, funkčnosti webovej stránky floflo.sk bez nutnosti predchádzajúceho oznámenia Užívateľovi. Užívateľ nie je oprávnený v dôsledku týchto zmien domáhať sa náhrady škody, vrátenia zaplatených peňažných prostriedkov alebo inej formy kompenzácie.",
    terms_of_service_II_4: "Poskytovateľ nezodpovedá za správnosť, presnosť a aktuálnosť údajov Užívateľa. Za správnosť, presnosť a aktuálnosť údajov zodpovedá výlučne Užívateľ, ktorý sa registroval. ",
    terms_of_service_II_5: "Poskytovateľ nie je zodpovedný za žiadne škody alebo straty nahraných údajov Užívateľom spôsobené jeho aktivitami, technickými poruchami, zlyhaním alebo aktivitami tretích strán.",
    terms_of_service_III: "Objednávka a dodanie služby",
    terms_of_service_III_1: "Základným predpokladom pre začatie využívania služby „floflo - plienky bez starostí“ poskytovanej Poskytovateľom je prihlásenie Užívateľa na webovej stránke floflo.sk a to zadaním platnej emailovej adresy Užívateľa (ďalej aj ako „Kontaktný email“) a hesla Užívateľa prípadne prihlásenia cez osobný účet Facebook, Gmail.",
    terms_of_service_III_2: "Užívateľ má po aktivácii účtu právo upraviť vo svojom účte svoje osobné údaje za účelom dodania tovaru.",
    terms_of_service_III_3: "Počas nákupného procesu Užívateľ poskytne Poskytovateľovi na účely dodania tovaru nasledovné údaje:",
    terms_of_service_III_3_1: "Meno, priezvisko",
    terms_of_service_III_3_2: "Adresa trvalého bydliska",
    terms_of_service_III_3_3: "Adresa doručenia",
    terms_of_service_III_3_4: "Telefonický kontakt",
    terms_of_service_III_3_5: "Kontaktný email",
    terms_of_service_III_4: "V procese objednania predplatného si Užívateľ zadá svoju platobnú kartu, prostredníctvom ktorej bude uhrádzať predplatné. Výhodou predplatného je poskytnutie dopravy tovaru zdarma v rámci Slovenskej republiky. Objednávku Užívateľ ukončí stlačením tlačidla „Objednať s povinnosťou platby“. Pred odoslaním objednávky má Užívateľ k dispozícii odkaz na tieto VOP. Odoslanie objednávky nie je možné bez potvrdenia oboznámenia sa s týmito VOP. Odoslaním objednávky Užívateľ potvrdzuje, že sa mal možnosť oboznámiť s:",
    terms_of_service_III_4_1: "podmienkami a výhodami príslušného predplatného,",
    terms_of_service_III_4_2: "celkovou cenou príslušného predplatného, ktorú je povinný Užívateľ Poskytovateľovi uhradiť,",
    terms_of_service_III_4_3: "termínom, dokedy je povinný cenu príslušného predplatného Užívateľ Poskytovateľovi uhradiť,",
    terms_of_service_III_4_4: "týmito VOP a ďalšími dokumentami Poskytovateľa.",
    terms_of_service_III_5: "Po odoslaní objednávky Užívateľa sa táto zaregistruje v systéme Poskytovateľa a súčasne Poskytovateľ zašle na Kontaktný e-mail Užívateľa potvrdenie objednávky s informáciami o príslušnom predplatnom. Doručením potvrdenia o prijatí objednávky príslušného predplatného Užívateľovi sa zmluva medzi Poskytovateľom a Užívateľom považuje za uzavretú.",
    terms_of_service_III_6: "Poskytovateľ sa zaväzuje začať poskytovať službu „floflo - plienky bez starostí“ Užívateľovi najneskôr do 3 pracovných dní odo dňa uhradenia ceny príslušného predplatného. Bezprostredne po riadnej a včasnej úhrade ceny predplatného odošle Poskytovateľ emailom na Kontaktný email Užívateľa faktúru – daňový doklad osvedčujúci uhradenie ceny predplatného a informáciu o uhradení platby. V prípade, že Užívateľ neuhradí cenu predplatného, môže sa prihlásiť do svojho účtu a využívať výlučne dostupné bezplatné funkcie služieb Poskytovateľa. Prístupovými údajmi Užívateľa do jeho účtu sú login (prihlasovacie meno) vyplnené Užívateľom a heslo, prípadne Užívateľ sa môže do svojho účtu prihlásiť prostredníctvom osobného účtu Facebook, Gmail. Po prihlásení sa do svojho účtu môže Užívateľ vo svojom účte zmeniť údaje vyplnené Užívateľom.",
    terms_of_service_III_7: "Služba je dodaná zo strany Poskytovateľa Užívateľovi dodaním objednaných plienok v zmysle týchto VOP do rúk Užívateľa alebo ním splnomocnenej osoby. Predpokladom prevzatia služby Užívateľom v plnom rozsahu je predchádzajúce zaplatenie ceny príslušného predplatného. ",
    terms_of_service_III_8: "Poskytovateľ službu „floflo - plienky bez starosti“ poskytuje výlučne za odplatu. Užívateľ si je vedomý, že ide o predplatné a zaväzuje sa uhrádzať cenu príslušného predplatného v pravidelných intervaloch, ktoré si Užívateľ zvolil a to až do doby kedy nepríde k ukončeniu zmluvy. V prípade, ak Užívateľ neuhradí včas predplatné, prípadne nemá na platobnej karte, prostredníctvom ktorej uhrádza predplatné dostatok finančných prostriedkov, nemá Užívateľ nárok na dodanie služby „floflo - plienky bez starostí“ a táto služba mu bude pozastavená, až do uhradenia predplatného. ",
    terms_of_service_III_9: "Poskytovateľ ponúka predplatné „floflo - plienky bez starostí“, ktoré si môže užívateľ v závislosti od jeho preferencií zakúpiť. Obsahom predplatného je dodanie jedného, či viac balení plienok zvolenej veľkosti, prípadne dodatočných produktov. Cena predplatného je vypočítaná počas nákupného procesu, po pridaní posledného produktu. Po zaplatení ceny tohto predplatného je Užívateľ oprávnený v plnom rozsahu využívať službu na obdobie, ktoré si sám zadefinoval a to v rozsahu 3, 4, 5, alebo 6 týždňov od zaplatenia ceny predplatného, pričom v cene predplatného je jedno dodanie tovaru, ktoré obsahuje vybrané produkty v zmysle tohto bodu VOP za uvedené obdobie. Poskytovateľ dodáva užívateľovi plienky 1x za zvolený interval.",
    terms_of_service_III_10: "Poskytovateľ je platiteľom DPH, ale ceny uvádzané v týchto VOP sú ceny konečné a nebude k nim pripočítaná DPH.",
    terms_of_service_III_11: "Poskytovateľ je oprávnený jednostranne meniť, upravovať, odoberať, či pridávať typy predplatného, a/alebo meniť cenu alebo zloženie existujúcich predplatných. O predmetných zmenách bude Užívateľ vopred upozornený emailom alebo oznamom po prihlásení na webovú stránku floflo.sk. V prípade, ak Užívateľ nezruší svoje predplatné má sa za to, že súhlasil s oznámenými zmenami. V prípade, ak sa zmení cena služby a Užívateľ nezrušil svoje predplatné, Užívateľ je povinný platiť novú cenu služby. Užívatelia, ktorí si zakúpili predplatné pred jeho zmenou, nie sú oprávnení po zmene požadovať akékoľvek kompenzácie, vrátenie peňazí na základe nových vlastností alebo ceny predplatného.",
    terms_of_service_IV: "Trvanie zmluvy a možnosti ukončenia zmluvy",
    terms_of_service_IV_1: "Užívateľ, ktorý si zakúpil predplatné „floflo - plienky bez starosti“ má právo kedykoľvek bez udania dôvodu alebo s uvedením dôvodu vypovedať zmluvu ku dňu doručenia výpovede Poskytovateľovi. V takomto prípade Užívateľ nemá právo na vrátenie akejkoľvek časti peňažných prostriedkov zaplatených za služby poskytované Poskytovateľom ku dňu vypovedania Zmluvy. Užívateľ, ktorý si zakúpil predplatné „floflo - plienky bez starosti“ a vypovedal Zmluvu, nemá právo na vrátenie alikvotnej časti ceny predplatného za nevyužité predplatené obdobie ani na vrátenie akejkoľvek časti peňažných prostriedkov zaplatených za služby poskytované Poskytovateľom ku dňu vypovedania Zmluvy.",
    terms_of_service_IV_2: "Poskytovateľ je oprávnený bez udania dôvodu vypovedať Zmluvu a ukončiť poskytovanie služby alebo ukončiť prevádzkovanie internetovej stránky, na ktorej sú služby dostupné. Výpovedná doba je 30 dní a začína plynúť dňom oznámenia o vypovedaní zmluvy a plánovanom ukončení poskytovania služby alebo ukončenia prevádzkovania internetovej stránky, na ktorej sú služby dostupné. Poskytovateľ je oprávnený vypovedať Zmluvu týmto spôsobom hromadne vo vzťahu ku všetkým Užívateľom a nie je povinný oboznamovať každého Užívateľa osobitným oznámením. Oznámenie sa považuje za doručené všetkým Užívateľom prvým dňom jeho zverejnenia na internetovej stránke floflo.sk. Užívateľ nie je oprávnený v dôsledku vypovedania Zmluvy Poskytovateľom a jednostranného ukončenia poskytovania služby alebo ukončenia prevádzkovania internetovej stránky, na ktorej sú služby dostupné zo strany Poskytovateľa domáhať sa náhrady škody, vrátenia zaplatených peňažných prostriedkov alebo inej formy kompenzácie.",
    terms_of_service_IV_3: "Užívateľ uzatvorením Zmluvy a súhlasom s týmito VOP zároveň udeľuje Poskytovateľovi výslovný súhlas so začatím poskytovania služby pred uplynutím lehoty na odstúpenie od zmluvy a zároveň uzatvorením Zmluvy a súhlasom s týmito VOP potvrdzuje, že bol riadne poučený o tom, že udelením súhlasu so začatím poskytovania služby pred uplynutím lehoty na odstúpenie od zmluvy stráca právo na odstúpenie od zmluvy.",
    terms_of_service_IV_4: "Užívateľ uzavretím Zmluvy a súhlasom s týmito VOP potvrdzuje, že bol poučený o tom, že podľa § 6 ods. 1 písm. b) zákona č. 102/2014 Z. z. nie je oprávnený odstúpiť od tejto Zmluvy z dôvodu, že bol riadne poučený o tom, že udelením súhlasu so začatím poskytovania služby pred uplynutím lehoty na odstúpenie od zmluvy stráca právo na odstúpenie od zmluvy a aj po tomto poučení udelil Poskytovateľovi výslovný súhlas so začatím poskytovania služby pred uplynutím lehoty na odstúpenie od zmluvy.",
    terms_of_service_V: "Platobné a dodacie podmienky",
    terms_of_service_V_1: "Platobné podmienky:",
    terms_of_service_V_1_1: "Ak sa na tovar vzťahuje akciová cena, je platná od dátumu zverejnenia akcie v popise služieb. Akékoľvek dodatočné zľavy, ktoré sú poskytované nad rámec zliav uvádzaných v popise služieb, individuálne zľavy pre Užívateľa alebo zľavy poskytované na základe zmluvnej spolupráce Poskytovateľa s tretími stranami, nie sú vzájomne kombinovateľné.",
    terms_of_service_V_1_2: "Cenu služieb podľa objednávky Užívateľ uhradí platobnou bránou Stripe, pričom budú prijímané platby kartami VISA a Mastercard.",
    terms_of_service_V_1_3: "Poskytovateľ si vyhradzuje právo dočasne alebo trvalo obmedziť spôsoby úhrady z technických príčin, z dôvodov prekážok na strane Poskytovateľa alebo sprostredkovateľa týchto služieb alebo z dôvodu vyššej moci.",
    terms_of_service_V_1_4: "Za úhradu ceny služieb si Poskytovateľ neúčtuje poplatok. Poskytovateľ upozornil Užívateľa, že subjekt, u ktorého je úhrada ceny realizovaná, si môže účtovať poplatok za úhradu (bankové poplatky za prevod a pod.).",
    terms_of_service_V_1_5: "Úhradou sa rozumie moment pripísania ceny služieb na bankový účet Poskytovateľa alebo moment potvrdenia sprostredkovateľa platby o jej realizácii.",
    terms_of_service_V_2: "Dodacie podmienky:",
    terms_of_service_V_2_1: "Pokiaľ si Užívateľ zvolil spôsob dopravy sprostredkovateľom doručenia, cena dopravy v rámci Slovenskej republiky ide na ťarchu Poskytovateľa. Skutočnosť poskytnutia dopravy na ťarchu Poskytovateľa je uvedená v popise služieb ako aj v elektronickej objednávke.",
    terms_of_service_V_2_2: "Poskytovateľ sa zaväzuje dodať tovar Užívateľovi v primeranej lehote (ďalej aj „dodacia lehota“). Dodávky tovaru budú realizované podľa prevádzkových možností Poskytovateľa, v závislosti od zvoleného intervalu dodávania predplatného na plienky. Vo výnimočných prípadoch môže Poskytovateľ jednostranne predĺžiť dodaciu lehotu, a to aj opakovane. Poskytovateľ si vyhradzuje právo predĺžiť dodaciu lehotu v prípadoch vzniknutých vyššou mocou alebo v prípadoch, keď nie je možné z objek¬tív¬nych príčin uskutočniť dodávku v obvyklej dodacej lehote. V prípade zmeny dodacej lehoty Poskytovateľ informuje o tejto skutočnosti Užívateľa prostredníctvom emailu. O odoslaní tovaru je Užívateľ dodatočne informovaný prostredníctvom emailu.",
    terms_of_service_V_2_3: "Poskytovateľ doručí Užívateľovi tovar na adresu, ktorú Užívateľ uviedol ako adresu doručenia v objednávke služieb. Súčasťou dodania tovaru nie je inštalácia ani iné úkony (napr. vyloženie tovaru a pod.). Užívateľ je povinný prevziať tovar v mieste dodania osobne alebo zabezpečiť, aby tovar prevzala osoba, ktorú splnomocní pre prípad svojej neprítomnosti na prevzatie tovaru. ",
    terms_of_service_V_2_4: "Užívateľ je povinný skontrolovať dodaný tovar ako aj jeho obal ihneď pri jeho dodaní. V prípade, ak Užívateľ zistí, že tovar alebo obal tovaru je mechanicky poškodený, je povinný túto skutočnosť preukázateľne zápisom na potvrdenie o dodaní oznámiť sprostredkovateľovi doručenia a za jeho prítomnosti skontrolovať stav tovaru v zásielke. V prípade zistenia poško¬denia tovaru, je Užívateľ povinný vyhotoviť záznam o rozsahu a povahe poškodenia tovaru na dokladoch o doručení, ktorého správnosť potvrdí sprostredkovateľ doručenia. Na základe takto vyhotoveného záznamu doručeného Poskytovateľovi môže následne Poskytovateľ poskytnúť Užívateľovi zákonné nároky z vád tovaru. Poskytovateľ nezodpovedá za poškodenia tovaru spôsobené Užívateľom.",
    terms_of_service_V_2_5: "Užívateľ je povinný svojím podpisom, alebo podpisom splnomocnenej osoby, pri prevzatí tovaru potvrdiť Poskytovateľovi na dodacom liste prevzatie tovaru.",
    terms_of_service_VI: "Nadobudnutie vlastníctva a prechod nebezpečenstva škody na tovare:",
    terms_of_service_VI_1: "Užívateľ nadobúda vlastnícke právo k tovaru jeho prevzatím.",
    terms_of_service_VI_2: "Nebezpečenstvo škody na tovare prechádza na Užívateľa v čase, keď prevezme tovar od Poskytovateľa alebo keď tak neurobí včas, tak v čase, keď mu Poskytovateľ umožní nakladať s tovarom a Užívateľ tovar neprevezme.",
    terms_of_service_VII: "Reklamačný poriadok (zodpovednosť za vady, záruka, spôsob reklamácie) a Alternatívne riešenie sporov",
    terms_of_service_VII_1: "Ak tovar vykazuje zjavné chyby, t.j. najmä ak je tovar Užívateľovi dodaný v poškodenom transportnom obale, je Užívateľ oprávnený tovar neprevziať. V prípade, že sa po prevzatí tovaru Užívateľom vyskytnú v záručnej dobe chyby tovaru, môže Užívateľ uplatniť svoju reklamáciu.",
    terms_of_service_VII_2: "Užívateľ je povinný skontrolovať dodaný tovar ako aj jeho obal ihneď pri jeho dodaní. V prípade, ak Užívateľ zistí, že tovar alebo obal tovaru je mechanicky poškodený, je povinný túto skutočnosť preukázateľne zápisom na potvrdenie o dodaní oznámiť sprostredkovateľovi doručenia a za jeho prítomnosti skontrolovať stav tovaru v zásielke. V prípade zistenia poško-denia tovaru, je Užívateľ povinný vyhotoviť záznam o rozsahu a povahe poškodenia tovaru na dokladoch o doručení, ktorého správnosť potvrdí sprostredkovateľ doručenia. Ak tovar vykazuje zjavné chyby, t.j. najmä ak je tovar Užívateľovi dodaný v poškodenom transportnom obale, je Užívateľ oprávnený tovar neprevziať. Na základe takto vyhotoveného záznamu doručenému Poskytovateľovi môže následne Poskytovateľ poskytnúť Užívateľovi zákonné nároky z vád tovaru. Poskytovateľ nezodpovedá za poškodenia tovaru spôsobené Užívateľom.",
    terms_of_service_VII_3: "Dĺžka záručnej doby sa riadi platnými ustanoveniami Občianskeho zákonníka, trvá 24 mesiacov, s výnimkami stanovenými Občianskym zákonníkom. Záručná doba začína plynúť dňom prevzatia tovaru Užívateľom.",
    terms_of_service_VII_4: "Ak ide o vadu, ktorú možno odstrániť, má Užívateľ právo, aby bola bezplatne, včas a riadne odstránená. Poskytovateľ je povinný vadu bez zbytočného odkladu odstrániť. Užívateľ môže namiesto odstránenia vady požadovať výmenu veci, alebo ak sa vada týka len súčasti veci, výmenu súčasti, ak tým Poskytovateľovi nevzniknú neprimerané náklady vzhľadom na cenu tovaru alebo závažnosť vady. Poskytovateľ môže vždy namiesto odstránenia vady vymeniť závadnú vec za nezávadnú, ak to Užívateľovi nespôsobí závažné ťažkosti.",
    terms_of_service_VII_5: "Ak ide o vadu, ktorú nemožno odstrániť a ktorá bráni tomu, aby sa vec mohla riadne užívať ako vec bez vady, má Užívateľ právo na výmenu veci alebo má právo od zmluvy odstúpiť. Ak ide o iné neodstrániteľné vady, má Užívateľ právo na primeranú zľavu z ceny služieb.",
    terms_of_service_VII_6: "Poskytovateľ poučil Užívateľa o jeho právach, ktoré mu vyplývajú z ust. § 622 Občianskeho zákonníka a z ust. § 623 Občianskeho zákonníka tak, že zahrnul tento reklamačný poriadok do týchto VOP a tieto umiestnil na internetovej stránke Poskytovateľa a Užívateľ mal možnosť si ho prečítať v čase pred odoslaním objednávky.",
    terms_of_service_VII_7: "Reklamácie vrátane odstránenia vady musia byť vybavené bez zbytočného odkladu, v odôvodnených prípadoch najneskôr do 30 dní odo dňa uplatnenia reklamácie. Po uplynutí tejto lehoty má Užívateľ právo od zmluvy odstúpiť alebo má právo na výmenu tovaru za nový tovar.",
    terms_of_service_VII_8: "V situácii, keď je tovar potrebné zaslať Poskytovateľovi, koná Užívateľ tak, aby bol tovar zabalený do vhodného obalu, ktorý tovar dostatočne ochráni a vyhovuje nárokom na prepravu tovaru. ",
    terms_of_service_VII_9: "V prípade, že je reklamovaný tovar doručený poštou alebo kuriérom, zodpovedná osoba tento tovar prevezme, skontroluje zásielku a dokumentáciu k tovaru. Rozhodujúci dátum na lehotu plynutia reklamácie je dátum prijatia od kuriéra alebo poštového doručovateľa.",
    terms_of_service_VII_10: "Poskytovateľ po riadnom vybavení reklamácie vyzve Užívateľa telefonicky, emailom alebo iným spôsobom dohodnutým s Poskytovateľom k prevzatiu opraveného tovaru a reklamačného protokolu, resp. bude po vzájomnej dohode tovar spolu s reklamačným protokolom doručený Užívateľovi formou doporučenej zásielky.",
    terms_of_service_VII_11: "Nárok na uplatnenie záruky zaniká v prípade nesprávnej manipulácie s tovarom. ",
    terms_of_service_VII_12: "V prípade akejkoľvek reklamácie informuje Užívateľ Poskytovateľa o uplatnení reklamácie a dohodne sa s ním na najvhodnejšej forme reklamačného postupu.",
    terms_of_service_VII_13: "Užívateľ znáša náklady na vrátenie reklamovaného tovaru.",
    terms_of_service_VII_14: "Reklamácie sú vybavované nasledovne:",
    terms_of_service_VII_14_1: "telefonicky: +421 904 943 833 (v pracovných dňoch) ",
    terms_of_service_VII_14_2: "emailom: ahoj@floflo.sk",
    terms_of_service_VII_14_3: "poštou: Extropian s.r.o., Idanská 775/27, 040 11 Košice, Slovenská republika",
    terms_of_service_VII_14_4: "formou formuláru: “Reklamácia”, ktorý sa nachádza na webovej stránke floflo.sk.",
    terms_of_service_VII_15: "Ak nie je Užívateľ spokojný so spôsobom, ktorým Poskytovateľ vybavil jeho reklamáciu alebo ak sa domnieva, že Poskytovateľ porušil jeho práva, má právo obrátiť sa s námietkou na Poskytovateľa so žiadosťou o nápravu emailom na adresu: ahoj@floflo.sk. Pokiaľ Poskytovateľ posúdi túto námietku Užívateľa zamietavo alebo na ňu neodpovie do 30 dní od jej odoslania, Užívateľ má právo podať návrh na začatie alternatívneho riešenia sporu subjektu alterna-tívneho riešenia sporov (ďalej ako „subjekt ARS“) podľa zákona č. 391/2015 Z. z. o alternatívnom riešení spotrebiteľských sporov a o zmene a doplnení niektorých zákonov v znení neskorších predpisov (ďalej ako „zákon o ARS“). Subjektmi ARS sú orgány a oprávnené právnické osoby podľa § 3 zákona o ARS, ktorých zoznam vedie príslušný štátny orgán: (https://www.mhsr.sk/obchod/ochrana-spotrebitela/alternativne-riesenie-spotrebitelskych-sporov-1/zoznam-subjektov-alternativneho-riesenia-spotrebitelskych-sporov-1). Návrh môže byť podaný spotrebiteľom podľa § 12 zákona o ARS.",
    terms_of_service_VII_16: "Užívateľ môže podať sťažnosť aj prostredníctvom platformy alternatívneho riešenia sporov RSO, ktorá je dostupná online na https://ec.europa.eu/consumers/odr/main/index.cfm.",
    terms_of_service_VII_17: "Alternatívne riešenie sporov sa týka len sporu medzi Užívateľom a Poskytovateľom, vyplývajúceho zo spotrebiteľskej zmluvy alebo súvisiaceho so spotrebiteľskou zmluvou a týka sa len  zmlúv uzatvorených na diaľku. Alternatívne riešenie sporov sa netýka sporov, kde hodnota sporu neprevyšuje sumu 20 €. Subjekt ARS môže od Užívateľa požadovať úhradu poplatku za začatie alternatívneho riešenia sporu maximálne do výšky 5 € s DPH.",
    terms_of_service_VIII: "Ďalšie práva a povinnosti zmluvných strán",
    terms_of_service_VIII_1: "Poskytovateľ je oprávnený jednostranne meniť ustanovenia týchto VOP, pričom nové VOP nadobúdajú účinnosť dňom ich zverejnenia na internetovej stránke floflo.sk/vseobecne-obchodne-podmienky. Užívateľ je oprávnený odstúpiť od Zmluvy z dôvodu zmeny VOP len v prípade, ak sa v dôsledku VOP zvyšuje cena predplatného. Užívateľ môže od Zmluvy z dôvodu podľa tohto bodu odstúpiť prostredníctvom odkazu na webovej stránke floflo.sk alebo zaslaním emailu na emailovú adresu: ahoj@floflo.sk, pričom ako dôvod musí uviesť zmenu VOP zo strany Poskytovateľa. Ak Užívateľ týmto spôsobom potvrdí svoje odstúpenie od Zmluvy, bude mu na kontaktný email doručený email potvrdzujúci zrušenie Zmluvy. Odstúpením od Zmluvy sa Zmluva zrušuje ku dňu doručenia emailu potvrdzujúceho ukončenie Zmluvy (deň doručenia tohto emailu sa považuje za deň odstúpenia od Zmluvy zo strany Užívateľa). V prípade platného odstúpenia z dôvodu podľa tohto bodu vzniká Užívateľovi nárok na preplatenie alikvotnej časti zostávajúceho predplateného obdobia ku dňu odstúpenia od Zmluvy. Odstúpením od zmluvy sa zrušuje a vymazáva účet Užívateľa.",
    terms_of_service_VIII_2: "Užívateľ súhlasom s týmito VOP zároveň potvrdzuje, že bol Poskytovateľom poučený v zmysle zákona o ARS o možnosti a podmienkach riešenia sporu prostredníctvom systému alternatívneho riešenia sporov. Orgánom alternatívneho riešenia sporov zo zákona sú Úrad pre reguláciu sieťových odvetí, Úrad pre reguláciu elektronických komunikácií a poštových služieb a Slovenská obchodná inšpekcia.",
    terms_of_service_VIII_3: "Užívateľ súhlasom s týmito VOP zároveň potvrdzuje, že bol Poskytovateľom poučený v zmysle ust. § 4 ods. 1 písm. e) zákona č. 22/2004 Z.z. o elektronickom obchode, že orgánom dozoru alebo dohľadu, ktorému činnosť Poskytovateľa služieb podlieha je Slovenská obchodná inšpekcia (SOI), Inšpektorát SOI pre Bratislavský kraj, P. O. BOX 29, Bajkalská 21/A, 827 99 Bratislava, Odbor výkonu dozoru, tel. č. tel. č. 02/58 27 21 72, 02/58 27 21 04, fax č. 02/58 27 21 70.",
    terms_of_service_VIII_4: "Užívateľ súhlasom s týmito VOP zároveň potvrdzuje, že bol Poskytovateľom poučený o tom, že „floflo“ je značka používaná výlučne pre tento typ služieb a webová stránka floflo.sk je autorským dielom požívajúcim ochranu v zmysle zákona č. 185/2015 Z.z. autorský zákon. Držiteľom neobmedzenej licencie na použitie tohto diela v súlade s § 19 ods. 4 zákona č. 185/2015 Z.z. autorský zákon je Poskytovateľ.",
    terms_of_service_VIII_5: "Užívateľ súhlasom s týmito VOP zároveň potvrdzuje, že si je vedomý skutočnosti, že doručením obsahu služieb poskytnutých Poskytovateľom a možnosťou využívať predplatné „floflo - plienky bez starosti“, sa na Užívateľa neprevádzajú žiadne majetkové ani iné práva k týmto službám, a poskytnutie týchto služieb nezakladá vzdanie sa akýchkoľvek autorských práv alebo majetkových práv Poskytovateľa alebo tretích osôb. Akékoľvek použitie predplatného „floflo - plienky bez starosti“ alebo ochrannej známky iným spôsobom ako využívaním služieb podľa tejto Zmluvy vyžaduje predchádzajúci písomný súhlas Poskytovateľa.",
    terms_of_service_VIII_6: "Užívateľ súhlasom s týmito VOP zároveň potvrdzuje, že si je vedomý skutočnosti, že služby, vrátane obsahu poskytnutého prostredníctvom týchto služieb, grafické zobrazenia, užívateľské rozhrania, audio-klipy, videoklipy, redakčný obsah, zdrojové kódy (skripty) a softvér použité na realizáciu webovej stránky floflo.sk a ďalších služieb podľa tejto Zmluvy, obsahujú chránené informácie, ktorých vlastníkom je Poskytovateľ a/alebo jeho poskytovatelia licencií, a ktoré sú chránené právnymi predpismi upravujúcimi duševné vlastníctvo a inými právnymi predpismi. Žiadna časť služieb poskytnutých podľa tejto Zmluvy nesmie byť rozmnožovaná akoukoľvek formou alebo akýmikoľvek prostriedkami, pokiaľ to Zmluva alebo tieto VOP výslovne nedovoľujú. Užívateľ sa zaväzuje, že nebudete žiadnym spôsobom vypožičiavať, upravovať, prenajímať, požičiavať predávať alebo rozširovať služby alebo obsah poskytnutý prostredníctvom týchto služieb akýmkoľvek spôsobom.",
    terms_of_service_IX: "Záverečné ustanovenia",
    terms_of_service_IX_1: "Na vzťahy neupravené týmito VOP sa vzťahujú najmä príslušné ustanovenia Občianskeho zákonníka, zákona č. 102/2014 Z.z. o ochrane spotrebiteľa pri predaji tovaru alebo poskytovaní služieb na základe zmluvy uzavretej na diaľku alebo zmluvy uzavretej mimo prevádzkových priestorov predávajúceho a o zmene a doplnení niektorých zákonov, zákona č. 250/2007 Z.z. o ochrane spotrebiteľa a o zmene zákona Slovenskej národnej rady č. 372/1990 Zb. o priestupkoch v znení neskorších predpisov, zákona č. 22/2004 Z.z. o elektronickom obchode a o zmene a doplnení zákona č. 128/2002 Z.z. o štátnej kontrole vnútorného trhu vo veciach ochrany spotrebiteľa a o zmene a doplnení niektorých zákonov v znení zákona č. 284/2002 Z.z. v znení neskorších predpisov a ďalších súvisiacich právnych predpisov.",
    terms_of_service_IX_2: "Tieto VOP nadobúdajú platnosť a účinnosť dňa 1. Mája 2021.",
    terms_of_service_IX_3: "Slovenská verzia má vždy prednosť v prípade rozporu medzi slovenskou verziou a jej anglickým prekladom.",
    terms_of_service_IX_4: "V Bratislave, dňa 1.5.2021.",
    privacy_policy_headline: "Vyhlásenie o ochrane osobných údajov – privacy policy",
    privacy_policy_I: "Identifikácia prevádzkovateľa, príslušné právne predpisy a vymedzenie niektorých pojmov",
    privacy_policy_I_1: "Spoločnosť Extropian s.r.o., so sídlom Idanská 775/27 040 11 Košice - mestská časť Západ, IČO: 53179927, zapísaná v obchodnom registri Okresného súdu Košice I, oddiel: Sro, vložka č. 49528/V (ďalej ako „Prevádzkovateľ”) je pri spracúvaní osobných údajov Dotknutých osôb zodpovedná za dodržiavanie právnych predpisov o ochrane osobných údajov, a to najmä Nariadenia Európskeho parlamentu a Rady (EÚ) 2016/679 z 27. apríla 2016 o ochrane fyzických osôb pri spracúvaní osobných údajov a o voľnom pohybe takýchto údajov, ktorým sa zrušuje smernica 95/46/ES (všeobecné nariadenie o ochrane údajov)  (ďalej aj ako „GDPR”) a zákona č. 18/2018 Z. z. o ochrane osobných údajov a o zmene a doplnení niektorých zákonov (ďalej aj ako „zákon o ochrane osobných údajov”).",
    privacy_policy_I_1_1_bold: "Osobnými údajmi",
    privacy_policy_I_1_1_regular: "sú údaje týkajúce sa identifikovanej fyzickej osoby alebo identifikovateľnej fyzickej osoby, ktorú možno identifikovať priamo alebo nepriamo, najmä na základe všeobecne použiteľného identifikátora, iného identifikátora, ako je napríklad meno, priezvisko, identifikačné číslo, lokalizačné údaje, alebo online identifikátor, alebo na základe jednej alebo viacerých charakteristík alebo znakov, ktoré tvoria jej fyzickú identitu, fyziologickú identitu, genetickú identitu, psychickú identitu, mentálnu identitu, ekonomickú identitu, kultúrnu identitu alebo sociálnu identitu.",
    privacy_policy_I_1_2_bold: "Súhlasom dotknutej osoby",
    privacy_policy_I_1_2_regular: "je akýkoľvek vážny a slobodne daný, konkrétny, informovaný a jednoznačný prejav vôle dotknutej osoby vo forme vyhlásenia alebo jednoznačného potvrdzujúceho úkonu, ktorým dotknutá osoba vyjadruje súhlas so spracúvaním svojich osobných údajov.",
    privacy_policy_I_1_3_bold: "Spracúvaním osobných údajov",
    privacy_policy_I_1_3_regular: "je spracovateľská operácia alebo súbor spracovateľských operácií s osobnými údajmi alebo so súbormi osobných údajov, najmä získavanie, zaznamenávanie, usporadúvanie, štruktúrovanie, uchovávanie, zmena, vyhľadávanie, prehliadanie, využívanie, poskytovanie prenosom, šírením alebo iným spôsobom, preskupovanie alebo kombinovanie, obmedzenie, vymazanie, bez ohľadu na to, či sa vykonáva automatizovanými prostriedkami alebo neautomatizovanými prostriedkami.",
    privacy_policy_I_1_4_bold: "Profilovaním",
    privacy_policy_I_1_4_regular: "je akákoľvek forma automatizovaného spracúvania osobných údajov spočívajúceho v použití osobných údajov na vyhodnotenie určitých osobných znakov alebo charakteristík týkajúcich sa fyzickej osoby, najmä na analýzu alebo predvídanie znakov alebo charakteristík dotknutej osoby súvisiacich s jej výkonnosťou v práci, majetkovými pomermi, zdravím, osobnými preferenciami, záujmami, spoľahlivosťou, správaním, polohou alebo pohybom.",
    privacy_policy_I_1_5_bold: "Dotknutou osobou",
    privacy_policy_I_1_5_regular: "je každá fyzická osoba, ktorej osobné údaje sa spracúvajú.",
    privacy_policy_II: "Spracovanie osobných údajov a zdieľanie osobných údajov",
    privacy_policy_II_1: "Prevádzkovateľ spracúva osobné údaje od fyzických osôb, ktoré sa zaregistrujú a používajú po registrácií webovú stránku Floflo slúžiacu na vytvorenie predplatného na plienky (ďalej ako „webová stránka”) ako aj osobné údaje od fyzických osôb tzv. neregistrovaných užívateľov, ktorí využívajú dostupnú bezplatnú funkciu služieb „Testovacie plienky zdarma“, ktorá je poskytovaná v rozsahu vymedzenom Poskytovateľom.",
    privacy_policy_II_2: "Prevádzkovateľ spracúva nasledujúce osobné údaje Dotknutých osôb:",
    privacy_policy_II_2_1: "Meno a priezvisko",
    privacy_policy_II_2_2: "E-mailová adresa",
    privacy_policy_II_2_3: "Adresa",
    privacy_policy_II_2_4: "Telefónne číslo",
    privacy_policy_II_2_5: "Informácie o platobnej karte (najmä číslo karty, dátum expirácie karty a CVV kód)",
    privacy_policy_II_2_6: "Tieto osobné údaje Dotknutých osôb sú spracúvané za účelom registrácie Dotknutých osôb na webovej stránke, následného prihlasovania sa do svojho užívateľského konta na webovej stránke ako aj za účelom získavania služieb Poskytovateľa „floflo - plienky bez starostí (teda najmä predplatného na plienky). V tomto prípade je právnym základom na spracúvanie osobných údajov Dotknutých osôb plnenie zmluvy, ktorej zmluvnou stranou je Prevádzkovateľ a Dotknutá osoba v zmysle § 13 ods. 1 písm. b)  zákona o ochrane osobných údajov.",
    privacy_policy_II_2_7: "Prevádzkovateľ tiež spracúva osobné údaje Dotknutých osôb tzv. neregistrovaných užívateľov (okrem informácií o platobnej karte) za účelom využívania dostupných bezplatných funkcií služieb „Testovacie plienky zdarma“, ktoré sú poskytované v rozsahu vymedzenom Poskytovateľom. V tomto prípade je právnym základom na spracúvanie osobných údajov Dotknutých osôb výslovný súhlas Dotknutej osoby v zmysle § 13 ods. 1 písm. a) zákona o ochrane osobných údajov. V prípade, ak by Dotknutá osoba neposkytla Poskytovateľovi svoj výslovný súhlas na spracúvanie osobných údajov nebolo by možné využiť bezplatnú službu „Testovacie vzorka zdarma“.",
    privacy_policy_II_2_8: "Prevádzkovateľ taktiež spracúva osobné údaje Dotknutých osôb za účelom odoberania tzv. newsletterov, teda noviniek, informáciách o zľavách, aktualizáciách, nových produktoch, a to vo forme e-mailov alebo poštových zásielok. V tomto prípade je právnym základom na spracúvanie osobných údajov Dotknutých osôb výslovný súhlas Dotknutej osoby v zmysle § 13 ods. 1 písm. a)  zákona o ochrane osobných údajov. V prípade, ak Dotknutá osoba nebude mať záujem dostávať vyššie uvedené správy, má právo odvolať svoj súhlas priamo vo svojom užívateľskom konte, prípadne nájde hypertextový odkaz na zrušenie odberu predmetných správ v zaslaných e-mailoch.",
    privacy_policy_II_2_9: "Prevádzkovateľ spracúva osobné údaje Dotknutých osôb aj za účelom uvedenia hodnotenia na webovej stránke Prevádzkovateľa. V tomto prípade je právnym základom na spracúvanie osobných údajov Dotknutých osôb výslovný súhlas Dotknutej osoby v zmysle § 13 ods. 1 písm. a) zákona o ochrane osobných údajov.",
    privacy_policy_II_2_10: "Za účelom komunikácie s Prevádzkovateľom, napríklad pri poskytnutí pomoci pri používaní webovej stránky alebo objednania služieb, pri riešení akýchkoľvek problémov, Prevádzkovateľ spracúva osobné údaje Dotknutých osôb. V tomto prípade je právnym základom na spracúvanie osobných údajov Dotknutých osôb plnenie zmluvy, ktorej zmluvnou stranou je Prevádzkovateľ a Dotknutá osoba v zmysle § 13 ods. 1 písm. b)  zákona o ochrane osobných údajov.",
    privacy_policy_II_2_11: "Okrem vyššie uvedeného Prevádzkovateľ spracúva osobné údaje Dotknutých osôb v rámci reklamačného konania na základe reklamácie tovaru alebo služby poskytnutej Prevádzkovateľom. V tomto prípade je právnym základom na spracúvanie osobných údajov Dotknutých osôb osobitný predpis (plnenie zákonnej povinnosti Prevádzkovateľa najmä podľa zákona č. 250/2007 Z. z. o ochrane spotrebiteľa a o zmene zákona Slovenskej národnej rady č. 372/1990 Zb. o priestupkoch v znení neskorších predpisov a zákona č. 40/1964 Zb. Občiansky zákonník) v zmysle § 13 ods. 1 písm. c) zákona o ochrane osobných údajov.",
    privacy_policy_II_3: "Prevádzkovateľ spracúva osobné údaje Dotknutých osôb výlučne spôsobom, ktorý zodpovedá účelu, na ktorý boli zhromaždené a v súlade so zákonom o ochrane osobných údajov. Prevádzkovateľ spracúva osobné údaje na základe súhlasu Dotknutej osoby, na základe plnenia zmluvy, ktorej zmluvnou stranou je Prevádzkovateľ a Dotknutá osoba a na základe osobitného predpisu (tzn. v zmysle § 13 ods. 1 písm. a), b) a c) zákona o ochrane osobných údajov).",
    privacy_policy_II_4: "Väčšina osobných údajov, ktoré Dotknuté osoby poskytujú prostredníctvom webovej stránky Prevádzkovateľovi sú poskytované na dobrovoľnej báze, ktorá si vyžaduje aktívne a dobrovoľné konanie zo strany Dotknutých osôb.",
    privacy_policy_II_5: "Osobné údaje Dotknutých osôb môžu byť poskytnuté orgánom verejnej moci, resp. verejným inštitúciám, a to najmä v prípade:",
    privacy_policy_II_5_1: "plnenia zákonnej povinnosti Prevádzkovateľa v rozsahu podľa príslušných právnych predpisov",
    privacy_policy_II_5_2: "preukazovania/uplatňovania právnych nárokov Prevádzkovateľa",
    privacy_policy_II_5_3: "prevencie, odhaľovania, vyšetrovania a stíhania protiprávneho konania",
    privacy_policy_II_6: "Za účelom sprístupnenia webovej stránky a služieb má Prevádzkovateľ právo osobné údaje Dotknutých osôb poskytnúť (a Dotknutá osoba súhlasí s takýmto poskytnutím) v súlade so zásadou minimalizácie len obmedzenému okruhu osôb a to zamestnancom Prevádzkovateľa, subdodávateľom, úradníkom, poradcom, obchodným zástupcom, dodávateľom a Poskytovateľom služieb v rozsahu, v akom je to primerané a nevyhnutné na dosiahnutie účelu uvedeného v tomto vyhlásení, najmä nasledovným osobám:",
    privacy_policy_II_6_1: "Stripe Payments Europe, Limited The One Building, so sídlom 1 Grand Canal Street Lower Dublin 2, Co. Dublin, Ireland, VAT Number: IE 3206488L",
    privacy_policy_II_6_2: "Slovenská pošta, a.s., so sídlom Partizánska cesta č.9, 975 99 Banská Bystrica, IČO: 36 631 124, zapísaná v obchodnom registri Okresného súdu Banská Bystrica, oddiel: Sa, vložka č. 803/S",
    privacy_policy_II_6_3: "GLS General Logistics Systems Slovakia s.r.o., so sídlom Lieskovská cesta 13, 962 21 Lieskovec, IČO: 36624942, zapísaná v obchodnom registri Okresného súdu Banská Bystrica, Oddiel Sro, Vložka č. 9084/S",
    privacy_policy_II_6_4: "CELLTEX s.r.o., so sídlom Mokráň záhon 2, 821 04 Bratislava, IČO: 31327052, zapísaná v obchodnom registri Okresného súdu Bratislava I, oddiel: Sro, vložka č. 12679/B",
    privacy_policy_II_6_5: "Sendinblue, a simplified joint-stock company registered with the Paris Trade and Companies Register under number 498 019 289, so sídlom 7 Rue de Madrid, 75008 Paris, France",
    privacy_policy_III: "Vyhlásenie prevádzkovateľa a ochrana osobných údajov dotknutých osôb",
    privacy_policy_III_1: "Prevádzkovateľovi záleží na ochrane osobných údajov Dotknutých osôb a dodržiava pravidlá ochrany osobných údajov v súlade s platnými právnymi predpismi o ochrane osobných údajov. Prevádzkovateľ prostredníctvom tohto Vyhlásenia o ochrane osobných údajov poskytuje Dotknutým osobám komplexné informácie podľa zákona o ochrane osobných údajov, ako aj ďalšie dôležité informácie a vysvetlenia za účelom vytvorenia transparentnosti, a to aj s ohľadom na článok 13 a 14 GDPR.",
    privacy_policy_III_2: "Prevádzkovateľ vyhlasuje, že prijal primerané technické, organizačné a bezpečnostné opatrenia za účelom zabezpečenia bezpečnosti spracúvania osobných údajov Dotknutých osôb. Hlavne prijal také opatrenia aby boli osobné údaje Dotknutých osôb, v čo najväčšej miere chránené pred neželaným prístupom. Ochrana osobných údajov bude aktualizovaná primerane aktuálnemu stavu techniky.",
    privacy_policy_III_3: "Prevádzkovateľ garantuje Dotknutým osobám, že nepredá a ani komerčne nevyužije osobné údaje, ktoré získal od Dotknutých osôb v rámci obchodnej spolupráce s akoukoľvek treťou osobou a nezverejní žiadne osobné údaje získané od Dotknutých osôb bez toho, aby Dotknuté osoby vyjadrili svoj individuálny písomný súhlas.",
    privacy_policy_III_4: "Prevádzkovateľ bude spracúvať osobné údaje Dotknutých osôb s použitím rozhodnutia založeného na automatizovaných, čiastočne automatizovaných prostriedkoch a iných ako automatizovaných prostriedkoch spracúvania osobných údajov a tzv. profilovanie, a to v závislosti od účelu spracúvania osobných údajov. V tomto prípade má Dotknutá osoba právo na to, aby sa na ňu nevzťahovalo rozhodnutie, ktoré je založené výlučne na automatizovanom spracúvaní osobných údajov vrátane profilovania. Uvedené neplatí, v prípade ak Dotknutá osoba udelila Prevádzkovateľovi výslovný súhlas vo vzťahu k spracúvaniu jej osobných údajov a uplatňovania rozhodnutia založeného na automatizovaných prostriedkoch spracúvania osobných údajov a tzv. profilovania.  Prevádzkovateľ poskytne Dotknutým osobám informácie o použitom postupe a význame a predpokladaných dôsledkoch takého spracúvania osobných údajov.",
    privacy_policy_IV: "Doba uchovávania osobných údajov dotknutých osôb",
    privacy_policy_IV_1: "Prevádzkovateľ uchováva osobné údaje Dotknutých osôb po dobu potrebnú na dosiahnutie účelu, na ktorý boli osobné údaje Dotknutých osôb poskytnuté Prevádzkovateľovi. Akonáhle si Dotknutá osoba zruší predplatné, resp. užívateľské konto na webovej stránke, prípadne Prevádzkovateľ zruší užívateľské konto Dotknutej osoby, budú osobné údaje Dotknutej osoby do jedného mesiaca od zrušenia užívateľského konta vymazané. Údaje, ktoré bude v zmysle osobitnej zákonnej povinnosti potrebné uchovávať po dlhšiu dobu, budú uchovávané len na nevyhnutnú dobu v zmysle príslušného osobitného zákona.",
    privacy_policy_IV_2: "Osobné údaje Dotknutých osôb, ktoré boli získané na základe súhlasu Dotknutej osoby bude Prevádzkovateľ uchovávať do dňa odvolania súhlasu Dotknutej osoby so spracúvaním jej osobných údajov alebo do dňa zrušenia registrácie Dotknutej osoby na webovej stránke.",
    privacy_policy_V: "Cookies",
    privacy_policy_V_1: "Prevádzkovateľ bude na webovej stránke využívať súbory typu cookies, pričom používanie cookies počas návštevy webovej stránky je na rozhodnutí Dotknutých osôb.",
    privacy_policy_V_2: "Súbor Cookie je súbor obsahujúci identifikátor (reťazec písmen a čísel), ktorý je odoslaný webovým serverom do webového prehliadača a je uložený prehliadačom na mobilnom zariadení alebo počítači Dotknutej osoby za účelom jej identifikácie (zväčša na základe anonymných identifikátorov). Identifikátor sa potom odosiela späť na server vždy, keď prehliadač požaduje od servera zobrazenie stránky za účelom identifikácie návštevy Dotknutej osoby na určitej stránke, zoznamu reklám, na ktoré Dotknutá osoba reagovala, typ prehliadača, ktorý používa a tiež za účelom zapamätania si údajov, ktoré Dotknutá osoba zadala na webovej stránke.",
    privacy_policy_V_3: "Cookies Prevádzkovateľ používa za účelom personalizácie obsahu a reklám, ktoré sa Dotknutej osobe zobrazujú, umožnenia využívania funkcionalít sociálnych médií a za účelom analýzy návštevnosti webovej stránky. Taktiež Cookies Prevádzkovateľ používa, aby sa vedel prispôsobiť potrebám a požiadavkám Dotknutých osôb, aby vedel Dotknuté osoby odlíšiť od iných používateľov, a aby zabránil zobrazovaniu reklám v internetovom prehliadači Dotknutej osoby, ktoré nie sú pre ňu zaujímavé.",
    privacy_policy_V_4: "Prevádzkovateľ používa „trvalé“ alebo „dočasné“ cookies. Trvalé cookies budú uložené v internetovom prehliadači Dotknutej osoby až do dňa ich určenej exspirácie, ak ich pred týmto dňom Dotknutá osoba neodstráni. Dočasné cookies exspirujú v momente, kedy Dotknutá osoba zatvorí internetový prehliadač.",
    privacy_policy_V_5: "Prevádzkovateľ používa tiež cookies tretích strán, ktoré sú nastavené inou stránkou ako webovou stránkou Prevádzkovateľa. Cieľom týchto cookies tretích strán je zväčša zbieranie určitých informácií za účelom výskumu v oblasti správania, demografie a v neposlednom rade aj za účelom vykonávania cieleného marketingu.",
    privacy_policy_V_6: "Cookies väčšinou neobsahujú informácie, na základe ktorých by bolo možné identifikovať Dotknutú osobu, avšak osobné údaje Dotknutých osôb, ktoré sú uchovávané Prevádzkovateľom môžu byť prepojené s údajmi, ktoré sú získavané a ukladané z cookies.",
    privacy_policy_V_7: "Okrem vyššie uvedených cookies Prevádzkovateľ používa aj tzv. technické cookies, funkčné cookies a analytické cookies.",
    privacy_policy_V_8: "Technické cookies slúžia na to, aby webová stránka fungovala správne a vedela poskytovať služby predplatného detských plienok Dotknutým osobám, napr. aby si Dotknuté osoby mohli vytvoriť svoje užívateľské konto a prihlásiť sa do neho a aby mohli využívať služby predplatného detských plienok.",
    privacy_policy_V_9: "Funkčné cookies slúžia na to, aby sa Dotknuté osoby nemuseli opakovane prihlasovať a aby si nemuseli opakovane nastavovať svoje preferencie. V takom prípade je heslo Dotknutej osoby vždy zašifrované. Používanie týchto cookies nie je nevyhnutné, avšak zjednodušuje návštevu webovej stránky.",
    privacy_policy_V_10: "Analytické cookies slúžia ako pomoc pri vylepšovaní webovej stránky. Analytické cookies na webovej stránke Prevádzkovateľa sú zhromažďované skriptom spoločnosti Google Inc., ktorá následne tieto dáta anonymizuje. Po anonymizácii už nejde o osobné údaje, nakoľko anonymizované cookies nemožno priradiť konkrétnemu používateľovi, resp. konkrétnej osobe. Prevádzkovateľ pracuje s cookies v anonymizovanej podobe.",
    privacy_policy_VI: "Cezhraničný prenos osobných údajov",
    privacy_policy_VI_1: "Prevádzkovateľ nebude vykonávať žiadny cezhraničný prenos osobných údajov Dotknutých osôb do tretej krajiny, ktorá nezaručuje primeranú úroveň ochrany osobných údajov.",
    privacy_policy_VI_2: "Osobné údaje Dotknutých osôb, ktoré budú spracúvané nebudú prenesené z územia členských štátov Európskej únie do tretích krajín.",
    privacy_policy_VII: "Práva dotknutých osôb",
    privacy_policy_VII_1: "Právo na informácie - Dotknutá osoba má právo od Prevádzkovateľa kedykoľvek požadovať informácie o poskytnutých osobných údajoch, ktoré Prevádzkovateľ spracúva, o ich pôvode, o účele spracúvania osobných údajov, kategórii spracúvania osobných údajov, identifikácii príjemcu alebo o kategórii príjemcu, ktorému boli osobné údaje poskytnuté a dobe uchovávania osobných údajov, ak to nie je možné, informácie o kritériách jej určenia.",
    privacy_policy_VII_2: "Právo na prístup k osobným údajom - Dotknutá osoba má právo na prístup k jej osobným údajom a právo na poskytnutie jej osobných údajov. V tomto prípade, ak by vyžadovala Dotknutá osoba prístup k osobným údajom, Prevádzkovateľ je bezodkladne povinný jej takýto prístup umožniť. Prípadne si svoje osobné údaje vie Dotknutá osoba nájsť priamo v Aplikácii vo svojom konte. Za opakované poskytnutie osobných údajov, o ktoré Dotknutá osoba požiada, môže Prevádzkovateľ účtovať primeraný poplatok zodpovedajúci administratívnym nákladom. Prevádzkovateľ je povinný poskytnúť osobné údaje Dotknutej osobe spôsobom podľa jej požiadavky.",
    privacy_policy_VII_3: "Právo na odvolanie súhlasu so spracovaním osobných údajov - V prípade, ak Dotknutá osoba udelila súhlas so spracovaním osobných údajov je oprávnená ho kedykoľvek odvolať, a to bez uvedenia dôvodov a s okamžitou účinnosťou. V takom prípade Dotknutá osoba môže odvolanie súhlasu zaslať na e-mailovú adresu Prevádzkovateľa: ahoj@floflo.sk. V prípade odvolania súhlasu Dotknutej osoby, Prevádzkovateľ ukončí spracúvanie osobných údajov Dotknutej osoby a osobné údaje budú vymazané, ak nebude existovať iný právny základ pre ich ďalšie spracúvanie. Odvolanie súhlasu so spracovaním osobných údajov nebude mať vplyv na zákonnosť spracúvania vychádzajúceho zo súhlasu pred jeho odvolaním.",
    privacy_policy_VII_4: "Právo na opravu osobných údajov - Dotknutá osoba má právo na opravu nesprávnych osobných údajov, ktoré sa jej týkajú. Dotknutá osoba má právo aj na doplnenie svojich neúplných osobných údajov. V prípade, ak sú uchovávané údaje nesprávne alebo neúplné, môže ich Dotknutá osoba kedykoľvek opraviť vo svojom konte, prípadne túto skutočnosť oznámi priamo Prevádzkovateľovi na e-mailovú adresu Prevádzkovateľa uvedenú na konci tohto vyhlásenia a Prevádzkovateľ osobné údaje Dotknutej osoby bezodkladne opraví, prípadne ich doplní.",
    privacy_policy_VII_5: "Právo na výmaz osobných údajov - Dotknutá osoba má právo na výmaz osobných údajov, ktoré Prevádzkovateľ spracúva. Prevádzkovateľ je povinný bez zbytočného odkladu vymazať osobné údaje Dotknutej osoby, ak Dotknutá osoba uplatnila právo na výmaz, ak:",
    privacy_policy_VII_5_1: "osobné údaje už nie sú potrebné na účel, na ktorý sa získali alebo inak spracúvali,",
    privacy_policy_VII_5_2: "Dotknutá osoba odvolá svoj súhlas, na základe ktorého sa spracúvanie osobných údajov vykonáva, a neexistuje iný právny základ pre spracúvanie osobných údajov,",
    privacy_policy_VII_5_3: "Dotknutá osoba namieta spracúvanie osobných údajov podľa § 27 ods. 1 a 2 zákona o ochrane osobných údajov,",
    privacy_policy_VII_5_4: "osobné údaje Dotknutej osoby sa spracúvajú nezákonne.",
    privacy_policy_VII_5_5: "Prevádzkovateľ bez zbytočného odkladu, najneskôr do jedného mesiaca po uplatnení tohto práva vymaže osobné údaje, ktoré sa týkajú Dotknutej osoby. Vymazanie osobných údajov Dotknutej osoby sa uskutoční na základe žiadosti Dotknutej osoby, ktorú Dotknutá osoba zašle na e-mailovú adresu Prevádzkovateľa uvedenú na konci tohto vyhlásenia. Ak výmaz osobných údajov Dotknutej osoby odporuje zákonným povinnostiam na uchovanie údajov, osobné údaje Dotknutej osoby nebudú vymazané. Právo na výmaz osobných údajov sa neuplatní v prípadoch uvedených v § 23 ods. 4 zákona o ochrane osobných údajov.",
    privacy_policy_VII_6: "Právo na obmedzenie spracúvania osobných údajov - Dotknutá osoba má právo na obmedzenie spracúvania osobných údajov Prevádzkovateľom, ak:",
    privacy_policy_VII_6_1: "Dotknutá osoba namieta správnosť osobných údajov, a to počas obdobia umožňujúceho Prevádzkovateľovi overiť správnosť osobných údajov,",
    privacy_policy_VII_6_2: "spracúvanie osobných údajov je nezákonné a Dotknutá osoba namieta vymazanie osobných údajov a žiada namiesto toho obmedzenie ich použitia,",
    privacy_policy_VII_6_3: "Prevádzkovateľ už nepotrebuje osobné údaje na účel spracúvania osobných údajov, ale potrebuje ich Dotknutá osoba na uplatnenie právneho nároku, alebo",
    privacy_policy_VII_6_4: "Dotknutá osoba namieta spracúvanie osobných údajov podľa § 27 ods. 1 zákona o ochrane osobných údajov, a to až do overenia, či oprávnené dôvody na strane Prevádzkovateľa prevažujú nad oprávnenými dôvodmi Dotknutej osoby.",
    privacy_policy_VII_7: "Právo na prenosnosť osobných údajov - V prípade, ak Dotknutá osoba požaduje od Prevádzkovateľa svoje osobné údaje, ktoré Prevádzkovateľ spracúva, Prevádzkovateľ tieto osobné údaje Dotknutej osobe prípadne inej osobe, ktorá je prevádzkovateľom vydá alebo prenesie, a to v štruktúrovanom a bežne používanom čitateľnom formáte. Uvedené platí len v prípade, ak je to technicky možné.",
    privacy_policy_VII_8: "Právo namietať spracúvanie osobných údajov - Dotknutá osoba má právo namietať spracúvanie osobných údajov na účel priameho marketingu vrátane profilovania. Dotknutá osoba má právo namietať spracúvanie osobných údajov, ktoré sa jej týkajú, z dôvodov týkajúcich sa jej konkrétnej situácie. V prípade takejto námietky Prevádzkovateľ nebude ďalej spracúvať osobné údaje Dotknutej osoby, pokiaľ nebudú dané nevyhnutné oprávnené dôvody na ich ďalšie spracúvanie alebo dôvody na preukazovanie/uplatňovanie právnych nárokov Prevádzkovateľa.",
    privacy_policy_VII_9: "Právo nebyť subjektom automatizovaného individuálneho rozhodovania, vrátane profilovania - Dotknutá osoba má právo, aby sa na ňu nevzťahovalo rozhodnutie, ktoré je založené výlučne na automatizovanom spracúvaní osobných údajov vrátane tzv. profilovania a ktoré má právne účinky, ktoré sa jej týkajú alebo ju obdobne významne ovplyvňujú. Toto právo dotknutej osoby sa neuplatní v prípade, ak je rozhodnutie:",
    privacy_policy_VII_9_1: "nevyhnutné na uzavretie alebo plnenie zmluvy medzi Dotknutou osobou a Prevádzkovateľom,",
    privacy_policy_VII_9_2: "vykonané na základe osobitného predpisu alebo medzinárodnej zmluvy, ktorou je SR viazaná, a v ktorých sú zároveň ustanovené aj vhodné opatrenia zaručujúce ochranu práv a oprávnených záujmov Dotknutej osoby,",
    privacy_policy_VII_9_3: "je založené na výslovnom súhlase Dotknutej osoby.",
    privacy_policy_VII_10: "Právo na podanie sťažnosti - V prípade, ak chce Dotknutá osoba podať sťažnosť, môže tak urobiť, zaslaním e-mailu na email adresu: ahoj@floflo.sk prípadne zaslaním sťažnosti na adresu: Idanská 775/27 040 11 Košice - mestská časť Západ.",
    privacy_policy_VII_11: "Právo podať návrh na začatie konania - Dotknutá osoba má pri podozrení o neoprávnenom spracúvaní jej osobných údajov Prevádzkovateľom právo podať návrh na začatie konania o ochrane osobných údajov Úradu na ochranu osobných údajov Slovenskej republiky, so sídlom Hraničná 4, 820 07 Bratislava, a to v zmysle § 100 zákona o ochrane osobných údajov.",
    privacy_policy_VIII: "Otázky ohľadom ochrany osobných údajov dotknutej osoby",
    privacy_policy_VIII_1: "V prípade, ak má Dotknutá osoba otázky týkajúce sa ochrany jej osobných údajov môže sa obrátiť na Prevádzkovateľa:",
    privacy_policy_VIII_2: "e-mailová adresa:",
    privacy_policy_VIII_3: "telefonický kontakt:",
    privacy_policy_VIII_4: "Slovenská verzia má vždy prednosť v prípade rozporu medzi slovenskou verziou a jej anglickým prekladom.",
    privacy_policy_VIII_5: "Toto Vyhlásenie o ochrane osobných údajov je platné a účinné od 1. Mája 2021.",
};
