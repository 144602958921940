import * as React from 'react';
import {useEffect} from 'react';
import {Box, Grid, LinearProgress, List, ListItem, Paper, Typography} from "@mui/material";
import {useIntl} from "react-intl";
import {RegularButton} from "../common/RegularButton";
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import IconButton from "@mui/material/IconButton";
import {useSelector} from "react-redux";
import {Search} from "../common/Search";
import {getProducts} from "../../reducers/products";
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

const styles = {
    captionWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    caption: {
        flex: '1 1 0',
        padding: '1rem',
        fontSize: '26px'
    },
    paper: {
        boxShadow: 'rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px',
        borderRadius: '1rem',
        margin: '1.5rem 0',
        overflow: 'hidden'
    },
    sectionWrapper: {
        padding: '1rem'
    },
    list: {
        margin: 0,
        padding: 0
    },
    listItem: beforeLast => [
        {
            padding: '12px 24px',
            '&:hover': {
                backgroundColor: '#3741510a'
            },
        },
        beforeLast && {
            borderBottom: '1px solid rgb(230, 232, 240)'
        }
    ],
    tableCellText: {
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: 1.57,
        color: '#374151'
    },
    tableCellSubtext: {
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: 1.57,
        color: '#65748b'
    },
    buttonWrapper: {
        textAlign: 'end'
    },
    tableHeader: {
        backgroundColor: '#f3f4f6',
        padding: '24px'
    },
    tableHeaderText: {
        textTransform: 'uppercase',
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: 1
    },
    productImage: imageName => [{
        height: '80px',
        width: '80px',
        backgroundImage: `url(/product/${imageName})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        borderRadius: '8px'
    }],
    progressBar: {
        width: '65%',
        bgcolor: 'rgba(81,241,152,0.82)',
        borderRadius: '0.5rem',
        height: '8px',
        '& .MuiLinearProgress-bar': {
            height: '8px',
            bgcolor: 'green.main',
            transition: 'width 0.6s ease 0s !important',
        },
    },
    statusText: status => [
        {
            display: 'flex',
            justifyContent: 'center',
            textTransform: 'uppercase',
            fontSize: '12px',
            fontWeight: 600,
            lineHeight: 2,
            color: 'white',
            borderRadius: '16px',
            padding: '2px 8px'
        },
        status === 'published' && {
            bgcolor: '#14b8a6'
        },
        status === 'draft' && {
            bgcolor: '#2196f3'
        },
    ],
};

export const Products = () => {

    const intl = useIntl();
    const products = useSelector(getProducts);
    const [searchString, setSearchString] = React.useState('');
    const [searchFilteredProducts, setSearchFilteredProducts] = React.useState(products);

    useEffect(() => {
        let filteredItems = products;
        if (searchString !== '') {
            const sanitizedSearchString = searchString.toLowerCase();
            filteredItems = products.filter(product =>
                product.name.toLowerCase().includes(sanitizedSearchString) ||
                product.category.toLowerCase().includes(sanitizedSearchString) ||
                product.sku.toLowerCase().includes(sanitizedSearchString)
            );
        }
        setSearchFilteredProducts(filteredItems);
    }, [searchString]);

    const header = <ListItem sx={styles.tableHeader}>
        <Grid container
              spacing={2}
              justifyContent="center"
              alignItems="center">
            <Grid item>
                <div style={{width: '40px'}}/>
            </Grid>
            <Grid item xs={3}>
                <Typography sx={styles.tableHeaderText}>
                    {intl.formatMessage({id: 'name'})}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography sx={styles.tableHeaderText}>
                    {intl.formatMessage({id: 'stock'})}
                </Typography>
            </Grid>
            <Grid item xs={1}>
                <Typography sx={styles.tableHeaderText}>
                    {intl.formatMessage({id: 'price'})}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography sx={styles.tableHeaderText}>
                    {intl.formatMessage({id: 'sku'})}
                </Typography>
            </Grid>
            <Grid item xs={1}>
                <Typography sx={styles.tableHeaderText}>
                    {intl.formatMessage({id: 'status'})}
                </Typography>
            </Grid>
            <Grid item xs/>
            <Grid item xs={1}>
                <Typography sx={styles.tableHeaderText} style={{textAlign: 'end'}}>
                    {intl.formatMessage({id: 'actions'})}
                </Typography>
            </Grid>
        </Grid>
    </ListItem>;

    return <Box>
        <Box sx={styles.captionWrapper}>
            <Typography sx={styles.caption}>
                {intl.formatMessage({id: 'products'})}
            </Typography>
            <RegularButton startIcon={<AddRoundedIcon/>} text={'add'}/>
        </Box>
        <Box>
            <RegularButton startIcon={<FileUploadRoundedIcon/>} text={'import'} variant={'text'}
                           customStyles={{marginRight: '10px'}}/>
            <RegularButton startIcon={<DownloadRoundedIcon/>} text={'download'} variant={'text'}/>
        </Box>
        <Paper sx={styles.paper}>
            <Box sx={styles.sectionWrapper}>
                <Search value={searchString} setValue={setSearchString} width={'50%'}/>
            </Box>
            <List sx={styles.list}>
                {header}
                {searchFilteredProducts.map((product, index) => <ListItem
                    sx={styles.listItem(index !== product.length - 1)}>
                    <Grid container
                          spacing={2}
                          justifyContent="center"
                          alignItems="center">
                        <Grid item>
                            <Box sx={styles.buttonWrapper}>
                                <IconButton color="primary" component="label">
                                    <ArrowForwardIosRoundedIcon/>
                                </IconButton>
                            </Box>
                        </Grid>
                        <Grid item xs={3} container spacing={1} alignItems={'center'}>
                            <Grid item>
                                <Box sx={styles.productImage(product.imageName)}/>
                            </Grid>
                            <Grid item xs>
                                <Typography sx={styles.tableCellText}>
                                    {product.name}
                                </Typography>
                                <Typography sx={styles.tableCellSubtext}>
                                    {intl.formatMessage({id: product.category})}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={3} container spacing={1} alignItems={'center'}>
                            <Grid item xs={12}>
                                <LinearProgress sx={styles.progressBar} variant="determinate" value={product.stock}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography sx={styles.tableCellText}>
                                    {intl.formatMessage({id: 'in_stock'}, {stock: product.stock})}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography sx={{...styles.tableCellText, ...styles.greenText}}>
                                €{product.price}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography sx={styles.tableCellText}>
                                {product.sku}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography sx={styles.statusText(product.status)}>
                                {intl.formatMessage({id: product.status})}
                            </Typography>
                        </Grid>
                        <Grid item xs/>
                        <Grid item xs={1}>
                            <Box sx={styles.buttonWrapper}>
                                <IconButton color="primary" component="label">
                                    <MoreHorizRoundedIcon/>
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>
                </ListItem>)}
            </List>
        </Paper>
    </Box>;
};