import * as React from 'react';
import {Avatar, Box, Grid, Paper, Typography} from "@mui/material";
import Chart from "react-apexcharts";
import {useIntl} from "react-intl";
import {CommonDivider} from "../common/CommonDivider";
import {mainGraphOptions, mainGraphSeries, minorGraphOptions, minorGraphSeries} from "./graphData";
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';

const styles = {
    caption: {
        padding: '1rem',
        fontSize: '26px'
    },
    paper: {
        boxShadow: 'rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px',
        borderRadius: '1rem',
        overflow: 'hidden'
    },
    sectionWrapper: {
        padding: '1.5rem'
    },
    graphWrapper: {
        padding: '1.5rem',
        maxWidth: 'calc(100% - 24px)'
    },
    mainGraphName: {
        fontSize: '18px',
        fontWeight: 'bold'
    },
    minorCardDesc: {
        fontSize: '0.75rem',
        fontWeight: 400,
        lineHeight: 1.66,
        color: 'textColorTertiary.main'
    },
    minorCardLabel: {
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: 1.57,
        color: '#65748b'
    },
    minorCardValue: {
        fontSize: '1.45rem',
        fontWeight: 600,
        lineHeight: 1.375,
        marginTop: '8px'
    },
    avatar: up => [
        {
            bgcolor: '#14b8a614',
            color: '#14b8a6',
        },
        !up && {
            bgcolor: '#d1434314',
            color: '#d14343',
            transform: 'rotate(-180deg)',
        }
    ],
    legend: color => [
        {
            display: 'inline-block',
            borderRadius: '50%',
            height: '16px',
            marginRight: '8px',
            width: '16px'
        },
        color === 'green' && {
            border: '3px solid #10b981',
        },
        color === 'yellow' && {
            border: '3px solid #ff9800',
        },
        color === 'purple' && {
            border: '3px solid #5048e5',
        },
    ]
};

export const Dashboard = () => {

    const intl = useIntl();

    const minorCardContent = [
        {
            label: intl.formatMessage({id: 'impressions_label'}),
            mainValue: '1.9M',
            up: true,
            desc: intl.formatMessage({id: 'impressions_desc'}),
        },
        {
            label: intl.formatMessage({id: 'spent_label'}),
            mainValue: '$41.2K',
            up: false,
            desc: intl.formatMessage({id: 'spent_desc'}),
        },
        {
            label: intl.formatMessage({id: 'engagements_label'}),
            mainValue: '36,6K',
            up: true,
            desc: intl.formatMessage({id: 'engagements_desc'}),
        },
        {
            label: intl.formatMessage({id: 'conversions_label'}),
            mainValue: '131,3K',
            up: false,
            desc: intl.formatMessage({id: 'conversions_desc'}),
        }
    ];

    return <Box>
        <Box>
            <Typography sx={styles.caption}>
                {intl.formatMessage({id: 'dashboard'})}
            </Typography>
        </Box>
        <Grid container
              spacing={3}
              justifyContent="start"
              alignItems="center">
            {minorCardContent.map(cc => <Grid item xs={3}>
                <Paper sx={styles.paper}>
                    <Box sx={styles.sectionWrapper}>
                        <Grid container
                              spacing={2}
                              justifyContent="start"
                              alignItems="center">
                            <Grid item xs>
                                <Typography sx={styles.minorCardLabel}>
                                    {cc.label}
                                </Typography>
                                <Typography sx={styles.minorCardValue}>
                                    {cc.mainValue}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Chart
                                    options={minorGraphOptions}
                                    series={minorGraphSeries}/>
                            </Grid>
                        </Grid>
                    </Box>
                    <CommonDivider/>
                    <Box sx={styles.sectionWrapper}>
                        <Grid container
                              spacing={2}
                              justifyContent="start"
                              alignItems="center">
                            <Grid item>
                                <Avatar sx={styles.avatar(cc.up)}>
                                    <KeyboardArrowUpRoundedIcon/>
                                </Avatar>
                            </Grid>
                            <Grid item xs>
                                <Typography sx={styles.minorCardDesc}>
                                    {cc.desc}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Grid>)}
            <Grid item xs={12}>
                <Paper sx={styles.paper}>
                    <Box sx={styles.sectionWrapper}>
                        <Typography sx={styles.mainGraphName}>
                            {intl.formatMessage({id: 'traffic_sources'})}
                        </Typography>
                    </Box>
                    <CommonDivider/>
                    <Box sx={styles.sectionWrapper}>
                        <Grid container
                              spacing={2}
                              justifyContent="start"
                              alignItems="center">
                            <Grid item>
                                <Box sx={styles.legend('purple')}/>
                                <Typography component={'span'}>
                                    {"Facebook"}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Box sx={styles.legend('green')}/>
                                <Typography component={'span'}>
                                    {"Google"}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Box sx={styles.legend('yellow')}/>
                                <Typography component={'span'}>
                                    {"Company blog"}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={styles.graphWrapper}>
                        <Chart
                            options={mainGraphOptions}
                            series={mainGraphSeries}/>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    </Box>;
};