import * as React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import {Box, StepContent, Typography} from "@mui/material";

const styles = {
    iconRoot: active => [
        {
            color: 'white',
            bgcolor: 'textColorTertiary.main',
            transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            display: 'flex',
            width: 40,
            height: 40,
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '8px'
        },
        active && {
            bgcolor: 'green.main',
        }
    ],
    completedIcon: {
        color: 'white',
    },
    stepContent: {
        marginLeft: '20px'
    },
    stepLabel: {
        marginLeft: '8px',
        fontSize: '0.75rem',
        fontWeight: 600,
        letterSpacing: '0.5px',
        lineHeight: 2.5,
        textTransform: 'uppercase',
    },
    stepper: {
        '& div.MuiStepConnector-root': {
            marginLeft: '20px'
        }
    }
}

const CustomStepIcon = props => {
    const {active, completed, icon} = props;

    const icons = {
        1: "1",
        2: "2",
        3: "3"
    };

    return <Box sx={styles.iconRoot(active || completed)}>
        {completed ? (
            <Check sx={styles.completedIcon}/>
        ) : (
            icons[String(icon)]
        )}
    </Box>;
};

export const CommonVerticalStepper = props => {

    const {steps, activeStep} = props;

    return <Stepper activeStep={activeStep} orientation={'vertical'} sx={styles.stepper}>
        {steps.map(step => (
            <Step key={step.label}>
                <StepLabel StepIconComponent={CustomStepIcon}>
                    <Typography sx={styles.stepLabel}>
                        {step.label}
                    </Typography>
                </StepLabel>
                <StepContent sx={styles.stepContent}>
                    {step.content}
                </StepContent>
            </Step>
        ))}
    </Stepper>;
};
