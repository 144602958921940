import {createSlice} from '@reduxjs/toolkit'
import {EDGE_LENGTH, NODE_HEIGHT, NODE_WIDTH} from "../components/automation/common";

export const workflowDefaults = {
    name: '',
    active: false,
    idCounter: 1,
    builderWidth: 0,
    savedState: [],
    nodes: [],
    edges: []
};

export const newWorkflowSlice = createSlice({
    name: 'newWorkflow',
    initialState: {
        data: workflowDefaults
    },
    reducers: {
        initBuilderState: (state, action) => {
            const {builderWidth, onLeafNodeClick} = action.payload;
            state.data.builderWidth = builderWidth;
            state.data.nodes = positionNodes(state.data.nodes, builderWidth);
            state.data.nodes = state.data.nodes.map(node => {
                if(node.type === 'addMoreNode') {
                    return {
                        ...node,
                        data: {
                            onClick: onLeafNodeClick(node.parentId)
                        }
                    }
                }

                return node;
            });
        },
        initNodes: (state, action) => {
            const {node, nodeConfig} = action.payload;

            const startNode = {
                id: 'start-node-1',
                type: 'workflowStartNode',
                parentId: null,
                draggable: false,
                position: {x: 0, y: 0},
                data: {
                    name: node.name,
                    description: node.description,
                    workflowActionType: node.workflowActionType,
                    ...nodeConfig
                },
            };

            const addMoreNode = {
                id: 'add-more-node-1',
                type: 'addMoreNode',
                parentId: 'start-node-1',
                draggable: false,
                position: {x: 0, y: 0},
                data: {},
            };

            const edge = {
                id: 'start-node-1-add-more-node-1',
                type: 'straight',
                source: 'start-node-1',
                target: 'add-more-node-1',
                animated: true,
            };

            state.data.nodes = [startNode, addMoreNode];
            state.data.edges = [edge];
        },
        setWorkflowName: (state, action) => {
            state.data.name = action.payload;
        },
        setWorkflowIdCounter: (state, action) => {
            state.data.idCounter = action.payload;
        },
        setWorkflowActive: (state, action) => {
            state.data.active = action.payload;
        },
        setNodes: (state, action) => {
            state.data.nodes = action.payload;
        },
        setEdges: (state, action) => {
            state.data.edges = action.payload;
        },
        addNewNode: (state, action) => {
            const {parentId, node, nodeAction, onLeafNodeClick} = action.payload;
            const nodes = [...state.data.nodes];
            const counter = state.data.idCounter + 1;
            const newNodeId = `workflow-node-${counter}`;
            const currentTreeNodeIndex = nodes.findIndex(n => n.parentId === parentId);
            const currentTreeNode = {
                ...nodes[currentTreeNodeIndex],
                parentId: newNodeId,
                data: {
                    onClick: onLeafNodeClick(newNodeId)
                },
            };

            const newNode = {
                id: newNodeId,
                type: 'workflowStepNode',
                draggable: false,
                position: {x: 0, y: 0},
                data: {
                    name: node.name,
                    description: node.description,
                    workflowActionType: node.workflowActionType,
                    logoPath: node.logoPath,
                    nodeAction
                },
                parentId
            };

            nodes.splice(currentTreeNodeIndex, 1, newNode, currentTreeNode);
            const repositionedNodes = positionNodes(nodes, state.data.builderWidth);
            state.data.nodes = repositionedNodes;
            state.data.edges = createEdges(repositionedNodes);
            state.data.idCounter = counter;
        },
        reset: (state, action) => {
            state.data = {
                name: '',
                active: false,
                idCounter: 1,
                builderWidth: state.data.builderWidth,
                savedState: [],
                nodes: [],
                edges: []
            };
        },
    }
});

export const positionNodes = (nodes, builderWidth) => {
    return nodes.map((n, i) => ({
        ...n,
        position: {
            x: (builderWidth - NODE_WIDTH) / 2,
            y: NODE_HEIGHT + (i * (NODE_HEIGHT + EDGE_LENGTH))
        }
    }));
}

export const createEdges = nodes => {
    const edges = [];
    for (let i = 0; i < nodes.length - 1; i++) {
        const current = nodes[i];
        const next = nodes[i + 1];

        edges.push({
            id: `${current.id}-${next.id}`,
            type: 'straight',
            source: current.id,
            target: next.id,
            animated: true
        });
    }

    return edges;
};

export const getNewWorkflowNodes = state => state.newWorkflow.data.nodes;
export const isInitializedSelector = state => state.newWorkflow.data.nodes.length && state.newWorkflow.data.builderWidth;
export const getNewWorkflowEdges = state => state.newWorkflow.data.edges;
export const getWorkflowState = state => state.newWorkflow.data;

export const {
    reset,
    addNewNode,
    setNodes,
    setEdges,
    setWorkflowName,
    setWorkflowIdCounter,
    setWorkflowActive,
    initBuilderState,
    initNodes
} = newWorkflowSlice.actions;
export default newWorkflowSlice.reducer;