import {createSlice} from '@reduxjs/toolkit'

export const customersSlice = createSlice({
    name: 'customers',
    initialState: {
        data: [
            {
                name: "Charles Patterson",
                email: 'CharlesPatterson@gmail.com',
                location: 'Los Angeles, California, USA',
                orders: 5,
                totalSum: '300.00'
            },
            {
                name: "Lorraine Edwards",
                email: 'LorraineEdwards@teleworm.us',
                location: 'Gypsum, Colorado, USA',
                orders: 10,
                totalSum: '500.00'
            },
            {
                name: "Shantell Jasso Garay",
                email: 'Shantell@mexicomail.mx',
                location: 'Maumee, Ohio, USA',
                orders: 1,
                totalSum: '150.00'
            },
            {
                name: "Publia Villegas Mares",
                email: 'PubliaVillegasMares@jourrapide.com',
                location: 'Madrid, Spain',
                orders: 3,
                totalSum: '2700.00'
            },
            {
                name: "Paul Schultz",
                email: 'PaulSchultz@gmail.com',
                location: 'Nerenstetten, Germany',
                orders: 7,
                totalSum: '450.00'
            },
        ]
    }
});

export const getCustomers = state => state.customers.data;

export default customersSlice.reducer;