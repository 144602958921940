import React, {useEffect, useState} from 'react';
import {useIntl} from "react-intl";
import {Alert, Box, Container, Grid, Snackbar, Typography} from "@mui/material";
import {usePrevious} from "../../hooks/usePrevious";
import {useViewportDimensions} from "../../hooks/useViewportDimensions";
import {API_URL, emailRegex} from "../../constants";
import {TextInput} from "../common/TextInput";
import {RegularButton} from "../common/RegularButton";

const styles = {
    container: {
        paddingBottom: {xs: '100px'},
    },
    caption: animate => [
        {
            fontSize: {xs: '40px', sm: '55px', md: '55px', lg: '60px'},
            fontWeight: 500,
            color: 'textColorPrimaryDarkTheme.main',
            textFillColor: 'transparent',
            textAlign: 'center',
            transition: 'transform 0.7s ease 0s, opacity 0.7s ease 0s',
            opacity: 0,
            background: 'radial-gradient(45% 100% at 50% 50%,#fff 30%,hsla(0,0%,100%,.35) 100%)',
            backgroundClip: 'text',
            transform: 'translate3d(0px, 100px, 0px)',
            marginBottom: {xs: '20px', sm: '20px'},
        },
        animate && {
            opacity: 1,
            transform: 'translate3d(0px, 0px, 0px)'
        }
    ],
    desc: animate => [
        {
            fontSize: {xs: '16px', sm: '20px', md: '22px', lg: '24px'},
            marginBottom: '24px',
            color: 'textColorPrimaryDarkTheme.main',
            textAlign: 'center',
            transition: 'transform 0.7s ease 0s, opacity 0.7s ease 0s',
            opacity: 0,
            transform: 'translate3d(0px, 100px, 0px)',
        },
        animate && {
            opacity: 1,
            transform: 'translate3d(0px, 0px, 0px)'
        }
    ],
    imagesWrapper: animate => [
        {
            transition: 'transform 0.7s ease 0s, opacity 0.7s ease 0s',
            opacity: 0,
            transform: 'translate3d(0px, 50px, 0px)',
        },
        animate && {
            opacity: 1,
            transform: 'translate3d(0px, 0px, 0px)'
        }
    ],
};

export const SignUp = () => {

    const intl = useIntl();
    const [animateCaption, setAnimateCaption] = useState(false);
    const [animateDesc, setAnimateDesc] = useState(false);
    const [animateSlides, setAnimateSlides] = useState(false);
    const [animate, setAnimate] = useState(false);
    const [scrollListener, setScrollListener] = useState(() => () => void 0);
    const previousListener = usePrevious(scrollListener);
    const [scrollTarget, setScrollTarget] = useState(undefined);
    const {viewportHeight} = useViewportDimensions();

    useEffect(() => {
        window.removeEventListener('scroll', previousListener);
        window.addEventListener('scroll', scrollListener);
        return () => window.removeEventListener('scroll', scrollListener);
    }, [scrollListener]);

    useEffect(() => {
        if (!scrollTarget)
            return;

        const listener = () => {
            const {y} = scrollTarget.getBoundingClientRect();
            if (viewportHeight >= y + 100) {
                setAnimate(true);
            }
        };
        listener();

        setScrollListener(() => listener);
    }, [scrollTarget, viewportHeight]);

    useEffect(() => {
        if (animate) {
            setTimeout(() => setAnimateCaption(true), 200);
            setTimeout(() => setAnimateDesc(true), 600);
            setTimeout(() => setAnimateSlides(true), 400);
        }
    }, [animate]);

    const [contactInfo, setContactInfo] = useState({
        name: '',
        email: '',
        companyName: ''
    });
    const [validationErrors, setValidationErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const handleInputChange = name => event => {
        setContactInfo({
            ...contactInfo,
            [name]: event.target.value
        });
    };

    const validators = {
        email: () => {
            if (!emailRegex.test(contactInfo.email)) return 'validation_email';
            return true;
        }
    };

    const runValidators = () => {
        return Object.entries(validators)
            .reduce((acc, currentValue) => {
                const [key, validator] = currentValue;
                const output = validator();
                if (output !== true) {
                    acc[key] = output;
                }
                return acc;
            }, {});
    };

    const validateInput = () => {
        const errors = runValidators();

        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = () => {
        if (validateInput()) {
            setLoading(true);

            fetch(`${API_URL}/fluxima`,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        name: contactInfo.name,
                        email: contactInfo.email,
                        companyName: contactInfo.companyName,
                    })
                })
                .then(response => {
                    setLoading(false);
                    if (response.status !== 200) {
                        throw Error();
                    }

                    setLoading(false);
                    setContactInfo({
                        name: '',
                        email: '',
                        companyName: ''
                    });
                    setValidationErrors({});
                    setOpen(true);
                })
                .catch(error => console.log(error.message));
        }
    };

    return <>
        <Box sx={styles.container}>
            <Container maxWidth="xl">
                <Grid container
                      justifyContent="center"
                      alignItems="start"
                      spacing={2}>
                    <Grid item xs={12} ref={node => {
                        if (node) {
                            setScrollTarget(node);
                        }
                    }}>
                        <Typography sx={styles.caption(animateCaption)}>
                            {intl.formatMessage({id: 'sign_up_caption'})}
                        </Typography>
                        <Typography sx={styles.desc(animateDesc)}>
                            {intl.formatMessage({id: 'sign_up_desc'})}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} container spacing={1}>
                        <Grid item xs={12}>
                            <TextInput value={contactInfo.email}
                                       disableLabel
                                       autoComplete={'email'}
                                       type={'email'}
                                       placeholder={intl.formatMessage({id: 'email'})}
                                       onChange={handleInputChange('email')}
                                       error={validationErrors.email}/>
                        </Grid>
                        <Grid item xs={12} style={{textAlign: 'center'}}>
                            <RegularButton text={'join_waitlist'}
                                           color={'purple'}
                                           onClick={handleSubmit}
                                           loadingButton
                                           loading={loading}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
        <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}
                  anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
            <Alert onClose={() => setOpen(false)} severity="success" sx={{width: '100%'}} elevation={6}
                   variant="filled">
                {intl.formatMessage({id: 'thank_you_reaching_out'})}
            </Alert>
        </Snackbar>
    </>;
};