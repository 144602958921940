import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import {useLocation} from "react-router-dom";
import {DrawerList} from "./DrawerList";

const styles = {
    nav: drawerWidth => [{
        width: {
            sm: drawerWidth
        },
        flexShrink: {
            sm: 0
        }
    }],
    drawer: drawerWidth => [{
        display: {xs: 'none', sm: 'block'},
        '& .MuiDrawer-paper': {
            '-ms-overflow-style': 'none',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
                display: 'none'
            },
            boxSizing: 'border-box',
            width: drawerWidth,
            bgcolor: 'backgroundPrimary.purple',
            borderRight: 'none',
            overflow: 'unset'
        },
    }],
    drawerMobile: drawerWidth => [{
        display: {xs: 'block', sm: 'none'},
        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
    }]
}

export const MainDrawer = props => {

    const location = useLocation();
    const {drawerWidth, drawerOpen, toggleDrawer} = props;

    return <Box component="nav" sx={styles.nav(drawerWidth)}>
        <Drawer
            variant="permanent"
            sx={styles.drawer(drawerWidth)}
            open>
            <DrawerList/>
        </Drawer>
        <Drawer
            variant="temporary"
            open={drawerOpen}
            onClose={toggleDrawer}
            ModalProps={{keepMounted: true}}
            sx={styles.drawerMobile(drawerWidth)}>
            <DrawerList/>
        </Drawer>
    </Box>;
};
