import * as React from 'react';
import {Box} from "@mui/material";
import {RegularButton} from "../common/RegularButton";

const styles = {
    appBar: {
        bgcolor: 'backgroundPrimary.main',
        boxShadow: 'none',
        position: 'fixed',
        width: '100%',
        zIndex: 1100
    },
    toolbar: {
        maxWidth: '1280px',
        margin: 'auto',
        height: '100%',
        padding: {xs: '8px 16px', sm: '16px 40px'},
        display: 'flex',
        justifyContent: {xs: 'center', md: 'space-between'},
        alignItems: 'center'
    },
    spacer: {
        flexGrow: 1
    },
    menuIconWrapper: {
        position: 'absolute',
        left: '20px',
        display: {xs: 'flex', md: 'none'},
        lineHeight: 0
    },
    menuIcon: {
        cursor: 'pointer'
    },
    buttonWrapper: {
        marginRight: '10px',
        width: '120px',
        display: {xs: 'none', sm: 'flex'},
    },
    logoCaptionWrapper: {
        display: 'flex',
        cursor: 'pointer',
        justifyContent: 'center',
        alignItems: 'center'
    },
    logo: {
        height: '30px',
        width: 'auto',
    },
    caption: {
        fontFamily: 'Tanker',
        fontSize: '28px',
        color: 'textColorPrimary.main',
    },
};

export const Navbar = () => {

    return <Box sx={styles.appBar}>
        <Box sx={styles.toolbar}>
            <Box sx={styles.logoCaptionWrapper}>
                <Box component="img" src={'/Fluxima_full-white-cropped.svg'} sx={styles.logo}/>
            </Box>
            <Box sx={styles.spacer}/>
            <Box>
                <RegularButton text={'book_demo'} onClick={() => window.open("https://calendly.com/fluxima-demo", "_blank")} color={'purple'}/>
            </Box>
        </Box>
    </Box>;
};