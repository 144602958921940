import * as React from 'react';
import {Container, Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {useNavigate, useParams} from "react-router-dom";
import {useIntl} from "react-intl";
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import {useSelector} from "react-redux";
import {getConnectorById} from "../../reducers/integrations";
import {CommonDivider} from "../common/CommonDivider";
import {CommonTabs} from "../common/CommonTabs";
import {ConnectorDetailGeneral} from "./ConnectorDetailGeneral";
import {ConnectorDetailActions} from "./ConnectorDetailActions";
import {ConnectorDetailLogs} from "./ConnectorDetailLogs";
import {BeaconPopup} from "../featureTour/BeaconPopup";

const styles = {
    wrapper: {
        padding: '2rem'
    },
    backWrapper: {
        display: 'flex',
        cursor: 'pointer',
    },
    backText: {
        marginLeft: '8px',
        color: 'textColorPrimary.main',
        fontWeight: 500,
        fontSize: '14px'
    },
    imageWrapper: {
        marginRight: '1rem',
        alignItems: 'center',
        justifyContent: 'center',
        height: '60px',
        borderRadius: '1rem',
    },
    image: {
        width: 'auto',
        height: 'auto',
        maxHeight: '100%',
        maxWidth: '150px'
    },
    connectorName: {
        color: 'textColorPrimary.main',
        fontWeight: 700,
        fontSize: '1.5rem'
    },
    connectorStatusText: {
        color: 'textColorPrimary.main',
        fontSize: '14px'
    },
    connectorStatus: activated => [
        {
            color: 'orangered',
            fontSize: '14px',
            fontWeight: '500'
        },
        activated && {
            color: 'green.dark',
        }
    ],
    paper: {
        padding: '1.5rem',
        boxShadow: 'rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px',
        borderRadius: '1rem',
    },
    activationLabel: {
        fontWeight: 600,
        fontSize: '18px'
    },
    nameWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    beaconWrapper: {
        marginLeft: '20px'
    },
};

export const ConnectorDetail = () => {

    const navigate = useNavigate();
    const {connectorId} = useParams();
    const connector = useSelector(getConnectorById(parseInt(connectorId)));

    const intl = useIntl();

    const tabs = [
        {label: 'general'},
        {label: 'actions'},
        {label: 'logs'},
    ];

    const [tabValue, setTabValue] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return <Container maxWidth={'lg'} sx={styles.wrapper}>
        <Grid container
              justifyContent="center"
              alignItems="center"
              spacing={3}>
            <Grid item xs={12}>
                <Box sx={styles.backWrapper} onClick={() => navigate('/usecase1/integrations')} component={'span'}>
                    <ArrowBackRoundedIcon fontSize={'small'}/>
                    <Typography sx={styles.backText} component={'span'}>
                        {intl.formatMessage({id: 'integrations'})}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12} container>
                <Grid item>
                    <Box sx={styles.imageWrapper}>
                        <Box component={'img'} src={connector.logoPath} sx={styles.image}/>
                    </Box>
                </Grid>
                <Grid item>
                    <Box sx={styles.nameWrapper}>
                        <Typography sx={styles.connectorName}>
                            {connector.name}
                        </Typography>
                        <Box sx={styles.beaconWrapper}>
                            <BeaconPopup placement={'right-start'}
                                         arrowPlacement={'right'}
                                         label={'feature_tour_connector_detail_label'}
                                         text={'feature_tour_connector_detail_desc'}
                                         current={{stepName: 'connectorDetail', path: '/integrations/1'}}
                                         next={{stepName: 'newWorkflowStep1', path: '/workflow/new'}}
                                         previous={{stepName: 'marketplace', path: '/integrations'}}/>
                        </Box>
                    </Box>
                    <Typography component={'span'} sx={styles.connectorStatusText}>
                        {intl.formatMessage({id: 'status'})}:&nbsp;
                    </Typography>
                    <Typography component={'span'} sx={styles.connectorStatus(connector.activated)}>
                        {intl.formatMessage({id: connector.activated ? 'active' : 'inactive'}).toUpperCase()}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <CommonTabs tabs={tabs} tabValue={tabValue} handleChange={handleTabChange}
                            customStyles={{marginTop: '10px'}}/>
            </Grid>
        </Grid>
        <CommonDivider/>
        {tabValue === 0 && <ConnectorDetailGeneral/>}
        {tabValue === 1 &&
            <ConnectorDetailActions actions={connector.actions} commonActionFlows={connector.commonActionFlows}/>}
        {tabValue === 2 && <ConnectorDetailLogs/>}
    </Container>;
};