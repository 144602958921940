import {createSlice} from '@reduxjs/toolkit'

export const productsSlice = createSlice({
    name: 'products',
    initialState: {
        data: [
            {
                name: "Collagen peptides",
                category: 'skincare',
                stock: 20,
                sku: 'PRODUCT_ABC_123',
                price: '23.99',
                imageName: 'product_1.jpg',
                status: 'published'
            },
            {
                name: "Vitamin C",
                category: 'immunity',
                stock: 80,
                sku: 'PRODUCT_DEF_234',
                price: '4.50',
                imageName: 'product_2.jpg',
                status: 'published'
            },
            {
                name: "Melatonin",
                category: 'insomnia',
                stock: 5,
                sku: 'PRODUCT_XYZ_789',
                price: '12.33',
                imageName: 'product_3.jpg',
                status: 'draft'
            },
            {
                name: "Niacin",
                category: 'longevity',
                stock: 35,
                sku: 'PRODUCT_KLM_456',
                price: '12.90',
                imageName: 'product_4.jpg',
                status: 'published'
            },
            {
                name: "Electrolytes",
                category: 'exercise',
                stock: 2,
                sku: 'PRODUCT_PQR_678',
                price: '45.49',
                imageName: 'product_5.jpg',
                status: 'draft'
            },
        ]
    }
});

export const getProducts = state => state.products.data;

export default productsSlice.reducer;