import React, {useEffect, useRef, useState} from "react";
import {Beacon} from "./Beacon";
import {useDispatch, useSelector} from "react-redux";
import {isStepActive, setActiveStep} from "../../reducers/featureTour";
import {Box, Fade, Grid, Paper, Popper, Typography} from "@mui/material";
import {useIntl} from "react-intl";
import {RegularButton} from "../common/RegularButton";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ImportantDevicesRoundedIcon from '@mui/icons-material/ImportantDevicesRounded';
import {useLocation, useNavigate} from "react-router-dom";
import {usePathPrefix} from "../../hooks/usePathPrefix";

const styles = {
    popper: {
        zIndex: 1300
    },
    paper: {
        boxShadow: 'rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px',
        borderRadius: '1rem',
        background: 'radial-gradient(circle at 18.7% 37.8%,#fcfcfc 0,#ccd1ff 90%)',
        padding: '1rem',
        width: '600px'
    },
    overlay: {
        backgroundColor: 'transparent',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
        zIndex: 1300
    },
    arrow: popperPosition => [
        {
            color: '#f2f3ff',
            position: 'absolute',
            width: '3rem',
            height: '3rem',
        },
        popperPosition === 'right' && {
            left: '-32px',
            transform: 'rotate(180deg)'
        },
    ],
    label: {
        fontWeight: 'bold',
        fontSize: '16px'
    },
    desc: {
        fontSize: '14px'
    },
    featureOwnedDesc: {
        fontSize: '14px',
        fontWeight: 600
    },
    featureOwnedWrapper: {
        display: 'flex',
        alignItems: 'center',
    }
};

export const BeaconPopup = props => {

    const {
        current,
        next,
        onNext,
        previous,
        onPrevious,
        placement,
        arrowPlacement,
        label,
        text,
        developedByFluxima = true,
        developedByBusiness = false
    } = props;
    const {stepName, path} = current;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const prefixPath = usePathPrefix();
    const intl = useIntl();
    const stepActive = useSelector(isStepActive(stepName));
    const anchorElRef = useRef(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (path) {
            setOpen(stepActive && location.pathname === prefixPath(path));
        } else {
            setOpen(stepActive);
        }
    }, [stepActive, location.pathname]);

    const onBeaconClick = () => {
        dispatch(setActiveStep(stepName));
    };

    const onClose = () => {
        dispatch(setActiveStep(''));
    };

    const nextClickHandler = data => () => {
        if (onNext)
            onNext();
        commonClickHandler(data);
    };

    const previousClickHandler = data => () => {
        if (onPrevious)
            onPrevious();
        commonClickHandler(data);
    };

    const commonClickHandler = data => {
        const {stepName, path} = data;
        if (path)
            navigate(prefixPath(path));

        dispatch(setActiveStep(stepName));
    };

    return <>
        <Beacon ref={anchorElRef} onClick={onBeaconClick}/>
        {open && <Box sx={styles.overlay} onClick={onClose}/>}
        <Popper open={open}
                anchorEl={anchorElRef.current}
                placement={placement}
                sx={styles.popper}
                transition
                disablePortal>
            {({TransitionProps}) => (
                <Fade {...TransitionProps} timeout={350}>
                    <Box>
                        <Paper sx={styles.paper}>
                            <PlayArrowIcon sx={styles.arrow(arrowPlacement)}/>
                            <Grid container
                                  spacing={2}
                                  justifyContent="center"
                                  alignItems="center">
                                <Grid item xs={12}>
                                    <Typography sx={styles.label}>
                                        {intl.formatMessage({id: label})}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={styles.desc}>
                                        {intl.formatMessage({id: text})}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={styles.featureOwnedWrapper}>
                                        <Typography sx={styles.featureOwnedDesc} style={{marginRight: '20px'}}>
                                            {intl.formatMessage({id: 'feature_ownership'})}
                                        </Typography>
                                        {developedByFluxima && <>
                                            <Box component={'img'} src={'/fluxima_logo.png'}
                                                 style={{marginRight: '10px', width: 'auto', height: '24px'}}/>
                                            <Typography sx={styles.featureOwnedDesc} style={{marginRight: '30px'}}>
                                                Fluxima
                                            </Typography>
                                        </>}
                                        {developedByBusiness && <>
                                            <ImportantDevicesRoundedIcon color={'primary'}
                                                                         style={{marginRight: '10px'}}/>
                                            <Typography sx={styles.featureOwnedDesc}>
                                                {intl.formatMessage({id: 'your_business'})}
                                            </Typography>
                                        </>}
                                    </Box>
                                </Grid>
                                <Grid item xs={12} style={{textAlign: 'right'}}>
                                    <RegularButton text={'close'} variant={'text'} onClick={onClose}
                                                   customStyles={{marginRight: '10px'}}/>
                                    {previous && <RegularButton text={'previous'} customStyles={next && {marginRight: '10px'}}
                                                                onClick={previousClickHandler(previous)}/>}
                                    {next && <RegularButton text={'next'} onClick={nextClickHandler(next)}/>}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Box>
                </Fade>
            )}
        </Popper>
    </>
};