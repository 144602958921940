import * as React from 'react';
import {Tab, Tabs} from "@mui/material";
import {useIntl} from "react-intl";

const styles = {
    tabs: {
        '& .MuiTab-root': {
            textTransform: 'none'
        },
    }
}

export const CommonTabs = props => {

    const intl = useIntl();
    const {tabs, tabValue, handleChange, customStyles = {}} = props;

    return <Tabs value={tabValue} onChange={handleChange} sx={{
        ...styles.tabs,
        ...customStyles
    }}>
        {tabs.map(tab => {
            const tabProps = {
                id: `common_tab_${tab.label}`,
                key: `common_tab_${tab.label}`,
                label: intl.formatMessage({id: tab.label}),
                iconPosition: "start",
                ...(tab.icon && {icon: tab.icon})
            };

            return <Tab disableRipple {...tabProps}/>
        })}
    </Tabs>;
};
