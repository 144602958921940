import * as React from 'react';
import {Container, Grid, Paper, Typography} from "@mui/material";
import 'reactflow/dist/style.css';
import {useSelector} from "react-redux";
import {getStartWorkflowActions} from "../../reducers/workflowActions";
import {useIntl} from "react-intl";
import {IconFactory} from "../automation/common";
import Box from "@mui/material/Box";
import {BeaconPopup} from "../featureTour/BeaconPopup";

const styles = {
    actionPaper: {
        borderRadius: '0.5rem',
        padding: '1rem',
        cursor: 'pointer',
        boxShadow: 'rgb(0 0 0 / 5%) 0rem 0.25rem 1.6875rem 0.5rem',
        height: '100px'

    },
    icon: {
        width: '40px',
        height: '40px',
        color: 'green.main',
        marginRight: '1rem'
    },
    caption: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '18px',
    },
    desc: {
        textAlign: 'justify',
        fontSize: '16px',
        marginBottom: '1rem'
    },
    connectorName: {
        fontWeight: 'bold',
        fontSize: '16px'
    },
    connectorDesc: {
        fontSize: '14px'
    },
    nameWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '1rem'
    },
    beaconWrapper: {
        marginLeft: '20px'
    },
};

export const WorkflowSelectStartAction = props => {

    const {onClick} = props;
    const actions = useSelector(getStartWorkflowActions);
    const intl = useIntl();

    return <Grid container
                 style={{height: '100%'}}
                 flexDirection={'column'}
                 alignItems="center">
        <Grid item xs/>
        <Grid item>
            <Container maxWidth="md">
                <Grid container
                      alignItems='center'
                      justifyContent='center'
                      spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={styles.nameWrapper}>
                            <Typography sx={styles.caption}>
                                {intl.formatMessage({id: 'pick_start_action'})}
                            </Typography>
                            <Box sx={styles.beaconWrapper}>
                                <BeaconPopup placement={'right-start'}
                                             arrowPlacement={'right'}
                                             label={'feature_tour_new_workflow_step1_label'}
                                             text={'feature_tour_new_workflow_step1_desc'}
                                             current={{stepName: 'newWorkflowStep1', path: '/workflow/new'}}
                                             next={{stepName: 'newWorkflowStep2', path: '/workflow/new'}}
                                             onNext={onClick(actions[0])}
                                             previous={{stepName: 'connectorDetail', path: '/integrations/1'}}/>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={styles.desc}>
                            {intl.formatMessage({id: 'pick_start_action_desc'})}
                        </Typography>
                        <Typography sx={styles.desc}>
                            {intl.formatMessage({id: 'pick_start_action_desc2'})}
                        </Typography>
                        <Typography sx={styles.desc}>
                            {intl.formatMessage({id: 'pick_start_action_desc3'})}
                        </Typography>
                    </Grid>
                    {actions.map(action => <Grid item xs={12}>
                        <Paper sx={styles.actionPaper} onClick={onClick(action)}>
                            <Grid container
                                  style={{height: '100%'}}
                                  justifyContent="center"
                                  alignItems="center">
                                <Grid item>
                                    {IconFactory[action.workflowActionType](styles.icon)}
                                </Grid>
                                <Grid item xs>
                                    <Typography sx={styles.connectorName}>
                                        {action.name}
                                    </Typography>
                                    <Typography sx={styles.connectorDesc}>
                                        {action.description}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>)}
                </Grid>
            </Container>
        </Grid>
        <Grid item xs/>
    </Grid>;
};