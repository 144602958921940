import * as React from 'react';
import {Box} from "@mui/material";
import 'reactflow/dist/style.css';
import {NODE_HEIGHT, NODE_WIDTH} from "../common";
import {Handle} from "reactflow";

const styles = {
    node: {
        border: '1px dashed #5048e5',
        borderRadius: '0.5rem',
        boxShadow: '0 4px 12px rgb(0 0 0 / 8%)',
        padding: '12px',
        backgroundColor: 'white',
        width: `${NODE_WIDTH}px`,
        height: `${NODE_HEIGHT}px`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '30px',
        color: 'violet.main',
        cursor: 'pointer',
        lineHeight: 1.2,
        '&:hover': {
            background: 'radial-gradient(circle at 18.7% 37.8%,#fcfcfc 0,#eff1f3 90%)'
        },
        '.react-flow__handle': {
            width: '8px',
            height: '10px',
            borderRadius: '3px',
        }
    }
};

export const AddMoreNode = props => {

    const {data, selected} = props;
    const {onClick} = data;

    return <>
        <Box sx={styles.node} onClick={onClick}>
            <div>
                +
            </div>
        </Box>
        <Handle type="target" style={{visibility: 'hidden'}}/>
    </>;
};