import * as React from 'react';
import {Grid, List, ListItem, Paper, Typography} from "@mui/material";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import Box from "@mui/material/Box";
import {getWorkflowExecutions} from "../../reducers/workflowExecutions";
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';
import CategoryRoundedIcon from '@mui/icons-material/CategoryRounded';
import IconButton from "@mui/material/IconButton";
import {BeaconPopup} from "../featureTour/BeaconPopup";

const styles = {
    caption: {
        padding: '1rem',
        fontSize: '26px'
    },
    paper: {
        boxShadow: 'rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px',
        borderRadius: '1rem',
        margin: '1.5rem 0',
        overflow: 'hidden'
    },
    activationLabelWrapper: {
        padding: '1.5rem'
    },
    activationLabel: {
        fontWeight: 600,
        fontSize: '18px'
    },
    list: {
        margin: 0,
        padding: 0
    },
    listItem: beforeLast => [
        {
            padding: '12px 24px'
        },
        beforeLast && {
            borderBottom: '1px solid rgb(230, 232, 240)'
        }
    ],
    statusCode: status => [
        {
            textAlign: 'center',
            borderRadius: '16px',
            color: 'white',
            bgcolor: '#14b8a6',
            fontSize: '0.75rem',
            lineHeight: 2,
            fontWeight: 600,
            letterSpacing: '0.5px',
            padding: '4px 8px',
        },
        status === 'error' && {
            bgcolor: 'orangered'
        },
        status === 'incomplete' && {
            bgcolor: 'orange'
        },
        status === 'processing' && {
            bgcolor: 'violet.light'
        }
    ],
    tableCellText: {
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: 1.57
    },
    buttonWrapper: {
        textAlign: 'end'
    },
    tableHeader: {
        padding: '12px 24px',
        bgcolor: 'lightgrey'
    },
    tableHeaderText: {
        fontSize: '1rem',
        fontWeight: 700,
        lineHeight: 1.57
    },
    flexWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
};

export const WorkflowExecutions = () => {

    const executions = useSelector(getWorkflowExecutions);
    const intl = useIntl();

    const header = <ListItem sx={styles.tableHeader}>
        <Grid container
              spacing={2}
              justifyContent="center"
              alignItems="center">
            <Grid item xs={2}>
                    <Typography sx={styles.tableHeaderText}>
                    {intl.formatMessage({id: 'execution_id'})}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography sx={styles.tableHeaderText}>
                    {intl.formatMessage({id: 'workflow_execution_name'})}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography sx={styles.tableHeaderText}>
                    {intl.formatMessage({id: 'job_duration'})}
                </Typography>
            </Grid>
            <Grid item xs={1}>
                <Typography sx={styles.tableHeaderText}>
                    {intl.formatMessage({id: 'status'})}
                </Typography>
            </Grid>
            <Grid item xs/>
            <Grid item xs={2}>
                <Typography sx={styles.tableHeaderText}>
                    {intl.formatMessage({id: 'timestamp'})}
                </Typography>
            </Grid>
            <Grid item xs={1}>
                <Typography sx={styles.tableHeaderText} style={{textAlign: 'end'}}>
                    {intl.formatMessage({id: 'actions'})}
                </Typography>
            </Grid>
        </Grid>
    </ListItem>;

    return <>
        <Box sx={styles.flexWrapper}>
            <Typography sx={styles.caption}>
                {intl.formatMessage({id: 'job_executions'})}
            </Typography>
            <Box>
                <BeaconPopup placement={'right-start'}
                             arrowPlacement={'right'}
                             label={'feature_tour_workflow_executions_label'}
                             text={'feature_tour_workflow_executions_desc'}
                             current={{stepName: 'workflowExecutions', path: '/workflow/execution'}}
                             previous={{stepName: 'prebuiltFlows', path: '/workflow/prebuilt'}}/>
            </Box>
        </Box>
        <Paper sx={styles.paper}>
            <List sx={styles.list}>
                {header}
                {executions.map((execution, index) => <ListItem sx={styles.listItem(index !== executions.length - 1)}>
                    <Grid container
                          spacing={2}
                          justifyContent="center"
                          alignItems="center">
                        <Grid item xs={2}>
                            <Typography sx={styles.tableCellText}>
                                {execution.executionId}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography sx={styles.tableCellText}>
                                {execution.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography sx={styles.tableCellText}>
                                {intl.formatMessage({id: 'execution_duration_seconds'}, {duration: execution.duration})}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={styles.tableCellText}>
                            <Typography sx={styles.statusCode(execution.status)}>
                                {intl.formatMessage({id: `execution_status_${execution.status}`})}
                            </Typography>
                        </Grid>
                        <Grid item xs/>
                        <Grid item xs={2}>
                            <Typography sx={styles.tableCellText}>
                                {execution.timestamp}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Box sx={styles.buttonWrapper}>
                                <IconButton sx={{color: 'violet.main'}} component="label">
                                    <ReplayRoundedIcon/>
                                </IconButton>
                                <IconButton sx={{color: 'violet.main'}} component="label">
                                    <CategoryRoundedIcon/>
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>
                </ListItem>)}
            </List>
        </Paper>
    </>;
};