import UpdateRoundedIcon from "@mui/icons-material/UpdateRounded";
import WebhookRoundedIcon from "@mui/icons-material/WebhookRounded";
import PivotTableChartRoundedIcon from "@mui/icons-material/PivotTableChartRounded";
import FilterAltRoundedIcon from "@mui/icons-material/FilterAltRounded";
import MergeTypeRoundedIcon from "@mui/icons-material/MergeTypeRounded";
import AltRouteRoundedIcon from "@mui/icons-material/AltRouteRounded";
import PowerInputRoundedIcon from "@mui/icons-material/PowerInputRounded";
import FormatListBulletedRoundedIcon from "@mui/icons-material/FormatListBulletedRounded";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import {WorkflowActionTypes} from "../../constants";

export const NODE_WIDTH = 200;
export const NODE_HEIGHT = 65;
export const EDGE_LENGTH = 100;
export const INTER_NODE_PADDING = 100;

export const IconFactory = {
    [WorkflowActionTypes.POLLING]: styles => <UpdateRoundedIcon sx={styles}/>,
    [WorkflowActionTypes.WEBHOOK]: styles => <WebhookRoundedIcon sx={styles}/>,
    [WorkflowActionTypes.TRANSFORM]: styles => <PivotTableChartRoundedIcon sx={styles}/>,
    [WorkflowActionTypes.FILTER]: styles => <FilterAltRoundedIcon sx={styles}/>,
    [WorkflowActionTypes.MERGE]: styles => <MergeTypeRoundedIcon sx={styles}/>,
    [WorkflowActionTypes.SPLIT]: styles => <AltRouteRoundedIcon sx={styles}/>,
    [WorkflowActionTypes.FLATTEN]: styles => <PowerInputRoundedIcon sx={styles}/>,
    [WorkflowActionTypes.GROUP]: styles => <FormatListBulletedRoundedIcon sx={styles}/>,
    [WorkflowActionTypes.SORT]: styles => <FilterListRoundedIcon sx={styles}/>
}