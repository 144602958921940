import {createSlice} from '@reduxjs/toolkit';

export const featureTourSlice = createSlice({
    name: 'featureTour',
    initialState: {
        mainModalShownOnce: false,
        mainModalShown: false,
        activeStep: ''
    },
    reducers: {
        showMainModal: (state, action) => {
            state.mainModalShown = true;
            state.mainModalShownOnce = true;
        },
        hideMainModal: (state, action) => {
            state.mainModalShown = false;
        },
        setActiveStep: (state, action) => {
            state.activeStep = action.payload;
        },
    },
});

export const isMainModalShown = state => state.featureTour.mainModalShown;
export const isMainModalShownOnce = state => state.featureTour.mainModalShownOnce;
export const getActiveStep = state => state.featureTour.activeStep;
export const isStepActive = name => state => state.featureTour.activeStep === name;

export const {
    showMainModal,
    hideMainModal,
    setActiveStep,
} = featureTourSlice.actions;
export default featureTourSlice.reducer;