import * as React from 'react';
import {useEffect} from 'react';
import {Box} from "@mui/material";
import {useSelector} from "react-redux";
import {getConnectorsByCategorySelector} from "../../reducers/integrations";
import {ConnectorCategory} from "./ConnectorCategory";
import {AppListing} from "../../constants";
import {ChoiceButton} from "../common/ChoiceButton";
import {Search} from "../common/Search";
import {BeaconPopup} from "../featureTour/BeaconPopup";

const styles = {
    controlsWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    spacer: {
        flexGrow: 1
    },
    beaconWrapper: {
        marginLeft: '20px'
    },
};

export const ConnectorMarketplace = () => {

    const allConnectors = useSelector(getConnectorsByCategorySelector);
    const [searchString, setSearchString] = React.useState('');
    const [appListing, setAppListing] = React.useState(AppListing.BY_APP);
    const [searchFilteredConnectors, setSearchFilteredConnectors] = React.useState([]);
    const appListingOptions = [
        {value: AppListing.BY_APP, i18n: `app_listing_${AppListing.BY_APP}`},
        {value: AppListing.BY_COMPANY, i18n: `app_listing_${AppListing.BY_COMPANY}`},
    ];

    useEffect(() => {
        let filteredItems = allConnectors;

        if (searchString !== '') {
            const sanitizedSearchString = searchString.toLowerCase();
            filteredItems = allConnectors.map(category => {
                const filteredConnectors = category.connectors.filter(conn =>
                    conn.name.toLowerCase().includes(sanitizedSearchString) ||
                    conn.description.toLowerCase().includes(sanitizedSearchString) ||
                    conn.keywords.some(keyword => keyword.includes(sanitizedSearchString)));
                return {
                    ...category,
                    connectors: filteredConnectors
                }
            }).filter(c => c.connectors.length);
        }
        setSearchFilteredConnectors(filteredItems);
    }, [searchString]);

    return <>
        <Box sx={styles.controlsWrapper}>
            <Search value={searchString} setValue={setSearchString}/>
            <Box sx={styles.beaconWrapper}>
                <BeaconPopup placement={'right-start'}
                             arrowPlacement={'right'}
                             label={'feature_tour_marketplace_label'}
                             text={'feature_tour_marketplace_desc'}
                             current={{stepName: 'marketplace', path: '/integrations'}}
                             next={{stepName: 'connectorDetail', path: '/integrations/1'}}
                             previous={{stepName: 'automationMenu'}}/>
            </Box>
            <Box sx={styles.spacer}/>
            <ChoiceButton selectedOption={appListing} options={appListingOptions} setValue={setAppListing}/>
        </Box>
        {searchFilteredConnectors.map(category => <ConnectorCategory category={category}/>)}
    </>;
};