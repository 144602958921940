import * as React from 'react';
import {Grid, Paper, Typography} from "@mui/material";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import Box from "@mui/material/Box";
import {CommonDivider} from "../common/CommonDivider";
import AccountTreeRoundedIcon from '@mui/icons-material/AccountTreeRounded';
import IconButton from "@mui/material/IconButton";
import {useNavigate} from "react-router-dom";
import {getPrebuiltWorkflows} from "../../reducers/prebuiltWorkflows";
import PrecisionManufacturingRoundedIcon from '@mui/icons-material/PrecisionManufacturingRounded';
import {setEdges, setNodes, setWorkflowIdCounter, setWorkflowName} from "../../reducers/newWorkflow";
import {BeaconPopup} from "../featureTour/BeaconPopup";

const styles = {
    caption: {
        padding: '1rem',
        fontSize: '26px'
    },
    paper: {
        boxShadow: 'rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px',
        borderRadius: '1rem',
        overflow: 'hidden'
    },
    nameWrapper: {
        padding: '1.5rem'
    },
    name: {
        fontSize: '18px',
        fontWeight: 'bold'
    },
    contentWrapper: {
        padding: '1.5rem'
    },
    flexWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
};

export const PrebuiltWorkflows = () => {

    const prebuiltWorkflows = useSelector(getPrebuiltWorkflows);
    const intl = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const clickHandler = wf => () => {
        dispatch(setNodes(wf.nodes));
        dispatch(setEdges(wf.edges));
        dispatch(setWorkflowName(wf.name));
        dispatch(setWorkflowIdCounter(wf.idCounter));
        navigate('/usecase1/workflow/new');
    };

    return <>
        <Box sx={styles.flexWrapper}>
            <Typography sx={styles.caption}>
                {intl.formatMessage({id: 'prebuilt_workflows'})}
            </Typography>
            <Box>
                <BeaconPopup placement={'right-start'}
                             arrowPlacement={'right'}
                             label={'feature_tour_prebuilt_flows_label'}
                             text={'feature_tour_prebuilt_flows_desc'}
                             current={{stepName: 'prebuiltFlows', path: '/workflow/prebuilt'}}
                             developedByBusiness={true}
                             next={{stepName: 'workflowExecutions', path: '/workflow/execution'}}
                             previous={{stepName: 'newWorkflowStep1', path: '/workflow/new'}}/>
            </Box>
        </Box>
        <Grid container
              spacing={2}
              justifyContent="start"
              alignItems="center">
            {prebuiltWorkflows.map(wf =>
                <Grid item xs={6}>
                    <Paper sx={styles.paper}>
                        <Box sx={styles.nameWrapper}>
                            <Grid container
                                  spacing={2}
                                  justifyContent="center"
                                  alignItems="center">
                                <Grid item style={{display: 'flex'}}>
                                    <AccountTreeRoundedIcon color={'primary'}/>
                                </Grid>
                                <Grid item xs>
                                    <Typography sx={styles.name}>
                                        {wf.name}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <IconButton color="primary" component="label" onClick={clickHandler(wf)}>
                                        <PrecisionManufacturingRoundedIcon/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Box>
                        <CommonDivider/>
                        <Box sx={styles.contentWrapper}>
                            <Grid container
                                  spacing={2}
                                  justifyContent="start"
                                  alignItems="center">
                                <Grid item xs={2}>
                                    <Typography>
                                        {intl.formatMessage({id: 'nodes_count'}, {count: wf.nodes.length - 1})}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography>
                                        {intl.formatMessage({id: 'successful_executions_count'}, {count: 100})}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography>
                                        {intl.formatMessage({id: 'errors_count'}, {count: 3})}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Grid>)}
        </Grid>
    </>;
};