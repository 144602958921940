import {createSlice} from '@reduxjs/toolkit'
import {IntegrationCategory} from "../constants";

export const integrationCategoriesSlice = createSlice({
    name: 'integrationCategories',
    initialState: {
        data: [
            {
                id: 1,
                order: 1,
                name: IntegrationCategory.COMMUNICATION
            },
            {
                id: 5,
                order: 2,
                name: IntegrationCategory.PAYMENT_PROVIDER
            },
            {
                id: 2,
                order: 3,
                name: IntegrationCategory.CUSTOMER_RELATIONSHIP_MANAGEMENT
            },
            {
                id: 3,
                order: 4,
                name: IntegrationCategory.PROJECT_MANAGEMENT
            },
            {
                id: 4,
                order: 5,
                name: IntegrationCategory.MARKETING
            },
            {
                id: 6,
                order: 6,
                name: IntegrationCategory.ENTERPRISE_RESOURCE_PLANNING
            },
        ]
    }
})

export default integrationCategoriesSlice.reducer;