import * as React from 'react';
import {Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {useIntl} from "react-intl";
import {ConnectorCard} from "./ConnectorCard";

const styles = {
    content: {
        margin: '20px 0'
    },
    categoryName: {
        padding: '1rem 0',
        color: 'textColorPrimary.main',
        fontWeight: 'bold',
        fontSize: '20px'
    },
    image: {
        height: '100%',
        width: 'auto'
    },
};

export const ConnectorCategory = props => {

    const {category} = props;
    const intl = useIntl();

    return <Box sx={styles.content}>
        <Typography sx={styles.categoryName}>
            {intl.formatMessage({id: `category_${category.name}`})}
        </Typography>
        <Grid container
              alignItems="center"
              spacing={2}>
            {category.connectors.map(conn => <Grid item xs={4} xl={3}>
                <ConnectorCard connector={conn}/>
            </Grid>)}
        </Grid>
    </Box>;
};