import {createSlice} from '@reduxjs/toolkit'
import {MemberFieldTypes, WorkflowActionTypes} from "../constants";

export const workflowActionsSlice = createSlice({
    name: 'workflowActions',
    initialState: {
        data: [
            {
                name: 'Poller',
                startNode: true,
                description: 'Periodically poll the given target for new data that need to be processed in your pipeline.',
                workflowActionType: WorkflowActionTypes.POLLING,
                sourceDataShape: MemberFieldTypes.LIST,
                resultDataShape: MemberFieldTypes.LIST,
                keywords: [],
            },
            {
                name: 'Webhook',
                startNode: true,
                description: 'Reactively accept new data in a specific endpoint to be processed in your pipeline. ',
                workflowActionType: WorkflowActionTypes.WEBHOOK,
                sourceDataShape: MemberFieldTypes.LIST,
                resultDataShape: MemberFieldTypes.LIST,
                keywords: [],
            },
            {
                name: 'Transform',
                description: 'Transforms data into a new shape required by the next step in the pipeline.',
                workflowActionType: WorkflowActionTypes.TRANSFORM,
                sourceDataShape: MemberFieldTypes.LIST,
                resultDataShape: MemberFieldTypes.LIST,
                keywords: [],
            },
            {
                name: 'Filter',
                description: 'Filters data, only leaving items matching a condition.',
                workflowActionType: WorkflowActionTypes.FILTER,
                sourceDataShape: MemberFieldTypes.LIST,
                resultDataShape: MemberFieldTypes.LIST,
                keywords: [],
            },
            {
                name: 'Merge',
                description: 'Merges two various sources of data into new combined entities.',
                workflowActionType: WorkflowActionTypes.MERGE,
                sourceDataShape: MemberFieldTypes.LIST,
                resultDataShape: MemberFieldTypes.LIST,
                keywords: [],
            },
            {
                name: 'Split',
                description: 'Divides data into two new lists of items, each to be processed in its own branch.',
                workflowActionType: WorkflowActionTypes.SPLIT,
                sourceDataShape: MemberFieldTypes.LIST,
                resultDataShape: MemberFieldTypes.LIST,
                keywords: [],
            },
            {
                name: 'Flatten',
                description: 'Combines a list of nested lists into a single long list.',
                workflowActionType: WorkflowActionTypes.FLATTEN,
                sourceDataShape: MemberFieldTypes.LIST,
                resultDataShape: MemberFieldTypes.LIST,
                keywords: [],
            },
            {
                name: 'Group',
                description: 'Groups data into lists based on a grouping condition.',
                workflowActionType: WorkflowActionTypes.GROUP,
                sourceDataShape: MemberFieldTypes.LIST,
                resultDataShape: MemberFieldTypes.LIST,
                keywords: [],
            },
            {
                name: 'Sort',
                description: 'Sort data based on a sorting rule.',
                workflowActionType: WorkflowActionTypes.SORT,
                sourceDataShape: MemberFieldTypes.LIST,
                resultDataShape: MemberFieldTypes.LIST,
                keywords: [],
            }
        ]
    }
})

export const getAllWorkflowActions = state => {
    const integrations = state.integrations.data.map(integration => {
        return {
            ...integration,
            workflowActionType: WorkflowActionTypes.INTEGRATION
        };
    });

    return [...state.workflowActions.data.filter(wa => !wa.startNode), ...integrations];
}

export const getStartWorkflowActions = state => {
    return state.workflowActions.data.filter(wa => wa.startNode);
}

export default workflowActionsSlice.reducer;