export const messagesEn = {
    sk: "Slovensky",
    en: "English",
    default_locale: "Default locale",
    introduction: "Introduction",
    benefits: "Benefits",
    pricing: "Pricing",
    for_businesses: "For businesses",
    for_public: "For public",
    login: "Log in",
    sign_in: "Sign in",
    sign_up: "Sign up",
    join_waitlist: "Join the waitlist",
    sign_out: "Sign out",
    google: "Google",
    facebook: "Facebook",
    buy_now: "Buy now",
    welcome_back: "Welcome back",
    join_us_today: "Join us today",
    dont_have_account: "Don't have an account?",
    enter_your_email_password: "Enter your email and password to sign in",
    enter_your_email_name_password: "Enter your email, name and a password to register",
    email: "Email",
    confirmEmail: "Confirm email",
    dashboard: 'Dashboard',
    traffic_sources: 'Traffic sources',
    impressions_label: 'Impressions',
    impressions_desc: '12% more then last month',
    spent_label: 'Spent',
    spent_desc: '30% less then last month',
    engagements_label: 'Engagements',
    engagements_desc: '12% more then last month',
    conversions_label: 'Conversions',
    conversions_desc: '30% less then last month',
    my_offering: 'My offering',
    my_store: 'My store',
    subscription_templates: 'Subscription templates',
    customers: 'Customers',
    import: 'Import',
    download: 'Download',
    all: 'All',
    accepts_marketing: 'Accepts marketing',
    prospecting: 'Prospecting',
    cold_lead: 'Cold lead',
    name: 'Name',
    company_name: 'Company name',
    contact_info: 'Contact info:',
    validation_email: "Not a valid email address",
    validation_email_empty: "Email address can not be empty",
    validation_name_empty: "Name can not be empty",
    validation_company_name_empty: "Company name can not be empty",
    validation_use_cases_empty: "Please select a use case.",
    location: 'Location',
    add: 'Add',
    spent: 'Spent',
    workflow: 'Workflow',
    products: 'Products',
    stock: 'Stock',
    sku: 'SKU',
    in_stock: '{stock} in stock',
    skincare: 'Skincare',
    immunity: 'Immunity',
    insomnia: 'Insomnia',
    longevity: 'Longevity',
    exercise: 'Exercise',
    published: 'Published',
    draft: 'Draft',
    orders: 'Orders',
    placed_on: 'Placed on',
    order_summary: 'Order summary',
    customer: 'Customer',
    invoice_id: 'Invoice ID',
    promotion_code: 'Promotion code',
    total_amount: 'Total amount',
    order_status: 'Order status',
    delivered: 'Delivered',
    timeline: 'Timeline',
    order_created: 'Order created',
    order_paid: 'Order paid',
    order_shipped: 'Order shipped',
    order_delivered: 'Order delivered',
    start_tour: 'Start tour',
    feature_tour: "Feature tour",
    feature_ownership: "Feature delivered by:",
    your_business: "Your business",
    previous: "Previous",
    feature_tour_main_dialog_title: "Fluxima feature tour",
    feature_tour_main_dialog_desc1: "You are now looking at the demo of what our project is all about. This admin interface represents what a Saas admin interface combined with our product might look like.",
    feature_tour_main_dialog_desc2: "You can enrich your application with integrations and automation capabilities with no added cost to your users. You customers need not have any additional subscriptions to make it work.",
    feature_tour_main_dialog_desc3: "To help you quickly understand individual pieces of the interface, we have added beacons like the one below throughout the demo.",
    feature_tour_main_dialog_desc4: "These beacons reveal a relevant piece of information upon clicking. You can interact with them at your leisure, or start a brief feature tour. You can always re-start the tour by accessing the speed dial button in the bottom right corner.",
    feature_tour_business_menu_label: "Your business app features",
    feature_tour_business_menu_desc: "This menu represents features, that may appear in business app, SaaS or ecommerce admin user interfaces. We didn't go wild here and only included 4 subpages - a dashboard, a product page, a customers list and an order detail.",
    feature_tour_automation_menu_label: "Fluxima features",
    feature_tour_automation_menu_desc: "You get these features by embedding Fluxima solution in your app. We have named the menu 'Automation' as it best describes the powerful new feature set. It will seamlessly blend into your interface and feel like a natural extension.",
    feature_tour_marketplace_label: "Integration marketplace",
    feature_tour_marketplace_desc: "A curated collection of integrations to other software. CRMs, ERPs, texting apps, payment providers, social media, you name it. For obvious reasons, we have only included a few. Your customers get instant access to all of these, with no coding on your part.",
    feature_tour_connector_detail_label: "Connector configuration",
    feature_tour_connector_detail_desc: "Here, your users configure access to paid or free software they have provisioned outside of your own app. For the most part, authentication and API keys will be sufficient. Active connectors can then be used to create autoamted workflows.",
    feature_tour_new_workflow_step1_label: "Create new automated workflow",
    feature_tour_new_workflow_step1_desc: "Workflows can begin proactively (polling) or reactively (webhook). You can either create a poller to periodically query a resource, or expose a new endpoint where new data or events will be deposited.",
    feature_tour_new_workflow_step2_label: "Configure an entry point",
    feature_tour_new_workflow_step2_desc: "The first step in each workflow will act as a bridge between different systems, APIs, databases and such. In this demo, you can only configure a single entry point, but we plan on having multiple in the real thing.",
    feature_tour_new_workflow_step3_label: "Workflow builder",
    feature_tour_new_workflow_step3_desc: "The best feature of our platform - the workflow builder. Although the demo version is quite rudimentary, it gives you an idea of how your customers can build automations on top of your app features. We have grand plans for the builder - branched workflows, code-like transformations, multiple entry and exit points and many more.",
    feature_tour_prebuilt_flows_label: "Prebuilt workflows",
    feature_tour_prebuilt_flows_desc: "You know your customers the best. By combining common knowledge and usage patterns, you can prepare workflow templates (in an admin interface that unfortunately is not a part of this demo), that you know would of great added value to your users.",
    feature_tour_workflow_executions_label: "Workflow execution history",
    feature_tour_workflow_executions_desc: "All workflows will log their individual executions for easy inspection. Usually only necessary when something goes wrong and you want to determine a root cause, or for auditing purposes. And with this, the tour ends! You can revisit any step as you like.",
    my_app_features: 'My app features',
    my_business_app: 'My business app',
    create_new_workflow: 'Create new workflow',
    prebuilt_workflows: 'Prebuilt workflows',
    send_message: 'Send message',
    retrieve_message: 'Retrieve message',
    send_invoice: 'Send an invoice',
    retrieve_all_recipient_messages: 'Retrieve all recipient messages',
    resend_all_unread_messages: 'Resend unread messages and perform action if no response',
    create_customer: 'Create customer',
    link_debit_card: 'Link debit card',
    charge_a_debit_card: 'Charge a debit card',
    create_customer_and_charge_money: 'Create customer and charge money',
    resend_all_unread_messages_desc: 'Some conversations are important and warrant a response from the queried party, so that you can move forward with a task. ' +
        'With this flow, you can remind the recipient that an answer is required. If no response is given within specified threshold, perform a default action of your choice.',
    perform_default_action: "Perform default action",
    prerelease: "pre-release",
    flows_hero_caption: "Native-like{br}integrations.{br}In your app.",
    flows_hero_desc: "Do not subject yourself to the misery of building native connectors ever again. With Fluxima, it can all be done in no time.",
    flows_app_icons_caption: "Your SaaS at the center of it all",
    flows_app_icons_desc: "All integrations at your customers' fingertips in your product UI. Make it easy for your users to adopt.",
    flows_screenshots_caption: "No more half measures like Zapier or Make.",
    flows_screenshots_desc: "We embed in your app directly. Reclaim the competitive advantage from third party vendors.",
    widgets_hero_caption: "Enhance{br}your UX flows{br}where needed.",
    widgets_hero_desc: "Integration-based UI widgets that blend in your app flows. Your integration pain stops with Fluxima.",
    widgets_app_icons_caption: "Your SaaS at the center of it all",
    widgets_app_icons_desc: "Let your ursers inteface with 3rd party software directly from within your product. Enrichment with no learning curve required.",
    widgets_screenshots_caption: "Data display, data sync, any direction.",
    widgets_screenshots_desc: "We embed in your app directly. Interactive tables, configurable multi-step wizards, one-click controls.",
    composer_hero_caption: "Create,{br}compose,{br}expose APIs.",
    composer_hero_desc: "A powerful visual API composer, drastically simplifing the process of leveraging APIs by your clients.",
    composer_app_icons_caption: "Let others integrate with you",
    composer_app_icons_desc: "Become a seamless part of your customers' existing app ecosytems by rapidly releasing APIs and monetize it while doing so.",
    composer_screenshots_caption: "No code, many APIs, all utility.",
    composer_screenshots_desc: "Architect and design APIs around your core business entities. Enable your clients to easily explore, select and transform responses to fit their needs.",
    sign_up_caption: "Get notified when we launch!",
    sign_up_desc: "Want to become an early adopter and use our beta releases? Step right up.",
    what_are_we: "What are we?",
    what_are_we_desc: "We are a fledgling company looking to bring the power of pre-made integrations and proxy APIs to other businesses. There is a vast ecosystem of potentially " +
        "interconnected workflows out there. We will have built these integrations for you, so that they look and feel as a natural extension part of your product, while you focus " +
        "on what you do best. Do not squander your resources, leave integration pain points to us. We provide your clients with modular extensions, comfortably from within of your own UI. ",
    why_should_you_care: "Why should you care?",
    why_should_you_care_desc: "Your business has taken off and we root for you. Soon, however, you will inevitably run into interfacing with other services to complement your product. " +
        "Your software application caters to a specific vertical or a feature-set that will never exist in a vacuum. A dilemma ensues - which third party connections do you build in-house " +
        "to have the best ROI? Fluxima to the rescue. ",
    use_cases: "Use cases",
    use_cases_desc: "Our integrations will power many distinct usecases. We have prepared demos for the two most obvious ones, with more to come in the future. Demos are best viewed " +
        "on big screens, there are no mobile-sized screen friendly CSS breakpoints yet.",
    captivated_yet: "Captivated yet?",
    captivated_yet_desc: "We welcome early adopters, who would help us steer the project in the right direction by taking part in alfa and beta releases and giving us a much needed feedback. " +
        "At the end of the day, our potential customers are the only thing that matters to us, so we absolutely want to provide as much value as we can. Express interest and have us reach out to you!",
    reach_us_directly: "You can also reach us directly at first@fluxima.io, or +421 904 943 833.",
    interested_in: "I would be interested in knowing more about:",
    thank_you_reaching_out: "Thank you for reaching out!",
    success: 'Success',
    error: 'Error',
    body: 'Body',
    saved_workflows: 'Saved workflows',
    no_saved_workflows: 'No saved workflows',
    nodes_count: 'Nodes: {count}',
    successful_executions_count: 'Successful executions: {count}',
    errors_count: 'Errors: {count}',
    job_executions: 'Job executions',
    execution_duration_seconds: '{duration} seconds',
    execution_status_success: 'Success',
    execution_status_processing: 'Processing',
    execution_status_incomplete: 'Incomplete',
    execution_status_error: 'Error',
    execution_id: 'Execution ID',
    workflow_execution_name: 'Workflow name',
    job_duration: 'Job duration',
    timestamp: 'Timestamp',
    test_runner: 'Test runner',
    builder_controls: 'Builder controls',
    builder_zoom: 'Zoom',
    reset_builder: 'Reset builder',
    activation: 'Activation',
    activate: 'Activate',
    username: 'Username',
    password: 'Password',
    api_key: 'API key',
    endpoint: 'Endpoint',
    settings: 'Settings',
    headers: 'Headers',
    header_name: 'Header name',
    header_value: 'Header value',
    time_interval: 'Time interval in minutes',
    every_minutes: 'Every {interval} minutes',
    default_integration_settings: 'Default settings',
    general: 'General',
    third_party_actions: 'Third party actions',
    actions: 'Actions',
    automations: 'Automations',
    workflows: 'Workflows',
    logs: 'Logs',
    recent_logs: 'Recent logs',
    request_params: 'Request parameters',
    step_foreach: 'This step will be performed for each item received in a response body of the previous step.',
    request_body: 'Request body',
    response_body: 'Response body',
    input_data_shape: 'Input data shape',
    output_data_shape: 'Resulting data shape',
    available_actions: 'Available actions',
    common_action_flows: 'Common action flows',
    expand_nested_objects: 'Expand nested objects',
    results_per_request: 'Results per request',
    integration_implementation_type: 'Integration type',
    integration_implementation_http: 'HTTP',
    integration_implementation_websocket: 'Websocket',
    home: 'Home',
    early_adopter: 'Early adopter',
    source_of_truth: 'Source of truth',
    source_of_truth_desc: 'A single unified proxy-database is a way for companies to consolidate various, customer data, giving ' +
        'them a single source of truth for each customer. Customers have a single ID across sales, marketing, and other team databases, ' +
        'so users can look up all their information under one ID, eliminating dark data (sitting in a data warehouse unused) or it ' +
        'becoming siloed off in several different platforms.',
    integration_marketplace: 'Integration marketplace',
    integration_marketplace_desc: 'An embedded marketplace of ready-made integrations. Your customers will gain access ' +
        'to other software products they have already provisioned, vastly expanding the usefulness of your own app capabilities. ' +
        'You should take great interest in fitting into a new customer\'s existing systems as seamlessly as possible. Not only will it make you ' +
        'more money by increasing the number of users who feel comfortable signing up, but it also improves the experience for those ' +
        'customers when an integration exists.',
    coming_soon: "Demo coming soon...",
    use_case_1: 'Use case 1',
    use_case_1_desc: 'Use case 1',
    use_case_2: 'Use case 2',
    use_case_2_desc: 'Use case 2',
    see_demo: 'See demo',
    book_demo: 'Book demo',
    payments: 'Payments',
    check_ins: 'Check ins',
    shipments: 'Shipments',
    my_business: 'My business',
    business_account: 'Business account',
    company: 'Company',
    company_details: 'Company details',
    payouts: 'Payouts',
    invoices: 'Invoices',
    user_access: 'User access',
    verification: 'Verification',
    personal_account: 'Personal account',
    notifications: 'Notifications',
    automation: 'Automation',
    integrations: 'Integrations',
    pick_action: 'Pick an action',
    pick_start_action: 'Select a start action',
    pick_start_action_desc: 'Start a new workflow by selecting a starting action. We currently support two workflow entry points - a proactive and a reactive approach.',
    pick_start_action_desc2: 'If you want to go with a periodic batch processing of data, configure a poller.',
    pick_start_action_desc3: 'If you want to process data as soon as it becomes available, configure a webhook endpoint, where you can send it.',
    configure_action: 'Configure action',
    select_action: 'Select an action',
    perform_action: 'Perform an action',
    perform_action_desc: 'Select an action to apply to input data from a previous step.',
    previous_step_input: 'Previous step input',
    previous_step_input_desc: 'Data shape from a previous step.',
    current_step_output: 'Current step output',
    current_step_output_desc: 'Resulting data shape that will be used by the next step in the sequence.',
    workflow_name: 'Workflow name',
    workflow_name_empty: 'Workflow name can not be empty',
    run_test: 'Run test',
    save_workflow: 'Save workflow',
    create_snapshot: 'Create snapshot',
    use_real_data: 'Use real data',
    webhooks: 'Webhooks',
    polling: 'Polling',
    api: 'API',
    vendors: 'Vendors',
    guides: 'Guides',
    developer_docs: 'Developer docs',
    changelog: 'Change Log',
    contact_us: "Contact us",
    contact: "Contact",
    close: "Close",
    to_verification: "Verify",
    submit: "Submit",
    create: "Create",
    search: 'Search',
    pay: "Pay",
    post: "Post",
    save: "Save",
    update: "Update",
    reset: "Reset",
    confirm: "Confirm",
    send: 'Send',
    copy: 'Copy',
    continue: "Continue",
    cancel: "Cancel",
    edit: "Edit",
    accept: "Accept",
    or: "or",
    complete: "Complete",
    back: "Back",
    add_more: "Add more",
    plus_sign: "+",
    minus_sign: "-",
    next: "Next",
    upload: "Upload",
    delete: "Delete",
    default: "Default",
    roles: "Roles",
    rows_per_page: "Rows per page",
    all_rows: "All",
    of: "of",
    on: "on",
    yes: "Yes",
    no: "no",
    enabled: "Enabled",
    disabled: "Disabled",
    enable: "Enable",
    disable: "Disable",
    status: "Status",
    active: 'Active',
    inactive: 'Inactive',
    created_by: "Created by",
    on_day: 'on',
    category_communication: 'Communication',
    category_customer_relationship_management: 'Customer Relationship Management',
    category_project_management: 'Project Management',
    category_marketing: 'Marketing',
    category_enterprise_resource_planning: 'Enterprise Resource Planning',
    category_payment_provider: 'Payment Provider',
    app_listing_by_app: 'By app',
    app_listing_by_company: 'By company',
};
