import React, {forwardRef} from "react";
import {Box} from "@mui/material";
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';

const styles = {
    icon: clickable => [
        {
            color: 'green.main',
            zIndex: 1,
            position: 'relative',
        },
        clickable && {
            cursor: 'pointer',
        }
    ],
    beacon: {
        margin: 'auto',
        height: '1.5rem',
        width: '1.5rem',
        '&::before': {
            position: 'absolute',
            height: '1.5rem',
            width: '1.5rem',
            content: '""',
            backgroundColor: 'transparent',
            borderRadius: '50%',
            boxShadow: '0px 0px 2px 2px #10b981',
            animation: 'active 2s infinite linear',
        },
        '@keyframes active': {
            '0%': {
                transform: 'scale(.1)',
                opacity: 1
            },
            '70%': {
                transform: 'scale(2.5)',
                opacity: 0
            },
            '100%': {
                opacity: 0
            }
        }
    },
};

export const Beacon = forwardRef((props, ref) => {

    const {onClick, clickable = true} = props;

    return <Box ref={ref} sx={styles.beacon} onClick={onClick}>
        <HelpRoundedIcon sx={styles.icon(clickable)}/>
    </Box>;
});