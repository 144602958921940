import * as React from 'react';
import {useIntl} from "react-intl";
import {Button} from "@mui/material";
import Box from "@mui/material/Box";

const styles = {
    button: (selected, position) => [
        {
            borderRadius: '0.5rem',
            padding: '8px 20px',
            fontSize: '0.875rem',
            lineHeight: '1.75',
            fontWeight: 'bold',
            color: 'violet.main',
            bgcolor: 'transparent',
            transition: 'color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            border: '1px solid #5048e5',
            minWidth: '120px'
        },
        position === 'first' && {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
        },
        position === 'last' && {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0
        },
        selected && {
            color: 'white',
            bgcolor: 'violet.main',
            '&:hover': {
                bgcolor: 'violet.dark',
            },
        }
    ]
};

export const ChoiceButton = props => {

    const intl = useIntl();
    const {setValue, options, selectedOption} = props;

    const toggleAppListing = buttonValue => () => {
        if (selectedOption !== buttonValue)
            setValue(buttonValue);
    };

    const determineButtonPosition = i => {
        if (i === 0) return 'first';
        if (i === options.length - 1) return 'last';
        return 'middle';
    }

    return <Box>
        {options.map((option, i) =>
            <Button
                onClick={toggleAppListing(option.value)}
                disableRipple
                sx={styles.button(selectedOption === option.value, determineButtonPosition(i))}
                value={option.value}>{intl.formatMessage({id: option.i18n})}
            </Button>)}
    </Box>;
};
