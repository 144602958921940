import * as React from 'react';
import Divider from "@mui/material/Divider";

const styles = {
    drawerDivider: {
        flexShrink: 0,
        borderWidth: '0px 0px thin',
        borderStyle: 'solid',
        borderColor: '#2d3748'
    },
};

export const DrawerDivider = () => {
    return <Divider sx={styles.drawerDivider}/>;
};
