import * as React from 'react';
import {Collapse, Grid, List, ListItem, Paper, Typography} from "@mui/material";
import 'reactflow/dist/style.css';
import {useIntl} from "react-intl";
import Box from "@mui/material/Box";
import {MemberFieldTypes, WorkflowActionTypes} from "../../constants";
import {SelectInput} from "../common/SelectInput";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import {IconFactory} from "./common";

const styles = {
    actionPaper: {
        borderRadius: '0.5rem',
        padding: '1rem',
        cursor: 'pointer',
        boxShadow: 'rgb(0 0 0 / 5%) 0rem 0.25rem 1.6875rem 0.5rem',
        height: '100px'
    },
    actionImageWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '60px',
        borderRadius: '1rem',
        backgroundColor: 'white',
        marginRight: '1rem'
    },
    actionImage: {
        width: 'auto',
        height: 'auto',
        maxHeight: '100%',
        maxWidth: '60px'
    },
    icon: {
        width: '80px',
        height: '80px',
        color: 'green.main',
        marginRight: '1rem'
    },
    caption: {
        fontWeight: 'bold',
        fontSize: '18px',
        marginBottom: '1rem'
    },
    midCaption: {
        fontWeight: 'bold',
        fontSize: '16px',
    },
    midDescription: {
        fontSize: '14px',
        marginBottom: '1rem'
    },
    connectorName: {
        fontWeight: 'bold',
        fontSize: '18px'
    },
    connectorDesc: {
        fontSize: '14px'
    },
    dataShapePaper: {
        boxShadow: 'none',
        borderRadius: '1rem',
        bgcolor: '#f3f4f6',
        padding: '1rem',
    },
    dataShapeCaption: {
        cursor: 'pointer',
        fontSize: '12px',
        fontWeight: '600'
    },
    fieldName: {
        fontSize: '12px',
        fontWeight: '600'
    },
    fieldValue: {
        fontSize: '12px',
    },
    collapse: {
        width: '100%'
    },
    expandIcon: expanded => ([
        {
            cursor: 'pointer',
            color: 'textColorPrimary.main',
            transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        },
        expanded && {
            transform: 'rotate(-180deg)',
        }
    ]),
};

const inputDataShape = {
    customer_id: {
        type: MemberFieldTypes.STRING
    },
    full_name: {
        type: MemberFieldTypes.STRING
    },
    phone_number: {
        type: MemberFieldTypes.STRING
    },
    allowed_countries: {
        type: MemberFieldTypes.ENUMERATION
    },
    address: {
        type: MemberFieldTypes.OBJECT,
        fields: {
            street: {
                type: MemberFieldTypes.STRING
            },
            city: {
                type: MemberFieldTypes.STRING
            },
            country: {
                type: MemberFieldTypes.STRING
            },
        }
    },
    orders: {
        type: MemberFieldTypes.LIST,
        listOf: MemberFieldTypes.OBJECT,
        fields: {
            amount: {
                type: MemberFieldTypes.NUMBER
            },
            order_id: {
                type: MemberFieldTypes.STRING
            },
        }
    },
    last_login: {
        type: MemberFieldTypes.TIMESTAMP
    }
};

export const WorkflowActionConfiguration = props => {

    const {action, selectOptions, selectedOption, setSelectedOption} = props;
    const [expandedInputData, setExpandedInputData] = React.useState(false);
    const [expandedOutputData, setExpandedOutputData] = React.useState(false);
    const intl = useIntl();

    const resolveIcon = () => {
        if (action.workflowActionType === WorkflowActionTypes.INTEGRATION) {
            return <Box sx={styles.actionImageWrapper}>
                <Box component={'img'} src={action.logoPath} sx={styles.actionImage}/>
            </Box>
        } else {
            return IconFactory[action.workflowActionType](styles.icon);
        }
    };

    const renderDataShape = () => <Grid item xs={12}>
        <List>
            {Object.entries(inputDataShape).map(([fieldName, fieldValue]) => <>
                    <ListItem>
                        <Typography sx={styles.fieldName} component={'span'}>
                            {fieldName}
                        </Typography>
                        <Typography sx={styles.fieldValue} component={'span'}>
                            &nbsp;{fieldValue.type}
                            {fieldValue.type === MemberFieldTypes.LIST && ` ${intl.formatMessage({id: 'of'})} ${fieldValue.listOf}s`}
                        </Typography>
                    </ListItem>
                    {[MemberFieldTypes.OBJECT, MemberFieldTypes.LIST].includes(fieldValue.type) &&
                        <ListItem disablePadding>
                            <List style={{padding: '0 16px'}}>
                                {Object.entries(fieldValue.fields).map(([nestedFieldName, nestedFieldValue]) =>
                                    <ListItem>
                                        <Typography sx={styles.fieldName} component={'span'}>
                                            {nestedFieldName}
                                        </Typography>
                                        <Typography sx={styles.fieldValue} component={'span'}>
                                            &nbsp;{nestedFieldValue.type}
                                        </Typography>
                                    </ListItem>)}
                            </List>
                        </ListItem>}
                </>
            )}
        </List>
    </Grid>;

    return <Grid container
                 spacing={2}
                 alignItems="center">
        <Grid item xs={12}>
            <Typography sx={styles.caption}>
                {intl.formatMessage({id: 'configure_action'})}
            </Typography>
        </Grid>
        <Grid item xs={12} container>
            <Grid item>
                {resolveIcon()}
            </Grid>
            <Grid item xs>
                <Typography sx={styles.connectorName}>
                    {action.name}
                </Typography>
                <Typography sx={styles.connectorDesc}>
                    {action.description}
                </Typography>
            </Grid>
        </Grid>
        <Grid item xs={12}>
            <Typography sx={styles.midCaption}>
                {intl.formatMessage({id: 'previous_step_input'})}
            </Typography>
            <Typography sx={styles.midDescription}>
                {intl.formatMessage({id: 'previous_step_input_desc'})}
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Paper sx={styles.dataShapePaper}>
                <Grid container
                      justifyContent="center"
                      alignItems="center">
                    <Grid item xs={12} container justifyContent="center" alignItems="center">
                        <Grid item xs>
                            <Typography sx={styles.dataShapeCaption}
                                        onClick={() => setExpandedInputData(!expandedInputData)}>
                                {intl.formatMessage({id: 'input_data_shape'})}
                            </Typography>
                        </Grid>
                        <Grid item style={{height: '24px'}}>
                            <ExpandMoreRoundedIcon sx={styles.expandIcon(expandedInputData)}/>
                        </Grid>
                    </Grid>
                    <Collapse in={expandedInputData} sx={styles.collapse}>
                        {renderDataShape()}
                    </Collapse>
                </Grid>
            </Paper>
        </Grid>
        <Grid item xs={12}>
            <Typography sx={styles.midCaption}>
                {intl.formatMessage({id: 'perform_action'})}
            </Typography>
            <Typography sx={styles.midDescription}>
                {intl.formatMessage({id: 'perform_action_desc'})}
            </Typography>
            <SelectInput id={'workflow_action_configuration'}
                         label={'select_action'}
                         options={selectOptions}
                         selectedOption={selectedOption}
                         setSelectedOption={setSelectedOption}/>
        </Grid>
        <Grid item xs={12}>
            <Typography sx={styles.midCaption}>
                {intl.formatMessage({id: 'current_step_output'})}
            </Typography>
            <Typography sx={styles.midDescription}>
                {intl.formatMessage({id: 'current_step_output_desc'})}
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Paper sx={styles.dataShapePaper}>
                <Grid container
                      justifyContent="center"
                      alignItems="center">
                    <Grid item xs={12} container justifyContent="center" alignItems="center">
                        <Grid item xs>
                            <Typography sx={styles.dataShapeCaption}
                                        onClick={() => setExpandedOutputData(!expandedOutputData)}>
                                {intl.formatMessage({id: 'output_data_shape'})}
                            </Typography>
                        </Grid>
                        <Grid item style={{height: '24px'}}>
                            <ExpandMoreRoundedIcon sx={styles.expandIcon(expandedInputData)}/>
                        </Grid>
                    </Grid>
                    <Collapse in={expandedOutputData} sx={styles.collapse}>
                        {renderDataShape()}
                    </Collapse>
                </Grid>
            </Paper>
        </Grid>
    </Grid>;
};