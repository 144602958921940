export const AuthenticationProvider = {
    GOOGLE: 'GOOGLE',
    FACEBOOK: 'FACEBOOK',
    EMAIL_PASSWORD: 'EMAIL_PASSWORD'
};

export const SubscriptionType = {
    ACCESS: 'ACCESS',
    REPLENISHMENT: 'REPLENISHMENT',
    CURATION: 'CURATION'
};

export const Locale = {
    SK: 'sk',
    CZ: 'cz',
    EN: 'en'
};

export const Currency = {
    EUR: 'eur',
    CZK: 'czk',
    USD: 'usd',
    PLN: 'pln'
};

export const IntegrationCategory = {
    COMMUNICATION: 'communication',
    EMAIL_PROVIDER: 'email_provider',
    MARKETING: 'marketing',
    PAYMENT_PROVIDER: 'payment_provider',
    BANK: 'bank',
    CUSTOMER_RELATIONSHIP_MANAGEMENT: 'customer_relationship_management',
    ENTERPRISE_RESOURCE_PLANNING: 'enterprise_resource_planning',
    DATA_STORAGE: 'data_storage',
    FILE_STORAGE: 'file_storage',
    ECOMMERCE: 'ecommerce',
    IDENTITY_MANAGEMENT: 'identity_management',
    PROJECT_MANAGEMENT: 'project_management',
    INVENTORY_MANAGEMENT: 'inventory_management',
    CUSTOMER_SUPPORT: 'customer_support',
    ACCOUNTING: 'accounting'
};

export const NotificationType = {
    SUCCESS: "SUCCESS",
    ERROR: "ERROR",
    INFO: "INFO",
    WARNING: "WARNING"
};

export const DemoType = {
    FLOWS: "flows",
    WIDGETS: "widgets",
    COMPOSER: "composer",
};

export const AppListing = {
    BY_APP: 'by_app',
    BY_COMPANY: 'by_company',
};

export const MemberFieldTypes = {
    STRING: 'string',
    BOOLEAN: 'boolean',
    NUMBER: 'number',
    ENUMERATION: 'enumeration',
    OBJECT: 'object',
    LIST: 'list',
    TIMESTAMP: 'timestamp',
};

export const WorkflowActionTypes = {
    POLLING: 'polling',
    WEBHOOK: 'webhook',
    TRANSFORM: 'transform',
    FILTER: 'filter',
    MERGE: 'merge',
    SPLIT: 'split',
    FLATTEN: 'flatten',
    GROUP: 'group',
    SORT: 'sort',
};

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const urlRegex = /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/;
export const wwwDomainRegex = /^([a-zA-Z0-9_-]+\.)+[a-zA-Z]+$/i;
export const separatorRegex = /[,;\s]+/;
export const skPhoneNumberRegex = /^(\+?421|0)([0-9]){9}$/;
export const alphanumericRegex = /^[a-z0-9]+$/i;
export const API_URL = process.env.NODE_ENV === 'production' ? `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PREFIX}` : process.env.REACT_APP_API_PREFIX;
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
