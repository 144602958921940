import * as React from 'react';
import 'reactflow/dist/style.css';
import {useDispatch, useSelector} from "react-redux";
import {
    addNewNode,
    getNewWorkflowEdges,
    getNewWorkflowNodes,
    getWorkflowState, initBuilderState,
    reset, setWorkflowActive,
    setWorkflowName
} from "../../reducers/newWorkflow";
import {WorkflowBuilder} from "../automation/WorkflowBuilder";
import {ConfigureWorkflowStart} from "./ConfigureWorkflowStart";
import {saveWorkflow} from "../../reducers/savedWorkflows";

export const NewWorkflow = () => {

    const dispatch = useDispatch();
    const nodes = useSelector(getNewWorkflowNodes);
    const edges = useSelector(getNewWorkflowEdges);
    const completeState = useSelector(getWorkflowState);

    const resetBuilder = () => {
        dispatch(reset());
    };

    const saveWorkflowHandler = () => {
        dispatch(saveWorkflow(completeState));
        resetBuilder();
    };

    const handleNameChange = event => {
        dispatch(setWorkflowName(event.target.value));
    };

    const setActive = event => {
        dispatch(setWorkflowActive(event.target.checked));
    };

    const addNewNodeHandler = (parentId, onLeafNodeClick) => (node, nodeAction) => {
        dispatch(addNewNode({parentId, node, nodeAction, onLeafNodeClick}));
    };

    const onInit = (builderWidth, onLeafNodeClick) => {
        dispatch(initBuilderState({builderWidth, onLeafNodeClick}));
    };

    if (!nodes.length)
        return <ConfigureWorkflowStart/>;

    return <WorkflowBuilder nodes={nodes}
                            edges={edges}
                            onInit={onInit}
                            addNewNodeHandler={addNewNodeHandler}
                            saveWorkflowHandler={saveWorkflowHandler}
                            active={completeState.active}
                            setActive={setActive}
                            workflowName={completeState.name}
                            setWorkflowName={handleNameChange}
                            resetBuilder={resetBuilder}/>;
};