import * as React from 'react';
import {useIntl} from "react-intl";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";

const styles = {
    input: {
        borderRadius: '8px',
    },
}

export const SelectInput = props => {

    const intl = useIntl();
    const {id, options, selectedOption, setSelectedOption, label} = props;

    const localizedOptions = options.map(option => ({
        value: option.value,
        label: intl.formatMessage({id: option.label})
    }));

    const handleChange = event => {
        setSelectedOption(options.find(o => o.value === event.target.value));
    };

    return <FormControl size="small" fullWidth>
        <InputLabel id={id}>{intl.formatMessage({id: label})}</InputLabel>
        <Select
            sx={styles.input}
            labelId={id}
            id={`${id}_select`}
            value={selectedOption.value}
            label={intl.formatMessage({id: label})}
            onChange={handleChange}>
            {localizedOptions.map(o => <MenuItem value={o.value}>{o.label}</MenuItem>)}
        </Select>
    </FormControl>;
};