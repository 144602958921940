import * as React from 'react';
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography} from "@mui/material";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {hideMainModal, isMainModalShown, setActiveStep} from "../../reducers/featureTour";
import {RegularButton} from "../common/RegularButton";
import {Beacon} from "./Beacon";

const styles = {
    beaconWrapper: {
        padding: '1rem',
        textAlign: 'center'
    },
    textHighlight: {
        fontWeight: 600
    },
    modal: {
        '& .MuiDialog-paper': {
            boxShadow: 'rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px',
            borderRadius: '1rem',
            padding: '0.5rem'
        },
    },
};

export const MainModal = () => {

    const intl = useIntl();
    const dispatch = useDispatch();
    const open = useSelector(isMainModalShown);

    const handleClose = () => {
        dispatch(hideMainModal());
    };

    const startTour = () => {
        dispatch(hideMainModal());
        dispatch(setActiveStep('businessMenu'))
    };

    return <Dialog
        maxWidth={'md'}
        fullWidth
        open={open}
        sx={styles.modal}
        onClose={handleClose}>
        <DialogTitle>
            {intl.formatMessage({id: 'feature_tour_main_dialog_title'})}
        </DialogTitle>
        <DialogContent>
            <Grid container
                  spacing={2}
                  alignItems="center">
                <Grid item xs={12}>
                    <Typography>
                        {intl.formatMessage({id: 'feature_tour_main_dialog_desc1'})}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography sx={styles.textHighlight}>
                        {intl.formatMessage({id: 'feature_tour_main_dialog_desc2'})}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        {intl.formatMessage({id: 'feature_tour_main_dialog_desc3'})}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={styles.beaconWrapper}>
                        <Beacon clickable={false}/>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        {intl.formatMessage({id: 'feature_tour_main_dialog_desc4'})}
                    </Typography>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <RegularButton text={'close'} variant={'text'} onClick={handleClose}/>
            <RegularButton text={'start_tour'} onClick={startTour}/>
        </DialogActions>
    </Dialog>;
};