import React, {useEffect, useState} from 'react';
import {useIntl} from "react-intl";
import {Box, Container, Grid, Typography} from "@mui/material";
import {usePrevious} from "../../hooks/usePrevious";
import {useViewportDimensions} from "../../hooks/useViewportDimensions";
import {useSelector} from "react-redux";
import {demoTypeSelector} from "../../reducers/demoType";

const styles = {
    container: {
        width: '100%',
        paddingTop: {xs: '100px'},
        paddingBottom: {xs: '50px', sm: '100px'},
    },
    caption: animate => [
        {
            fontSize: {xs: '40px', sm: '55px', md: '55px', lg: '60px'},
            fontWeight: 500,
            color: 'textColorPrimaryDarkTheme.main',
            textAlign: 'center',
            textFillColor: 'transparent',
            background: 'radial-gradient(45% 100% at 50% 50%,#fff 30%,hsla(0,0%,100%,.35) 100%)',
            backgroundClip: 'text',
            transition: 'transform 0.7s ease 0s, opacity 0.7s ease 0s',
            opacity: 0,
            transform: 'translate3d(0px, 100px, 0px)',
            marginBottom: {xs: '20px', sm: '20px'},
        },
        animate && {
            opacity: 1,
            transform: 'translate3d(0px, 0px, 0px)'
        }
    ],
    desc: animate => [
        {
            fontSize: {xs: '16px', sm: '20px', md: '22px', lg: '24px'},
            marginBottom: '24px',
            color: 'textColorPrimaryDarkTheme.main',
            textAlign: 'center',
            transition: 'transform 0.7s ease 0s, opacity 0.7s ease 0s',
            opacity: 0,
            transform: 'translate3d(0px, 100px, 0px)',
        },
        animate && {
            opacity: 1,
            transform: 'translate3d(0px, 0px, 0px)'
        }
    ],
    iconsContainer: {
        overflow: 'hidden',
        position: 'relative',
    },
    appsImage: {
        width: {xs: '600px', sm: '800px', md: '900px', lg: '1000px'},
        height: 'auto',
        position: 'relative',
        marginLeft: '50%',
        transform: 'translateX(-50%)'
    },
    gradientLeft: {
        zIndex: 2,
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        height: '100%',
        width: {xs: '50px', sm: '100px', md: '150px', lg: '250px', xl: '250px'},
        backgroundImage: 'linear-gradient(270deg, #29292900, #292929B6 33%, #292929D8 67%, #292929)'
    },
    gradientRight: {
        zIndex: 2,
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        height: '100%',
        width: {xs: '50px', sm: '100px', md: '150px', lg: '250px', xl: '250px'},
        backgroundImage: 'linear-gradient(90deg, #29292900, #292929B6 33%, #292929D8 67%, #292929)'
    },
};

export const AppIconsSection = () => {

    const intl = useIntl();
    const [animateCaption, setAnimateCaption] = useState(false);
    const [animateDesc, setAnimateDesc] = useState(false);
    const [animate, setAnimate] = useState(false);
    const [scrollListener, setScrollListener] = useState(() => () => void 0);
    const previousListener = usePrevious(scrollListener);
    const [scrollTarget, setScrollTarget] = useState(undefined);
    const {viewportHeight} = useViewportDimensions();
    const demoType = useSelector(demoTypeSelector);

    useEffect(() => {
        window.removeEventListener('scroll', previousListener);
        window.addEventListener('scroll', scrollListener);
        return () => window.removeEventListener('scroll', scrollListener);
    }, [scrollListener]);

    useEffect(() => {
        if (!scrollTarget)
            return;

        const listener = () => {
            const {y} = scrollTarget.getBoundingClientRect();
            if (viewportHeight >= y + 100) {
                setAnimate(true);
            }
        };
        listener();

        setScrollListener(() => listener);
    }, [scrollTarget, viewportHeight]);

    useEffect(() => {
        if (animate) {
            setTimeout(() => setAnimateCaption(true), 200);
            setTimeout(() => setAnimateDesc(true), 600);
        }
    }, [animate]);

    return <Box sx={styles.container}>
        <Container maxWidth="lg">
            <Grid container
                  justifyContent="center"
                  alignItems="start"
                  spacing={2}>
                <Grid item xs={12} sx={styles.iconsContainer}>
                    <Box sx={styles.gradientLeft}/>
                    <Box sx={styles.gradientRight}/>
                    <Box component="img" src={'/fluxima_apps.png'} sx={styles.appsImage}/>
                </Grid>
                <Grid item xs={12} ref={node => {
                    if (node) {
                        setScrollTarget(node);
                    }
                }}>
                    <Typography sx={styles.caption(animateCaption)}>
                        {intl.formatMessage({id: `${demoType}_app_icons_caption`}, {br: <br/>})}
                    </Typography>
                    <Typography sx={styles.desc(animateDesc)}>
                        {intl.formatMessage({id: `${demoType}_app_icons_desc`})}
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    </Box>;
};