import * as React from 'react';
import {useIntl} from "react-intl";
import {InputBase} from "@mui/material";
import Box from "@mui/material/Box";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const styles = {
    search: width => [{
        display: 'flex',
        width: width,
        borderRadius: '0.5rem',
        padding: '0 5px',
        bgcolor: 'rgba(130, 141, 248, 0.15)',
        '&:hover': {
            bgcolor: 'rgba(130, 141, 248, 0.25)',
        },
    }],
    iconWrapper: {
        padding: '8px',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    input: {
        flexGrow: 1
    },
    cancelIcon: {
        cursor: 'pointer'
    },
}

export const Search = props => {

    const intl = useIntl();
    const {
        value,
        setValue,
        width = '500px'
    } = props;

    return <Box sx={styles.search(width)}>
        <Box sx={styles.iconWrapper}>
            <SearchRoundedIcon/>
        </Box>
        <InputBase sx={styles.input} placeholder={intl.formatMessage({id: 'search'}) + '...'} value={value} onChange={e => setValue(e.target.value)}/>
        {value !== '' && <Box sx={styles.iconWrapper}>
            <CloseRoundedIcon onClick={() => setValue('')} sx={styles.cancelIcon}/>
        </Box>}
    </Box>;
};

