import * as React from 'react';
import {Grid, Paper, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {useNavigate} from "react-router-dom";
import {CommonDivider} from "../common/CommonDivider";
import {useIntl} from "react-intl";

const styles = {
    paper: {
        position: 'relative',
        borderRadius: '1rem',
        cursor: 'pointer',
        boxShadow: 'rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px'
    },
    headerWrapper: {
        padding: '1rem',
        height: '250px',
    },
    contentWrapper: {
        padding: '1rem'
    },
    imageWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '60px',
        borderRadius: '1rem',
        backgroundColor: 'white',
    },
    image: {
        width: 'auto',
        height: 'auto',
        maxHeight: '100%',
        maxWidth: '150px'
    },
    connectorActivated: {
        position: 'absolute',
        right: 16,
        top: 16,
        color: 'green.main'
    },
    connectorName: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '16px'
    },
    connectorDesc: {
        textAlign: 'center',
        fontSize: '14px'
    },
    connectorStatusText: {
        color: 'textColorPrimary.main',
        fontSize: '14px'
    },
    connectorStatus: activated => [
        {
            color: 'orangered',
            fontSize: '14px',
            fontWeight: '500'
        },
        activated && {
            color: 'green.dark',
        }
    ],
};

export const ConnectorCard = props => {

    const {connector} = props;
    const navigate = useNavigate();
    const intl = useIntl();

    const trimDescription = () => {
        if (connector.description.length >= 150)
            return connector.description.substring(0, 150) + '...';
        return connector.description;
    }

    return <Paper sx={styles.paper} onClick={() => navigate(`/usecase1/integrations/${connector.id}`)}>
        {connector.activated && <CheckCircleIcon sx={styles.connectorActivated}/>}
        <Grid container
              sx={styles.headerWrapper}
              justifyContent="center"
              alignItems="center"
              spacing={1}>
            <Grid item xs={12}>
                <Box sx={styles.imageWrapper}>
                    <Box component={'img'} src={connector.logoPath} sx={styles.image}/>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Typography sx={styles.connectorName}>
                    {connector.name}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography sx={styles.connectorDesc}>
                    {trimDescription()}
                </Typography>
            </Grid>
            <Grid item xs/>
        </Grid>
        <CommonDivider/>
        <Grid container
              sx={styles.contentWrapper}
              justifyContent="center"
              alignItems="center"
              spacing={1}>
            <Grid item xs={12}>
                <Typography component={'span'} sx={styles.connectorStatusText}>
                    {intl.formatMessage({id: 'status'})}:&nbsp;
                </Typography>
                <Typography component={'span'} sx={styles.connectorStatus(connector.activated)}>
                    {intl.formatMessage({id: connector.activated ? 'active' : 'inactive'}).toUpperCase()}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography component={'span'} sx={styles.connectorStatusText}>
                    {intl.formatMessage({id: 'available_actions'})}:&nbsp;
                </Typography>
                <Typography component={'span'} sx={styles.connectorStatusText}>
                    {connector.actions ? connector.actions.length : 0}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography component={'span'} sx={styles.connectorStatusText}>
                    {intl.formatMessage({id: 'common_action_flows'})}:&nbsp;
                </Typography>
                <Typography component={'span'} sx={styles.connectorStatusText}>
                    {connector.commonActionFlows ? connector.commonActionFlows.length : 0}
                </Typography>
            </Grid>
        </Grid>
    </Paper>;
};