import {createSlice} from '@reduxjs/toolkit';
import {DemoType} from "../constants";

export const demoTypeSlice = createSlice({
    name: 'demoType', initialState: {
        value: DemoType.FLOWS
    }, reducers: {
        changeDemoType: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const demoTypeSelector = state => state.demoType.value;

export const {changeDemoType} = demoTypeSlice.actions;
export default demoTypeSlice.reducer;