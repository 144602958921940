import * as React from 'react';
import {Box, Grid, Typography} from "@mui/material";
import 'reactflow/dist/style.css';
import {IconFactory, NODE_HEIGHT, NODE_WIDTH} from "../common";
import {useIntl} from "react-intl";
import {Handle} from "reactflow";

const styles = {
    node: {
        border: '1px solid #5048e5',
        borderRadius: '0.5rem',
        backgroundColor: 'white',
        boxShadow: '0 4px 12px rgb(0 0 0 / 8%)',
        padding: '12px',
        width: `${NODE_WIDTH}px`,
        height: `${NODE_HEIGHT}px`,
        fontWeight: 700,
        cursor: 'pointer',
        lineHeight: 1.2,
        '&:hover': {
            background: 'radial-gradient(circle at 18.7% 37.8%,#fcfcfc 0,#eff1f3 90%)'
        },
        '.react-flow__handle': {
            width: '8px',
            height: '10px',
            borderRadius: '3px',
        }
    },
    icon: {
        width: '30px',
        height: '30px',
        color: 'green.main',
    },
    connectorName: {
        fontWeight: 'bold',
        fontSize: '14px'
    },
    connectorDesc: {
        fontSize: '12px'
    },
};

export const WorkflowStartNode = props => {

    const {data} = props;
    const intl = useIntl();
    const {
        name,
        timeInterval,
        workflowActionType
    } = data;

    return <>
        <Box sx={styles.node}>
            <Grid container
                  spacing={1}
                  justifyContent="center"
                  alignItems="center">
                <Grid item>
                    {IconFactory[workflowActionType](styles.icon)}
                </Grid>
                <Grid item xs>
                    <Typography sx={styles.connectorName}>
                        {name}
                    </Typography>
                    <Typography sx={styles.connectorDesc}>
                        {intl.formatMessage({id: 'every_minutes'}, {interval: timeInterval})}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
        <Handle type="source" position={'bottom'} style={{visibility: 'hidden'}}/>
    </>;
};