import * as React from 'react';
import {Box, Container, Grid, Paper, Typography} from "@mui/material";
import {useIntl} from "react-intl";
import {CommonDivider} from "../common/CommonDivider";
import {RegularButton} from "../common/RegularButton";
import TodayRoundedIcon from '@mui/icons-material/TodayRounded';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import {Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator} from "@mui/lab";
import AddShoppingCartRoundedIcon from '@mui/icons-material/AddShoppingCartRounded';
import EuroRoundedIcon from '@mui/icons-material/EuroRounded';
import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded';
import WhereToVoteRoundedIcon from '@mui/icons-material/WhereToVoteRounded';

const styles = {
    container: {
        paddingTop: '5rem'
    },
    backWrapper: {
        display: 'flex',
        cursor: 'pointer',
        marginBottom: '1rem'
    },
    backText: {
        marginLeft: '8px',
        color: 'textColorPrimary.main',
        fontWeight: 500,
        fontSize: '14px'
    },
    mainCaption: {
        fontSize: '2rem',
        fontWeight: 700,
        lineHeight: 1.375,
        color: 'textColorPrimary.main'
    },
    mainCaptionSubText: {
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: 1.57,
        color: '#65748b',
        marginRight: '8px'
    },
    calendarIcon: {
        color: '#65748b',
        marginRight: '8px'
    },
    subText: {
        flex: '1 1 0',
        padding: '1rem',
        fontSize: '26px'
    },
    paper: {
        boxShadow: 'rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px',
        borderRadius: '1rem',
        marginTop: '1.5rem'
    },
    sectionWrapper: {
        padding: '1.5rem'
    },
    sectionCaption: {
        fontSize: '1rem',
        fontWeight: 600,
        lineHeight: 1.375,
        color: 'textColorPrimary.main'
    },
    rowCaption: {
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: 1.57,
        color: 'textColorPrimary.main'
    },
    rowText: {
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: 1.57,
        color: '#65748b',
    },
    timeline: {
        padding: 0,
        margin: 0
    },
    timelineConnector: {
        minHeight: '30px'
    },
    timelineItem: {
        '&::before': {
            content: 'none',
        }
    },
    timelineCaption: {
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: 1.57,
        color: 'textColorPrimary.main'
    },
    timelineDot: {
        width: '40px',
        height: '40px',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: 'green.light'
    },
};

export const OrderDetail = () => {

    const intl = useIntl();

    const resolveDate = minusDays => {
        const date = new Date();
        date.setDate(date.getDate() - minusDays);
        return date.toLocaleDateString('en-GB');
    }

    return <Container maxWidth="lg" sx={styles.container}>
        <Box sx={styles.backWrapper}>
            <ArrowBackRoundedIcon fontSize={'small'}/>
            <Typography sx={styles.backText} component={'span'}>
                {intl.formatMessage({id: 'orders'})}
            </Typography>
        </Box>
        <Grid container
              spacing={2}
              alignItems="center">
            <Grid item xs>
                <Typography sx={styles.mainCaption}>
                    ORDER-123456
                </Typography>
                <Box sx={{display: 'flex', alignItems: 'center', marginTop: '8px'}}>
                    <Typography sx={styles.mainCaptionSubText}>
                        {intl.formatMessage({id: 'placed_on'})}
                    </Typography>
                    <TodayRoundedIcon sx={styles.calendarIcon}/>
                    <Typography sx={styles.mainCaptionSubText}>
                        23/12/2022 09:30
                    </Typography>
                </Box>
            </Grid>
            <Grid item>
                <RegularButton endIcon={<OpenInNewRoundedIcon/>} text={'edit'} variant={'outlined'}
                               customStyles={{marginRight: '10px'}}/>
                <RegularButton text={'invoice'}/>
            </Grid>
        </Grid>
        <Paper sx={styles.paper}>
            <Box sx={styles.sectionWrapper}>
                <Typography sx={styles.sectionCaption}>
                    {intl.formatMessage({id: 'order_summary'})}
                </Typography>
            </Box>
            <CommonDivider/>
            <Box sx={styles.sectionWrapper}>
                <Grid container
                      spacing={2}
                      justifyContent="start">
                    <Grid item xs={2}>
                        <Typography sx={styles.rowCaption}>
                            {intl.formatMessage({id: 'customer'})}
                        </Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography sx={styles.rowText}>
                            John Smith
                        </Typography>
                        <Typography sx={styles.rowText}>
                            Smith street, no. 140
                        </Typography>
                        <Typography sx={styles.rowText}>
                            Los Angeles
                        </Typography>
                        <Typography sx={styles.rowText}>
                            USA
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <CommonDivider/>
            <Box sx={styles.sectionWrapper}>
                <Grid container
                      spacing={2}
                      justifyContent="start">
                    <Grid item xs={2}>
                        <Typography sx={styles.rowCaption}>
                            {intl.formatMessage({id: 'invoice_id'})}
                        </Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography sx={styles.rowText}>
                            5ecb8a6879877087d4aa2690
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <CommonDivider/>
            <Box sx={styles.sectionWrapper}>
                <Grid container
                      spacing={2}
                      justifyContent="start">
                    <Grid item xs={2}>
                        <Typography sx={styles.rowCaption}>
                            {intl.formatMessage({id: 'promotion_code'})}
                        </Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography sx={styles.rowText}>
                            GET10OFF
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <CommonDivider/>
            <Box sx={styles.sectionWrapper}>
                <Grid container
                      spacing={2}
                      justifyContent="start">
                    <Grid item xs={2}>
                        <Typography sx={styles.rowCaption}>
                            {intl.formatMessage({id: 'total_amount'})}
                        </Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography sx={styles.rowText}>
                            €500
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <CommonDivider/>
            <Box sx={styles.sectionWrapper}>
                <Grid container
                      spacing={2}
                      justifyContent="start">
                    <Grid item xs={2}>
                        <Typography sx={styles.rowCaption}>
                            {intl.formatMessage({id: 'order_status'})}
                        </Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography sx={styles.rowText}>
                            {intl.formatMessage({id: 'delivered'})}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <CommonDivider/>
            <Box sx={styles.sectionWrapper}>
                <Grid container
                      spacing={2}
                      justifyContent="start">
                    <Grid item xs={2}>
                        <Typography sx={styles.rowCaption}>
                            {intl.formatMessage({id: 'timeline'})}
                        </Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <Timeline sx={styles.timeline}>
                            <TimelineItem sx={styles.timelineItem}>
                                <TimelineSeparator>
                                    <TimelineDot sx={styles.timelineDot}>
                                        <AddShoppingCartRoundedIcon/>
                                    </TimelineDot>
                                    <TimelineConnector sx={styles.timelineConnector}/>
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Typography sx={styles.timelineCaption}>
                                        {intl.formatMessage({id: 'order_created'})}
                                    </Typography>
                                    <Typography sx={styles.rowText}>
                                        {resolveDate(4)}
                                    </Typography>
                                </TimelineContent>
                            </TimelineItem>
                            <TimelineItem sx={styles.timelineItem}>
                                <TimelineSeparator>
                                    <TimelineDot sx={styles.timelineDot}>
                                        <EuroRoundedIcon/>
                                    </TimelineDot>
                                    <TimelineConnector sx={styles.timelineConnector}/>
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Typography sx={styles.timelineCaption}>
                                        {intl.formatMessage({id: 'order_paid'})}
                                    </Typography>
                                    <Typography sx={styles.rowText}>
                                        {resolveDate(3)}
                                    </Typography>
                                </TimelineContent>
                            </TimelineItem>
                            <TimelineItem sx={styles.timelineItem}>
                                <TimelineSeparator>
                                    <TimelineDot sx={styles.timelineDot}>
                                        <LocalShippingRoundedIcon/>
                                    </TimelineDot>
                                    <TimelineConnector sx={styles.timelineConnector}/>
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Typography sx={styles.timelineCaption}>
                                        {intl.formatMessage({id: 'order_shipped'})}
                                    </Typography>
                                    <Typography sx={styles.rowText}>
                                        {resolveDate(2)}
                                    </Typography>
                                </TimelineContent>
                            </TimelineItem>
                            <TimelineItem sx={styles.timelineItem}>
                                <TimelineSeparator>
                                    <TimelineDot sx={styles.timelineDot}>
                                        <WhereToVoteRoundedIcon/>
                                    </TimelineDot>
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Typography sx={styles.timelineCaption}>
                                        {intl.formatMessage({id: 'order_delivered'})}
                                    </Typography>
                                    <Typography sx={styles.rowText}>
                                        {resolveDate(0)}
                                    </Typography>
                                </TimelineContent>
                            </TimelineItem>
                        </Timeline>
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    </Container>;
};