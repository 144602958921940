import * as React from 'react';
import {useState} from 'react';
import {WorkflowSelectStartAction} from "./WorkflowSelectStartAction";
import {WorkflowSelectStartConfiguration} from "./WorkflowSelectStartConfiguration";
import {useDispatch} from "react-redux";
import {initNodes} from "../../reducers/newWorkflow";

export const ConfigureWorkflowStart = () => {

    const [index, setIndex] = useState(0);
    const [selectedAction, setSelectedAction] = useState({});
    const dispatch = useDispatch();

    const actionSelect = action => () => {
        setIndex(1);
        setSelectedAction(action);
    };

    const back = () => setIndex(0);

    const onSubmit = nodeConfig => {
        dispatch(initNodes({node: selectedAction, nodeConfig}));
        setIndex(0);
        setSelectedAction({});
    }

    return <>
        {index === 0 && <WorkflowSelectStartAction onClick={actionSelect}/>}
        {index === 1 && <WorkflowSelectStartConfiguration action={selectedAction} back={back} onSubmit={onSubmit}/>}
    </>;
};