import {createSlice} from '@reduxjs/toolkit'

const now = new Date();
const formattedDate = () => `${now.toLocaleDateString('en-GB')} ${now.toLocaleTimeString('en-GB')}`;

export const workflowExecutionsSlice = createSlice({
    name: 'workflowExecutions',
    initialState: {
        data: [
            {
                executionId: '6ed2a7ddceca93f',
                name: "Track new customers",
                status: 'processing',
                duration: 15,
                timestamp: formattedDate()
            },
            {
                executionId: 'd060b498a8ed097',
                name: "Export leads to Hubspot",
                status: 'success',
                duration: 5,
                timestamp: formattedDate()
            },
            {
                executionId: 'd86ead6ca89bcd9',
                name: "Determine the best shipping carrier",
                status: 'incomplete',
                duration: 20,
                timestamp: formattedDate()
            },
            {
                executionId: '7ebddcfe20cc43f',
                name: "Track new customers",
                status: 'success',
                duration: 15,
                timestamp: formattedDate()
            },
            {
                executionId: '0e20a07c4dd1fd4',
                name: "Export leads to Hubspot",
                status: 'success',
                duration: 5,
                timestamp: formattedDate()
            },
            {
                executionId: '042fed0436836a4',
                name: "Determine the best shipping carrier",
                status: 'success',
                duration: 20,
                timestamp: formattedDate()
            },
            {
                executionId: '02d097efbc41e63',
                name: "Track new customers",
                status: 'success',
                duration: 15,
                timestamp: formattedDate()
            },
            {
                executionId: '4aaee2c0ef64b5f',
                name: "Export leads to Hubspot",
                status: 'error',
                duration: 5,
                timestamp: formattedDate()
            },
            {
                executionId: 'fbc41e63b237449',
                name: "Determine the best shipping carrier",
                status: 'success',
                duration: 20,
                timestamp: formattedDate()
            },
        ]
    }
});

export const getWorkflowExecutions = state => state.workflowExecutions.data;

export default workflowExecutionsSlice.reducer;