import * as React from 'react';
import {useState} from 'react';
import {Box, Grid, Paper, Switch, Typography} from "@mui/material";
import 'reactflow/dist/style.css';
import {useIntl} from "react-intl";
import {TextInput} from "../common/TextInput";
import {RegularButton} from "../common/RegularButton";
import {CommonDivider} from "../common/CommonDivider";
import {useReactFlow} from "reactflow";
import {BeaconPopup} from "../featureTour/BeaconPopup";

const styles = {
    builderWrapper: {
        height: '100%',
        border: '1px dashed #bbb',
        borderRadius: '8px'
    },
    paper: {
        width: '280px',
        padding: '1rem',
        position: 'relative',
        borderRadius: '1rem',
        boxShadow: 'rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px',
    },
    caption: {
        fontWeight: 'bold',
        fontSize: '16px',
        flex: '1 1 0',
    },
    subCaption: {
        fontSize: '14px',
    },
    flexWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
};

export const WorkflowBuilderControls = props => {

    const {saveWorkflowHandler, resetBuilder, workflowName, setWorkflowName, active, setActive} = props;
    const intl = useIntl();
    const reactFlowInstance = useReactFlow();
    const [workflowNameError, setWorkflowNameError] = useState(false);

    const onReset = () => {
        resetBuilder();
        setWorkflowNameError(false);
    };

    const saveWorkflow = () => {
        if (workflowName === '') {
            setWorkflowNameError('workflow_name_empty');
            return;
        }
        saveWorkflowHandler();
        onReset();
    };

    return <Paper sx={styles.paper}>
        <Grid container
              spacing={2}
              alignItems="center">
            <Grid item xs={12}>
                <Box sx={styles.flexWrapper}>
                    <Typography sx={styles.caption}>
                        {intl.formatMessage({id: 'workflow_name'})}
                    </Typography>
                    <BeaconPopup placement={'right-start'}
                                 arrowPlacement={'right'}
                                 label={'feature_tour_new_workflow_step3_label'}
                                 text={'feature_tour_new_workflow_step3_desc'}
                                 current={{stepName: 'newWorkflowStep3', path: '/workflow/new'}}
                                 next={{stepName: 'prebuiltFlows', path: '/workflow/prebuilt'}}
                                 onNext={onReset}
                                 previous={{stepName: 'newWorkflowStep1', path: '/workflow/new'}}
                                 onPrevious={onReset}
                    />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <TextInput label={'workflow_name'} onChange={setWorkflowName} value={workflowName}
                           error={workflowNameError}/>
            </Grid>
            <Grid item xs={12} container spacing={1} alignItems="center">
                <Grid item xs={6}>
                    <Typography sx={styles.subCaption}>
                        {intl.formatMessage({id: 'active'})}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Switch checked={active} onChange={setActive}/>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <RegularButton text={'save_workflow'} onClick={saveWorkflow} fullWidth/>
            </Grid>
            <Grid item xs={12}>
                <RegularButton text={'create_snapshot'} onClick={() => {
                }} fullWidth/>
            </Grid>
            <Grid item xs={12}>
                <CommonDivider/>
            </Grid>
            <Grid item xs={12}>
                <Typography sx={styles.caption}>
                    {intl.formatMessage({id: 'test_runner'})}
                </Typography>
            </Grid>
            <Grid item xs={12} container spacing={1} alignItems="center">
                <Grid item xs={6}>
                    <Typography sx={styles.subCaption}>
                        {intl.formatMessage({id: 'use_real_data'})}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Switch/>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <RegularButton text={'run_test'} onClick={() => {
                }} fullWidth/>
            </Grid>
            <Grid item xs={12}>
                <CommonDivider/>
            </Grid>
            <Grid item xs={12}>
                <Typography sx={styles.caption}>
                    {intl.formatMessage({id: 'builder_controls'})}
                </Typography>
            </Grid>
            <Grid item xs={12} container spacing={1}>
                <Grid item xs={12}>
                    <Typography sx={styles.subCaption}>
                        {intl.formatMessage({id: 'builder_zoom'})}
                    </Typography>
                </Grid>
                <Grid item>
                    <RegularButton variant={'outlined'} text={'plus_sign'} onClick={() => reactFlowInstance.zoomIn()}
                                   fullWidth/>
                </Grid>
                <Grid item>
                    <RegularButton variant={'outlined'} text={'minus_sign'} onClick={() => reactFlowInstance.zoomOut()}
                                   fullWidth/>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <RegularButton text={'reset_builder'} onClick={onReset} fullWidth/>
            </Grid>
        </Grid>
    </Paper>;
};