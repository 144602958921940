import * as React from 'react';
import {Grid, Paper, Switch, Typography} from "@mui/material";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import Box from "@mui/material/Box";
import {getSavedWorkflows, updateWorkflow} from "../../reducers/savedWorkflows";
import {CommonDivider} from "../common/CommonDivider";
import AccountTreeRoundedIcon from '@mui/icons-material/AccountTreeRounded';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import IconButton from "@mui/material/IconButton";
import {useNavigate} from "react-router-dom";

const styles = {
    caption: {
        padding: '1rem',
        fontSize: '26px'
    },
    paper: {
        boxShadow: 'rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px',
        borderRadius: '1rem',
        overflow: 'hidden'
    },
    nameWrapper: {
        padding: '1.5rem'
    },
    name: {
        fontSize: '18px',
        fontWeight: 'bold'
    },
    contentWrapper: {
        padding: '1.5rem'
    },
    switch: {
        color: 'violet.main'
    }
};

export const SavedWorkflows = () => {

    const savedWorkflows = useSelector(getSavedWorkflows);
    const intl = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const updateFlow = id => event => {
        dispatch(updateWorkflow({id, toUpdate: {active: event.target.checked}}));
    }

    const clickHandler = id => () => {
        navigate(`/usecase1/workflow/${id}`);
    };

    if (!savedWorkflows.length)
        return <Grid container
                     style={{height: '100%'}}
                     flexDirection={'column'}
                     justifyContent="center"
                     alignItems="center">
            <Grid item xs/>
            <Grid item>
                <Typography sx={styles.caption} align={'center'}>
                    {intl.formatMessage({id: 'no_saved_workflows'})}
                </Typography>
            </Grid>
            <Grid item xs/>
        </Grid>;

    return <>
        <Box>
            <Typography sx={styles.caption}>
                {intl.formatMessage({id: 'saved_workflows'})}
            </Typography>
        </Box>
        <Grid container
              spacing={2}
              justifyContent="start"
              alignItems="center">
            {savedWorkflows.map(wf =>
                <Grid item xs={6}>
                    <Paper sx={styles.paper}>
                        <Box sx={styles.nameWrapper}>
                            <Grid container
                                  spacing={2}
                                  justifyContent="center"
                                  alignItems="center">
                                <Grid item style={{display: 'flex'}}>
                                    <AccountTreeRoundedIcon sx={{color: 'violet.main'}}/>
                                </Grid>
                                <Grid item xs>
                                    <Typography sx={styles.name}>
                                        {wf.name}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography>
                                        {intl.formatMessage({id: 'active'})}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Switch checked={wf.active} onChange={updateFlow(wf.id)} sx={{color: 'violet.main'}}/>
                                </Grid>
                                <Grid item>
                                    <IconButton sx={{color: 'violet.main'}} component="label" onClick={clickHandler(wf.id)}>
                                        <TuneRoundedIcon/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Box>
                        <CommonDivider/>
                        <Box sx={styles.contentWrapper}>
                            <Grid container
                                  spacing={2}
                                  justifyContent="start"
                                  alignItems="center">
                                <Grid item xs={2}>
                                    <Typography>
                                        {intl.formatMessage({id: 'nodes_count'}, {count: wf.nodes.length - 1})}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography>
                                        {intl.formatMessage({id: 'successful_executions_count'}, {count: 100})}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography>
                                        {intl.formatMessage({id: 'errors_count'}, {count: 3})}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Grid>)}
        </Grid>
    </>;
};