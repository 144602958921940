import * as React from 'react';
import {Grid} from "@mui/material";
import {ConnectorDetailAvailableActions} from "./ConnectorDetailAvailableActions";
import {ConnectorDetailActionFlows} from "./ConnectorDetailActionFlows";

const styles = {
    grid: {
        marginTop: 0
    }
};

export const ConnectorDetailActions = props => {

    const {actions, commonActionFlows} = props;

    return <Grid container
                 justifyContent="center"
                 alignItems="center"
                 sx={styles.grid}
                 spacing={3}>
        <Grid item xs={12}>
            <ConnectorDetailAvailableActions actions={actions}/>
        </Grid>
        <Grid item xs={12}>
            <ConnectorDetailActionFlows commonActionFlows={commonActionFlows}/>
        </Grid>
    </Grid>;
};