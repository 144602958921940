import {createSlice} from '@reduxjs/toolkit';
import {Currency, Locale} from "../constants";

export const localeSlice = createSlice({
    name: 'locale',
    initialState: {
        language: Locale.EN,
        currency: Currency.EUR,
    },
    reducers: {
        changeLanguage: (state, action) => {
            state.language = action.payload;
        },
        changeCurrency: (state, action) => {
            state.currency = action.payload;
        },
    },
});

export const localeLanguageSelector = state => state.locale.language;
export const localeCurrencySelector = state => state.locale.currency;

export const {changeLanguage, changeCurrency} = localeSlice.actions;
export default localeSlice.reducer;