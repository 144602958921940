import * as React from 'react';
import {Box} from "@mui/material";
import {Navbar} from "./Navbar";
import {HeroSection} from "./HeroSection";
import {AppIconsSection} from "./AppIconsSection";
import {ScreenshotsSection} from "./ScreenshotsSection";
import {SignUp} from "./SignUp";

const styles = {
    main: {
        backgroundColor: 'backgroundPrimary.main',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        paddingBottom: '50px',
        overflow: 'auto'
    }
};

export const LandingPage = () => {

    return <Box component={'main'} sx={styles.main}>
        <Navbar/>
        <HeroSection/>
        <AppIconsSection/>
        <ScreenshotsSection/>
        <SignUp/>
    </Box>;
};