import * as React from 'react';
import {Box, Button, Collapse, List, ListItem, Toolbar, Typography} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {useIntl} from "react-intl";
import SpeedRoundedIcon from '@mui/icons-material/SpeedRounded';
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import SupervisedUserCircleRoundedIcon from '@mui/icons-material/SupervisedUserCircleRounded';
import CategoryRoundedIcon from '@mui/icons-material/CategoryRounded';
import WebhookRoundedIcon from '@mui/icons-material/WebhookRounded';
import AccountTreeRoundedIcon from '@mui/icons-material/AccountTreeRounded';
import {DrawerDivider} from "../common/DrawerDivider";
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import {BeaconPopup} from "../featureTour/BeaconPopup";
import {usePathPrefix} from "../../hooks/usePathPrefix";

const styles = {
    list: {
        margin: '24px 0',
    },
    sublist: {
        padding: 0,
    },
    listItem: {
        marginBottom: '4px',
        padding: '0px 16px'
    },
    subListItem: {
        marginBottom: '4px',
        padding: '0px 24px'
    },
    listItemButton: selected => ([
        {
            width: '100%',
            color: 'textColorSecondary.main',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            borderRadius: '0.5rem',
            padding: '9px 24px',
            fontSize: '0.875rem',
            lineHeight: 1.75,
            fontWeight: 'bold',
            textTransform: 'none',
            transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            '&:hover': {
                bgcolor: '#ffffff14'
            },
        },
        selected && {
            color: 'green.main',
            bgcolor: '#ffffff14'
        }
    ]),
    buttonSpacer: {
        flexGrow: 1
    },
    expandIcon: (expanded, selected) => ([
        {
            flexGrow: 1,
            color: 'textColorSecondary.main',
            transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        },
        expanded && {
            transform: 'rotate(-180deg)',
        },
        selected && {
            color: 'green.main'
        }
    ]),
    listSectionLabelWrapper: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 2rem'
    },
    listSectionLabel: {
        flex: '1 1 0',
        color: 'textColorTertiary.main',
        fontSize: '0.75rem',
        fontWeight: 700,
        lineHeight: 2.5,
        textTransform: 'uppercase',
    },
    companyLogo: {
        color: 'violet.main',
        marginRight: '8px',
    },
    companyName: {
        color: 'textColorSecondary.main',
        fontSize: '0.875rem',
        lineHeight: 1.75,
        fontWeight: 'bold',
    }
}

export const DrawerList = () => {

    const location = useLocation();
    const intl = useIntl();
    const navigate = useNavigate();
    const prefixPath = usePathPrefix();

    const dashboardSections = [
        {
            i18n: intl.formatMessage({id: 'my_app_features'}),
            beacon: <BeaconPopup placement={'right-start'}
                                 arrowPlacement={'right'}
                                 label={'feature_tour_business_menu_label'}
                                 text={'feature_tour_business_menu_desc'}
                                 developedByFluxima={false}
                                 developedByBusiness={true}
                                 current={{stepName: 'businessMenu'}}
                                 next={{stepName: 'automationMenu'}}/>,
            items: [
                {
                    i18n: intl.formatMessage({id: 'dashboard'}),
                    icon: <SpeedRoundedIcon fontSize="medium"/>,
                    path: prefixPath(''),
                    name: 'dashboard',
                },
                {
                    i18n: intl.formatMessage({id: 'products'}),
                    icon: <CategoryRoundedIcon fontSize="medium"/>,
                    path: prefixPath('/products'),
                    name: 'products',
                },
                {
                    i18n: intl.formatMessage({id: 'customers'}),
                    icon: <SupervisedUserCircleRoundedIcon fontSize="medium"/>,
                    path: prefixPath('/customers'),
                    name: 'customers',
                },
                {
                    i18n: intl.formatMessage({id: 'orders'}),
                    icon: <ShoppingCartRoundedIcon fontSize="medium"/>,
                    path: prefixPath('/orders'),
                    name: 'orders',
                },
            ]
        }];

    const fluximaSections = [
        {
            i18n: intl.formatMessage({id: 'automation'}),
            beacon: <BeaconPopup placement={'right-start'}
                                 arrowPlacement={'right'}
                                 label={'feature_tour_automation_menu_label'}
                                 text={'feature_tour_automation_menu_desc'}
                                 current={{stepName: 'automationMenu'}}
                                 next={{stepName: 'marketplace', path: '/integrations'}}
                                 previous={{stepName: 'businessMenu'}}/>,
            items: [
                {
                    i18n: intl.formatMessage({id: 'integrations'}),
                    icon: <WebhookRoundedIcon fontSize="medium"/>,
                    path: prefixPath('/integrations'),
                    name: 'integrations',
                },
                {
                    i18n: intl.formatMessage({id: 'workflow'}),
                    icon: <AccountTreeRoundedIcon fontSize="medium"/>,
                    name: 'workflow',
                    subItems: [
                        {
                            i18n: intl.formatMessage({id: 'create_new_workflow'}),
                            path: prefixPath('/workflow/new'),
                        },
                        {
                            i18n: intl.formatMessage({id: 'prebuilt_workflows'}),
                            path: prefixPath('/workflow/prebuilt'),
                        },
                        {
                            i18n: intl.formatMessage({id: 'saved_workflows'}),
                            path: prefixPath('/workflow/list'),
                            selectedRegex: new RegExp(prefixPath('/workflow') + '/(list|\\d+)')
                        },
                        {
                            i18n: intl.formatMessage({id: 'job_executions'}),
                            path: prefixPath('/workflow/execution'),
                        },
                    ]
                },
            ]
        },
    ];

    const initExpandedSection = () => {
        let selectedSection = '';
        dashboardSections
            .forEach(section => section.items.forEach(subsection => {
                    if (subsection.subItems && subsection.subItems.map(item => item.path).includes(location.pathname)) {
                        selectedSection = subsection.name;
                    }
                })
            );
        return selectedSection;
    };

    const [expandedSection, setExpandedSection] = React.useState(initExpandedSection());

    const isItemSelected = item => {
        if (item.subItems) {
            return item.subItems.some(i => location.pathname === i.path || (i.selectedRegex && i.selectedRegex.test(location.pathname)));
        } else {
            return location.pathname === item.path || (item.selectedRegex && item.selectedRegex.test(location.pathname));
        }
    };

    const resolveClickHandler = item => {
        if (item.subItems) {
            return event => {
                event.stopPropagation();
                setExpandedSection(expandedSection === item.name ? '' : item.name);
            }
        } else {
            return event => {
                event.stopPropagation();
                navigate(item.path);
            }
        }
    };

    const renderSections = sections => <List>{sections.map(section =>
        <>
            {section.i18n && <Box sx={styles.listSectionLabelWrapper}>
                <Typography sx={styles.listSectionLabel} component={'span'}>
                    {section.i18n}
                </Typography>
                {section.beacon}
            </Box>}
            {section.items.map(item => <>
                    <ListItem key={item.i18n} sx={styles.listItem}>
                        <Button variant="text"
                                sx={styles.listItemButton(isItemSelected(item))}
                                onClick={resolveClickHandler(item)}
                                startIcon={item.icon}
                                endIcon={item.subItems &&
                                    <ExpandMoreRoundedIcon
                                        sx={styles.expandIcon(expandedSection === item.name, isItemSelected(item))}/>}
                                disableRipple>
                            {item.i18n}
                            <Box sx={styles.buttonSpacer}/>
                        </Button>
                    </ListItem>
                    {item.subItems && <Collapse in={expandedSection === item.name}>
                        <List sx={styles.sublist}>
                            {item.subItems.map((subItem, i) => <ListItem key={`workflow_drawer_item_${i}`}
                                                                         sx={styles.subListItem}>
                                <Button variant="text"
                                        sx={styles.listItemButton(isItemSelected(subItem))}
                                        onClick={resolveClickHandler(subItem)}
                                        disableRipple>
                                    {subItem.i18n}
                                </Button>
                            </ListItem>)}
                        </List>
                    </Collapse>}
                </>
            )}
        </>
    )}</List>;

    return <div>
        <Toolbar>
            <HomeRoundedIcon fontSize="medium" sx={styles.companyLogo}/>
            <Typography sx={styles.companyName} component={'span'}>
                {intl.formatMessage({id: 'my_business_app'})}
            </Typography>
        </Toolbar>
        <DrawerDivider/>
        {renderSections(dashboardSections)}
        <DrawerDivider/>
        {renderSections(fluximaSections)}
    </div>;
};

