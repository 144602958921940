import React, {useEffect, useState} from "react";

export const useViewportDimensions = () => {

    const [dimensions, setDimensions] = useState({viewportWidth: 0, viewportHeight: 0});

    useEffect(() => {

        setDimensions({viewportWidth: window.innerWidth, viewportHeight: window.innerHeight});

        const handleResize = () => {
            setDimensions({viewportWidth: window.innerWidth, viewportHeight: window.innerHeight})
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, []);

    return dimensions;
};