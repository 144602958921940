import {createSlice} from '@reduxjs/toolkit'
import {MemberFieldTypes} from "../constants";

const messagingActions = [
    {
        label: 'send_message',
        request: {
            body: {
                message: {
                    type: MemberFieldTypes.STRING
                },
                recipient_id: {
                    type: MemberFieldTypes.STRING
                },
                request_read_receipt: {
                    type: MemberFieldTypes.BOOLEAN
                },
            }
        },
        response: {
            success: {
                code: 200,
                body: {}
            },
            error: {
                code: 400,
                body: {
                    message: {
                        type: MemberFieldTypes.STRING,
                        value: 'Recipient does not exist.'
                    }
                }
            }
        }
    },
    {
        label: 'retrieve_message',
        request: {
            body: {
                message_id: {
                    type: MemberFieldTypes.STRING
                }
            }
        },
        response: {
            success: {
                code: 200,
                body: {
                    message: {
                        type: MemberFieldTypes.STRING
                    },
                    read_timestamp: {
                        type: MemberFieldTypes.TIMESTAMP
                    }
                }
            },
            error: {
                code: 400,
                body: {
                    message: {
                        type: MemberFieldTypes.STRING,
                        value: 'Message does not exist.'
                    }
                }
            }
        }
    },
    {
        label: 'retrieve_all_recipient_messages',
        request: {
            body: {
                recipient_id: {
                    type: MemberFieldTypes.STRING
                },
                from: {
                    type: MemberFieldTypes.TIMESTAMP
                },
                to: {
                    type: MemberFieldTypes.TIMESTAMP
                },
                read: {
                    type: MemberFieldTypes.BOOLEAN
                }
            }
        },
        response: {
            success: {
                code: 200,
                body: {
                    messages: {
                        type: MemberFieldTypes.LIST,
                        elementType: MemberFieldTypes.OBJECT
                    }
                }
            },
            error: {
                code: 400,
                body: {
                    message: {
                        type: MemberFieldTypes.STRING,
                        value: 'Recipient does not exist.'
                    }
                }
            }
        }
    }
];

const messagingCommonActionFlows = [
    {
        label: 'resend_all_unread_messages',
        description: 'resend_all_unread_messages_desc',
        steps: [
            {
                name: 'retrieve_all_recipient_messages',
                params: {
                    recipient_id: 'recipient_id',
                    read: false
                }
            },
            {
                name: 'send_message',
                params: {
                    message_id: 'message_id'
                },
                foreach: true,
            },
            {
                name: 'perform_default_action',
                params: {
                    timeout: '24 hours'
                }
            }
        ]
    }
];

const paymentProviderActions = [
    {
        label: 'create_customer',
        request: {
            body: {
                name: {
                    type: MemberFieldTypes.STRING,
                    required: true
                },
                address: {
                    type: MemberFieldTypes.STRING,
                    required: true
                },
            }
        },
        response: {
            success: {
                code: 200,
                body: {
                    id: {
                        type: MemberFieldTypes.STRING
                    },
                    name: {
                        type: MemberFieldTypes.STRING
                    },
                    address: {
                        type: MemberFieldTypes.STRING
                    },
                }
            },
            error: {
                code: 400,
                body: {
                    message: {
                        type: MemberFieldTypes.STRING,
                        value: 'Could not create the customer.'
                    }
                }
            }
        }
    },
    {
        label: 'link_debit_card',
        request: {
            body: {
                customer_id: {
                    type: MemberFieldTypes.STRING,
                    required: true
                },
                card_id: {
                    type: MemberFieldTypes.STRING,
                    required: true
                },
            }
        },
        response: {
            success: {
                code: 200,
                body: {}
            },
            error: {
                code: 400,
                body: {
                    message: {
                        type: MemberFieldTypes.STRING,
                        value: 'The debit card could not be linked.'
                    }
                }
            }
        }
    },
    {
        label: 'charge_a_debit_card',
        request: {
            body: {
                customer_id: {
                    type: MemberFieldTypes.STRING
                },
                amount: {
                    type: MemberFieldTypes.NUMBER
                }
            }
        },
        response: {
            success: {
                code: 200,
                body: {
                    transaction_id: {
                        type: MemberFieldTypes.STRING
                    }
                }
            },
            error: {
                code: 400,
                body: {
                    message: {
                        type: MemberFieldTypes.STRING,
                        value: 'Insufficient funds.'
                    }
                }
            }
        }
    }
];

const paymentProviderActionFlows = [
    {
        label: 'create_customer_and_charge_money',
        description: 'resend_all_unread_messages_desc',
        steps: [
            {
                name: 'create_customer',
                params: {
                    verificationStatus: 'verified'
                }
            },
            {
                name: 'link_debit_card',
                params: {
                    default_card: true
                }
            },
            {
                name: 'create_subscription',
                params: {
                    periodicity: 'monthly'
                }
            }
        ]
    }
];

export const integrationsSlice = createSlice({
    name: 'integrations',
    initialState: {
        data: [
            {
                id: 1,
                name: 'Slack',
                description: 'Slack is a messaging program designed specifically for the office, but has also been adopted for personal use.',
                categoryId: 1,
                subCategory: '',
                logoPath: '/connectorLogos/slack.png',
                keywords: [],
                activated: false,
                actions: messagingActions,
                commonActionFlows: messagingCommonActionFlows
            },
            {
                id: 2,
                name: 'Teams',
                description: 'Microsoft Teams is a proprietary business communication platform developed by Microsoft.',
                categoryId: 1,
                subCategory: '',
                logoPath: '/connectorLogos/teams.png',
                keywords: ['microsoft'],
                activated: false,
                actions: messagingActions,
                commonActionFlows: messagingCommonActionFlows
            },
            {
                id: 3,
                name: 'Zoom',
                description: "Zoom's secure, reliable video platform powers all of your communication needs, including meetings, chat, phone, webinars, and online events.",
                categoryId: 1,
                subCategory: '',
                logoPath: '/connectorLogos/zoom.webp',
                keywords: [],
                activated: false,
                actions: messagingActions,
                commonActionFlows: messagingCommonActionFlows
            },
            {
                id: 4,
                name: 'Google Meet',
                description: "Real-time meetings by Google. Using your browser, share your video, desktop, and presentations with teammates and customers.",
                categoryId: 1,
                subCategory: '',
                logoPath: '/connectorLogos/google_meet.png',
                keywords: [],
                activated: false,
                actions: messagingActions,
                commonActionFlows: messagingCommonActionFlows
            },
            {
                id: 5,
                name: 'HubSpot',
                description: 'HubSpot is a developer and marketer of software products for inbound marketing, sales, and customer service.',
                categoryId: 2,
                subCategory: '',
                logoPath: '/connectorLogos/hubspot.png',
                keywords: ['crm'],
                activated: false
            },
            {
                id: 6,
                name: 'Zoho',
                description: 'Zoho Corporation is a technology company that makes web-based business tools. It is best known for the online office suite offering Zoho Office Suite.',
                categoryId: 2,
                subCategory: '',
                logoPath: '/connectorLogos/zoho.png',
                keywords: ['crm'],
                activated: false
            },
            {
                id: 7,
                name: 'Salesforce',
                description: 'Salesforce, Inc. is a cloud-based software company providing customer relationship management software and applications focused on sales, customer service, marketing automation, analytics, and application development.',
                categoryId: 2,
                subCategory: '',
                logoPath: '/connectorLogos/salesforce.png',
                keywords: ['crm'],
                activated: false
            },
            {
                id: 8,
                name: 'Trello',
                description: 'Trello is a web-based, Kanban-style, list-making application for project management and issue tracking.',
                categoryId: 3,
                subCategory: '',
                logoPath: '/connectorLogos/trello.png',
                keywords: [],
                activated: false
            },
            {
                id: 9,
                name: 'Asana',
                description: 'Asana is a web and mobile work management platform designed to help teams organize, track, and manage their work.',
                categoryId: 3,
                subCategory: '',
                logoPath: '/connectorLogos/asana.png',
                keywords: [],
                activated: false
            },
            {
                id: 10,
                name: 'Jira',
                description: 'Jira is an issue tracking web-based software that allows bug tracking, agile, project management and work management.',
                categoryId: 3,
                subCategory: '',
                logoPath: '/connectorLogos/jira.png',
                keywords: [],
                activated: false
            },
            {
                id: 11,
                name: 'Mailchimp',
                description: 'Grow your business on your terms with Mailchimp\'s All-In-One marketing, automation & email marketing platform.',
                categoryId: 4,
                subCategory: '',
                logoPath: '/connectorLogos/mailchimp.png',
                keywords: [],
                activated: false
            },
            {
                id: 12,
                name: 'Sendinblue',
                description: 'Sendinblue is a SaaS solution that offers a cloud-based marketing communication software suite with email marketing, transactional email, marketing automation, customer-relationship management and more.',
                categoryId: 4,
                subCategory: '',
                logoPath: '/connectorLogos/sendinblue.png',
                keywords: [],
                activated: false
            },
            {
                id: 13,
                name: 'Mailgun',
                description: 'Mailgun Technologies, Inc. is an email delivery service for sending, receiving, and tracking emails.',
                categoryId: 4,
                subCategory: '',
                logoPath: '/connectorLogos/mailgun.png',
                keywords: [],
                activated: false
            },
            {
                id: 14,
                name: 'Stripe',
                description: 'Stripe is a suite of APIs powering online payment processing and commerce solutions for internet businesses of all sizes. Accept payments and scale faster.',
                categoryId: 5,
                subCategory: '',
                logoPath: '/connectorLogos/stripe.png',
                keywords: [],
                activated: false,
                actions: paymentProviderActions,
                commonActionFlows: paymentProviderActionFlows
            },
            {
                id: 15,
                name: 'Paypal',
                description: 'Transfer money online in seconds with PayPal money transfer. All you need is an email address.',
                categoryId: 5,
                subCategory: '',
                logoPath: '/connectorLogos/paypal.png',
                keywords: [],
                activated: false,
                actions: paymentProviderActions,
                commonActionFlows: paymentProviderActionFlows
            },
            {
                id: 16,
                name: 'Adyen',
                description: 'Adyen allows businesses to accept ecommerce, mobile, and point-of-sale payments.',
                categoryId: 5,
                subCategory: '',
                logoPath: '/connectorLogos/adyen.png',
                keywords: [],
                activated: false,
                actions: paymentProviderActions,
                commonActionFlows: paymentProviderActionFlows
            },
            {
                id: 17,
                name: 'SAP',
                description: 'SAP Business One is a customizable ERP solution that has been a long-time favorite. of smaller firms and subsidiaries of large enterprises.',
                categoryId: 6,
                subCategory: '',
                logoPath: '/connectorLogos/sap.png',
                keywords: [],
                activated: false
            },
            {
                id: 18,
                name: 'Odoo',
                description: 'Odoo is a suite of open source business apps that cover all your company needs: CRM, eCommerce, accounting, inventory, point of sale, project management, etc.',
                categoryId: 6,
                subCategory: '',
                logoPath: '/connectorLogos/odoo.png',
                keywords: [],
                activated: false
            },
            {
                id: 19,
                name: 'Sage',
                description: 'A Sage ERP business management solution offers real-time financial management, business intelligence, supply chain management, and industry-specific solutions.',
                categoryId: 6,
                subCategory: '',
                logoPath: '/connectorLogos/sage.png',
                keywords: [],
                activated: false
            }
        ]
    },
    reducers: {
        integrationActivated: (state, action) => {
            const connectorId = parseInt(action.payload);
            state.data = state.data.map(conn => {
                if (conn.id === connectorId)
                    return {
                        ...conn,
                        activated: true
                    };
                return conn;
            });
        },
    }
})

export const getConnectorsByCategorySelector = state => state.integrationCategories.data.map(category => {
    const connectors = state.integrations.data.filter(integration => integration.categoryId === category.id);
    return {
        ...category,
        connectors
    };
});

export const getConnectorById = connectorId => state => state.integrations.data.find(conn => conn.id === connectorId);

export const {integrationActivated} = integrationsSlice.actions;
export default integrationsSlice.reducer;