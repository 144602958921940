import {createSlice} from '@reduxjs/toolkit'

export const prebuiltWorkflowsSlice = createSlice({
    name: 'prebuiltWorkflows',
    initialState: {
        data: [{
            "id": 1,
            "name": "Subscribe new customer to newsletter",
            "idCounter": 5,
            "nodes": [{
                "id": "start-node-1",
                "type": "workflowStartNode",
                "parentId": null,
                "draggable": false,
                "position": {"x": 548, "y": 65},
                "data": {
                    "name": "Poller",
                    "description": "Periodically poll the given target for new data that need to be processed in your pipeline.",
                    "workflowActionType": "polling",
                    "endpoint": "",
                    "timeInterval": "15",
                    "headers": [{
                        "headerName": "Content-type",
                        "headerValue": "application/json"
                    }, {"headerName": "Accept", "headerValue": "application/json"}]
                }
            }, {
                "id": "workflow-node-2",
                "type": "workflowStepNode",
                "draggable": false,
                "position": {"x": 548, "y": 230},
                "data": {
                    "name": "HubSpot",
                    "description": "HubSpot is a developer and marketer of software products for inbound marketing, sales, and customer service.",
                    "logoPath": "/connectorLogos/hubspot.png",
                    "nodeAction": {"value": "create_customer", "label": "create_customer"}
                },
                "parentId": "start-node-1"
            }, {
                "id": "workflow-node-3",
                "type": "workflowStepNode",
                "draggable": false,
                "position": {"x": 548, "y": 395},
                "data": {
                    "name": "Transform",
                    "description": "Transforms data into a new shape required by the next step in the pipeline.",
                    "workflowActionType": "transform",
                    "nodeAction": {"value": "link_debit_card", "label": "link_debit_card"}
                },
                "parentId": "workflow-node-2"
            }, {
                "id": "workflow-node-4",
                "type": "workflowStepNode",
                "draggable": false,
                "position": {"x": 548, "y": 560},
                "data": {
                    "name": "Paypal",
                    "description": "Transfer money online in seconds with PayPal money transfer. All you need is an email address.",
                    "logoPath": "/connectorLogos/paypal.png",
                    "nodeAction": {"value": "link_debit_card", "label": "link_debit_card"}
                },
                "parentId": "workflow-node-3"
            }, {
                "id": "workflow-node-5",
                "type": "workflowStepNode",
                "draggable": false,
                "position": {"x": 548, "y": 725},
                "data": {
                    "name": "Mailchimp",
                    "description": "Grow your business on your terms with Mailchimp's All-In-One marketing, automation & email marketing platform.",
                    "logoPath": "/connectorLogos/mailchimp.png",
                    "nodeAction": {"value": "send_invoice", "label": "send_invoice"}
                },
                "parentId": "workflow-node-4"
            }, {
                "id": "add-more-node-1",
                "type": "addMoreNode",
                "parentId": "workflow-node-5",
                "draggable": false,
                "position": {"x": 548, "y": 890},
                "data": {}
            }],
            "edges": [{
                "id": "start-node-1-workflow-node-2",
                "type": "straight",
                "source": "start-node-1",
                "target": "workflow-node-2",
                "animated": true
            }, {
                "id": "workflow-node-2-workflow-node-3",
                "type": "straight",
                "source": "workflow-node-2",
                "target": "workflow-node-3",
                "animated": true
            }, {
                "id": "workflow-node-3-workflow-node-4",
                "type": "straight",
                "source": "workflow-node-3",
                "target": "workflow-node-4",
                "animated": true
            }, {
                "id": "workflow-node-4-workflow-node-5",
                "type": "straight",
                "source": "workflow-node-4",
                "target": "workflow-node-5",
                "animated": true
            }, {
                "id": "workflow-node-5-add-more-node-1",
                "type": "straight",
                "source": "workflow-node-5",
                "target": "add-more-node-1",
                "animated": true
            }]
        }, {
            "id": 2,
            "name": "Send meeting agenda to new meeting attendees",
            "idCounter": 5,
            "nodes": [{
                "id": "start-node-1",
                "type": "workflowStartNode",
                "parentId": null,
                "draggable": false,
                "position": {"x": 548, "y": 65},
                "data": {
                    "name": "Webhook",
                    "description": "Reactively accept new data in a specific endpoint to be processed in your pipeline. ",
                    "workflowActionType": "webhook",
                    "endpoint": "",
                    "timeInterval": "15",
                    "headers": [{
                        "headerName": "Content-type",
                        "headerValue": "application/json"
                    }, {"headerName": "Accept", "headerValue": "application/json"}]
                }
            }, {
                "id": "workflow-node-2",
                "type": "workflowStepNode",
                "draggable": false,
                "position": {"x": 548, "y": 230},
                "data": {
                    "name": "Google Meet",
                    "description": "Real-time meetings by Google. Using your browser, share your video, desktop, and presentations with teammates and customers.",
                    "logoPath": "/connectorLogos/google_meet.png",
                    "nodeAction": {"value": "create_customer", "label": "create_customer"}
                },
                "parentId": "start-node-1"
            }, {
                "id": "workflow-node-3",
                "type": "workflowStepNode",
                "draggable": false,
                "position": {"x": 548, "y": 395},
                "data": {
                    "name": "Mailgun",
                    "description": "Mailgun Technologies, Inc. is an email delivery service for sending, receiving, and tracking emails.",
                    "logoPath": "/connectorLogos/mailgun.png",
                    "nodeAction": {"value": "create_customer", "label": "create_customer"}
                },
                "parentId": "workflow-node-2"
            }, {
                "id": "workflow-node-4",
                "type": "workflowStepNode",
                "draggable": false,
                "position": {"x": 548, "y": 560},
                "data": {
                    "name": "Trello",
                    "description": "Trello is a web-based, Kanban-style, list-making application for project management and issue tracking.",
                    "logoPath": "/connectorLogos/trello.png",
                    "nodeAction": {"value": "retrieve_message", "label": "retrieve_message"}
                },
                "parentId": "workflow-node-3"
            }, {
                "id": "workflow-node-5",
                "type": "workflowStepNode",
                "draggable": false,
                "position": {"x": 548, "y": 725},
                "data": {
                    "name": "Slack",
                    "description": "Slack is a messaging program designed specifically for the office, but has also been adopted for personal use.",
                    "logoPath": "/connectorLogos/slack.png",
                    "nodeAction": {"value": "send_message", "label": "send_message"}
                },
                "parentId": "workflow-node-4"
            }, {
                "id": "add-more-node-1",
                "type": "addMoreNode",
                "parentId": "workflow-node-5",
                "draggable": false,
                "position": {"x": 548, "y": 890},
                "data": {}
            }],
            "edges": [{
                "id": "start-node-1-workflow-node-2",
                "type": "straight",
                "source": "start-node-1",
                "target": "workflow-node-2",
                "animated": true
            }, {
                "id": "workflow-node-2-workflow-node-3",
                "type": "straight",
                "source": "workflow-node-2",
                "target": "workflow-node-3",
                "animated": true
            }, {
                "id": "workflow-node-3-workflow-node-4",
                "type": "straight",
                "source": "workflow-node-3",
                "target": "workflow-node-4",
                "animated": true
            }, {
                "id": "workflow-node-4-workflow-node-5",
                "type": "straight",
                "source": "workflow-node-4",
                "target": "workflow-node-5",
                "animated": true
            }, {
                "id": "workflow-node-5-add-more-node-1",
                "type": "straight",
                "source": "workflow-node-5",
                "target": "add-more-node-1",
                "animated": true
            }]
        }, {
            "id": 3,
            "name": "Merge all customer marketing data ",
            "idCounter": 6,
            "nodes": [{
                "id": "start-node-1",
                "type": "workflowStartNode",
                "parentId": null,
                "draggable": false,
                "position": {"x": 548, "y": 65},
                "data": {
                    "name": "Poller",
                    "description": "Periodically poll the given target for new data that need to be processed in your pipeline.",
                    "workflowActionType": "polling",
                    "endpoint": "",
                    "timeInterval": "15",
                    "headers": [{
                        "headerName": "Content-type",
                        "headerValue": "application/json"
                    }, {"headerName": "Accept", "headerValue": "application/json"}]
                }
            }, {
                "id": "workflow-node-2",
                "type": "workflowStepNode",
                "draggable": false,
                "position": {"x": 548, "y": 230},
                "data": {
                    "name": "HubSpot",
                    "description": "HubSpot is a developer and marketer of software products for inbound marketing, sales, and customer service.",
                    "logoPath": "/connectorLogos/hubspot.png",
                    "nodeAction": {"value": "create_customer", "label": "create_customer"}
                },
                "parentId": "start-node-1"
            }, {
                "id": "workflow-node-3",
                "type": "workflowStepNode",
                "draggable": false,
                "position": {"x": 548, "y": 395},
                "data": {
                    "name": "Trello",
                    "description": "Trello is a web-based, Kanban-style, list-making application for project management and issue tracking.",
                    "logoPath": "/connectorLogos/trello.png",
                    "nodeAction": {"value": "create_customer", "label": "create_customer"}
                },
                "parentId": "workflow-node-2"
            }, {
                "id": "workflow-node-4",
                "type": "workflowStepNode",
                "draggable": false,
                "position": {"x": 548, "y": 560},
                "data": {
                    "name": "Flatten",
                    "description": "Combines a list of nested lists into a single long list.",
                    "workflowActionType": "flatten",
                    "nodeAction": {"value": "retrieve_message", "label": "retrieve_message"}
                },
                "parentId": "workflow-node-3"
            }, {
                "id": "workflow-node-5",
                "type": "workflowStepNode",
                "draggable": false,
                "position": {"x": 548, "y": 725},
                "data": {
                    "name": "Merge",
                    "description": "Merges two various sources of data into new combined entities.",
                    "workflowActionType": "merge",
                    "nodeAction": {"value": "create_customer", "label": "create_customer"}
                },
                "parentId": "workflow-node-4"
            }, {
                "id": "workflow-node-6",
                "type": "workflowStepNode",
                "draggable": false,
                "position": {"x": 548, "y": 890},
                "data": {
                    "name": "Salesforce",
                    "description": "Salesforce, Inc. is a cloud-based software company providing customer relationship management software and applications focused on sales, customer service, marketing automation, analytics, and application development.",
                    "logoPath": "/connectorLogos/salesforce.png",
                    "nodeAction": {"value": "create_customer", "label": "create_customer"}
                },
                "parentId": "workflow-node-5"
            }, {
                "id": "add-more-node-1",
                "type": "addMoreNode",
                "parentId": "workflow-node-6",
                "draggable": false,
                "position": {"x": 548, "y": 1055},
                "data": {}
            }],
            "edges": [{
                "id": "start-node-1-workflow-node-2",
                "type": "straight",
                "source": "start-node-1",
                "target": "workflow-node-2",
                "animated": true
            }, {
                "id": "workflow-node-2-workflow-node-3",
                "type": "straight",
                "source": "workflow-node-2",
                "target": "workflow-node-3",
                "animated": true
            }, {
                "id": "workflow-node-3-workflow-node-4",
                "type": "straight",
                "source": "workflow-node-3",
                "target": "workflow-node-4",
                "animated": true
            }, {
                "id": "workflow-node-4-workflow-node-5",
                "type": "straight",
                "source": "workflow-node-4",
                "target": "workflow-node-5",
                "animated": true
            }, {
                "id": "workflow-node-5-workflow-node-6",
                "type": "straight",
                "source": "workflow-node-5",
                "target": "workflow-node-6",
                "animated": true
            }, {
                "id": "workflow-node-6-add-more-node-1",
                "type": "straight",
                "source": "workflow-node-6",
                "target": "add-more-node-1",
                "animated": true
            }]
        }, {
            "id": 4,
            "name": "Reconcile payments among payment processors",
            "idCounter": 6,
            "nodes": [{
                "id": "start-node-1",
                "type": "workflowStartNode",
                "parentId": null,
                "draggable": false,
                "position": {"x": 548, "y": 65},
                "data": {
                    "name": "Webhook",
                    "description": "Reactively accept new data in a specific endpoint to be processed in your pipeline. ",
                    "workflowActionType": "webhook",
                    "endpoint": "",
                    "timeInterval": "15",
                    "headers": [{
                        "headerName": "Content-type",
                        "headerValue": "application/json"
                    }, {"headerName": "Accept", "headerValue": "application/json"}]
                }
            }, {
                "id": "workflow-node-2",
                "type": "workflowStepNode",
                "draggable": false,
                "position": {"x": 548, "y": 230},
                "data": {
                    "name": "Adyen",
                    "description": "Adyen allows businesses to accept ecommerce, mobile, and point-of-sale payments.",
                    "logoPath": "/connectorLogos/adyen.png",
                    "nodeAction": {"value": "create_customer", "label": "create_customer"}
                },
                "parentId": "start-node-1"
            }, {
                "id": "workflow-node-3",
                "type": "workflowStepNode",
                "draggable": false,
                "position": {"x": 548, "y": 395},
                "data": {
                    "name": "Filter",
                    "description": "Filters data, only leaving items matching a condition.",
                    "workflowActionType": "filter",
                    "nodeAction": {"value": "create_customer", "label": "create_customer"}
                },
                "parentId": "workflow-node-2"
            }, {
                "id": "workflow-node-4",
                "type": "workflowStepNode",
                "draggable": false,
                "position": {"x": 548, "y": 560},
                "data": {
                    "name": "Paypal",
                    "description": "Transfer money online in seconds with PayPal money transfer. All you need is an email address.",
                    "logoPath": "/connectorLogos/paypal.png",
                    "nodeAction": {"value": "create_customer", "label": "create_customer"}
                },
                "parentId": "workflow-node-3"
            }, {
                "id": "workflow-node-5",
                "type": "workflowStepNode",
                "draggable": false,
                "position": {"x": 548, "y": 725},
                "data": {
                    "name": "Group",
                    "description": "Groups data into lists based on a grouping condition.",
                    "workflowActionType": "group",
                    "nodeAction": {"value": "create_customer", "label": "create_customer"}
                },
                "parentId": "workflow-node-4"
            }, {
                "id": "workflow-node-6",
                "type": "workflowStepNode",
                "draggable": false,
                "position": {"x": 548, "y": 890},
                "data": {
                    "name": "Stripe",
                    "description": "Stripe is a suite of APIs powering online payment processing and commerce solutions for internet businesses of all sizes. Accept payments and scale faster.",
                    "logoPath": "/connectorLogos/stripe.png",
                    "nodeAction": {"value": "create_customer", "label": "create_customer"}
                },
                "parentId": "workflow-node-5"
            }, {
                "id": "add-more-node-1",
                "type": "addMoreNode",
                "parentId": "workflow-node-6",
                "draggable": false,
                "position": {"x": 548, "y": 1055},
                "data": {}
            }],
            "edges": [{
                "id": "start-node-1-workflow-node-2",
                "type": "straight",
                "source": "start-node-1",
                "target": "workflow-node-2",
                "animated": true
            }, {
                "id": "workflow-node-2-workflow-node-3",
                "type": "straight",
                "source": "workflow-node-2",
                "target": "workflow-node-3",
                "animated": true
            }, {
                "id": "workflow-node-3-workflow-node-4",
                "type": "straight",
                "source": "workflow-node-3",
                "target": "workflow-node-4",
                "animated": true
            }, {
                "id": "workflow-node-4-workflow-node-5",
                "type": "straight",
                "source": "workflow-node-4",
                "target": "workflow-node-5",
                "animated": true
            }, {
                "id": "workflow-node-5-workflow-node-6",
                "type": "straight",
                "source": "workflow-node-5",
                "target": "workflow-node-6",
                "animated": true
            }, {
                "id": "workflow-node-6-add-more-node-1",
                "type": "straight",
                "source": "workflow-node-6",
                "target": "add-more-node-1",
                "animated": true
            }]
        }]

    }
});

export const getPrebuiltWorkflows = state => state.prebuiltWorkflows.data;

export default prebuiltWorkflowsSlice.reducer;