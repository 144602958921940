import { configureStore } from '@reduxjs/toolkit'
import localeReducer from '../reducers/locale';
import appNotificationReducer from '../reducers/appNotification';
import integrationsReducer from '../reducers/integrations';
import integrationCategoriesReducer from '../reducers/categories';
import logsReducer from '../reducers/logs';
import workflowActionsReducer from '../reducers/workflowActions';
import newWorkflowReducer from '../reducers/newWorkflow';
import workflowExecutionsReducer from '../reducers/workflowExecutions';
import savedWorkflowsReducer from '../reducers/savedWorkflows';
import prebuiltWorkflowsReducer from '../reducers/prebuiltWorkflows';
import customersReducer from '../reducers/customers';
import featureTourReducer from '../reducers/featureTour';
import productsReducer from '../reducers/products';
import demoTypeReducer from '../reducers/demoType';

export default configureStore({
    reducer: {
        locale: localeReducer,
        appNotification: appNotificationReducer,
        integrations: integrationsReducer,
        integrationCategories: integrationCategoriesReducer,
        logs: logsReducer,
        workflowActions: workflowActionsReducer,
        newWorkflow: newWorkflowReducer,
        workflowExecutions: workflowExecutionsReducer,
        savedWorkflows: savedWorkflowsReducer,
        prebuiltWorkflows: prebuiltWorkflowsReducer,
        customers: customersReducer,
        featureTour: featureTourReducer,
        products: productsReducer,
        demoType: demoTypeReducer,
    }
})