import './App.css';
import "swiper/css";
import {MainLayout} from "./components/layout/MainLayout";
import {Navigate, Route, Routes} from "react-router-dom";
import {messagesSk} from "./i18n/messages_sk";
import {messagesEn} from "./i18n/messages_en";
import {IntlProvider} from "react-intl";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {localeLanguageSelector} from "./reducers/locale";
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {ConnectorMarketplace} from "./components/connector/ConnectorMarketplace";
import {ConnectorDetail} from "./components/connector/ConnectorDetail";
import {LandingPage} from "./components/landingPage/LandingPage";
import {ReactFlowProvider} from "reactflow";
import {WorkflowExecutions} from "./components/workflowExecutions/WorkflowExecutions";
import {SavedWorkflows} from "./components/savedWorkflows/SavedWorkflows";
import {NewWorkflow} from "./components/newWorkflow/NewWorkflow";
import {EditWorkflow} from "./components/savedWorkflows/EditWorkflow";
import {PrebuiltWorkflows} from "./components/prebuiltWorkflows/PrebuiltWorkflows";
import {Dashboard} from "./components/dashboard/Dashboard";
import {Customers} from "./components/customers/Customers";
import {OrderDetail} from "./components/orders/OrderDetail";
import {Products} from "./components/products/Products";
import {DemoType} from "./constants";
import {changeDemoType} from "./reducers/demoType";

const messages = {
    sk: messagesSk,
    en: messagesEn
};

function App() {

    const localeLanguage = useSelector(localeLanguageSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        const hostname = window.location.hostname;
        const demoType = hostname.split('.')[0];
        if (demoType === DemoType.WIDGETS)
            dispatch(changeDemoType(DemoType.WIDGETS));
        if (demoType === DemoType.COMPOSER)
            dispatch(changeDemoType(DemoType.COMPOSER));
    }, []);

    return (
        <IntlProvider locale={localeLanguage} messages={messages[localeLanguage]}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <ReactFlowProvider>
                    <Routes>
                        <Route path="/" element={<LandingPage/>}/>
                        <Route path="/usecase1" element={<MainLayout/>}>
                            <Route index element={<Dashboard/>}/>
                            <Route path="products" element={<Products/>}/>
                            <Route path="customers" element={<Customers/>}/>
                            <Route path="orders" element={<OrderDetail/>}/>
                            <Route path="integrations">
                                <Route index element={<ConnectorMarketplace/>}/>
                                <Route path=":connectorId" element={<ConnectorDetail/>}/>
                            </Route>
                            <Route path="workflow">
                                <Route path="new" element={<NewWorkflow/>}/>
                                <Route path="prebuilt" element={<PrebuiltWorkflows/>}/>
                                <Route path="list" element={<SavedWorkflows/>}/>
                                <Route path=":workflowId" element={<EditWorkflow/>}/>
                                <Route path="execution" element={<WorkflowExecutions/>}/>
                            </Route>
                        </Route>
                        <Route element={<Navigate to="/"/>}/>
                    </Routes>
                </ReactFlowProvider>
            </LocalizationProvider>
        </IntlProvider>
    );
}

export default App;
