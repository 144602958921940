import React, {useEffect, useState} from 'react';
import {useIntl} from "react-intl";
import {Box, Container, Grid, Typography, useMediaQuery, useTheme} from "@mui/material";
import {usePrevious} from "../../hooks/usePrevious";
import {useViewportDimensions} from "../../hooks/useViewportDimensions";
import {useSelector} from "react-redux";
import {demoTypeSelector} from "../../reducers/demoType";

const styles = {
    container: {
        paddingTop: {xs: '100px'},
        paddingBottom: {xs: '50px', sm: '100px'},
        margin: {xs: 0, md: '0 24px'},
        borderRadius: '40px',
        background: 'radial-gradient(140% 107.13% at 50% 10%,transparent 37.41%,#774ad3 69.27%,#fff 100%)',
    },
    caption: animate => [
        {
            fontSize: {xs: '40px', sm: '55px', md: '55px', lg: '60px'},
            fontWeight: 500,
            color: 'textColorPrimaryDarkTheme.main',
            textFillColor: 'transparent',
            textAlign: {xs: 'center', md: 'left'},
            transition: 'transform 0.7s ease 0s, opacity 0.7s ease 0s',
            opacity: 0,
            background: 'radial-gradient(45% 100% at 50% 50%,#fff 30%,hsla(0,0%,100%,.35) 100%)',
            backgroundClip: 'text',
            transform: 'translate3d(0px, 100px, 0px)',
            marginBottom: {xs: '20px', sm: '20px'},
        },
        animate && {
            opacity: 1,
            transform: 'translate3d(0px, 0px, 0px)'
        }
    ],
    desc: animate => [
        {
            fontSize: {xs: '16px', sm: '20px', md: '22px', lg: '24px'},
            marginBottom: '24px',
            color: 'textColorPrimaryDarkTheme.main',
            textAlign: {xs: 'center', md: 'left'},
            transition: 'transform 0.7s ease 0s, opacity 0.7s ease 0s',
            opacity: 0,
            transform: 'translate3d(0px, 100px, 0px)',
        },
        animate && {
            opacity: 1,
            transform: 'translate3d(0px, 0px, 0px)'
        }
    ],
    imageContentWrapper: {
        position: 'relative',
        height: '250px'
    },
    image: {
        height: 'auto',
        width: {xs: '250px', md: '350px', lg: '400px', xl: '450px'},
    },
    imageWrapper: animate => [
        {
            position: 'absolute',
            transition: 'transform 0.7s ease 0s, opacity 0.7s ease 0s',
            opacity: 0,
            transform: 'translate3d(0px, 200px, 0px) translateX(-50%)',
            left: '50%',
        },
        animate && {
            opacity: 1,
            transform: 'translate3d(0px, 0px, 0px) translateX(-50%)'
        }
    ],
    slidingImageWrapper: (animate, zIndex) => [
        {
            position: 'absolute',
            height: 'auto',
            width: 0,
            overflow: 'hidden',
            left: {
                xs: 'calc((100% - 250px) / 2)',
                md: 'calc((100% - 350px) / 2)',
                lg: 'calc((100% - 400px) / 2)',
                xl: 'calc((100% - 450px) / 2)'
            },
            transition: 'width 1000ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            zIndex
        },
        animate && {
            width: {xs: '250px', md: '350px', lg: '400px', xl: '450px'},
        }
    ],
    label: animate => [
        {
            display: 'inline-flex',
            justifyContent: 'center',
            textTransform: 'uppercase',
            fontSize: '12px',
            fontWeight: 600,
            lineHeight: 2,
            borderRadius: '16px',
            padding: '2px 16px',
            marginBottom: '40px',
            color: 'backgroundPrimary.main',
            bgcolor: 'backgroundSecondary.main',
            transition: 'transform 0.7s ease 0s, opacity 0.7s ease 0s',
            opacity: 0,
        },
        animate && {
            opacity: 1,
        }
    ],
};

export const HeroSection = () => {

    const intl = useIntl();
    const [animatePicture1, setAnimatePicture1] = useState(false);
    const [animatePicture2, setAnimatePicture2] = useState(false);
    const [animatePicture3, setAnimatePicture3] = useState(false);
    const [slidingAnimation, setSlidingAnimation] = useState(false);
    const [slidingImageIndex, setSlidingImageIndex] = useState(1);
    const [animateCaption, setAnimateCaption] = useState(false);
    const [animateDesc, setAnimateDesc] = useState(false);
    const [animateLabel, setAnimateLabel] = useState(false);
    const [intervalId, setIntervalId] = useState();
    const [animate, setAnimate] = useState(false);
    const [scrollListener, setScrollListener] = useState(() => () => void 0);
    const previousListener = usePrevious(scrollListener);
    const [scrollTarget, setScrollTarget] = useState(undefined);
    const {viewportHeight} = useViewportDimensions();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.up('md'));
    const demoType = useSelector(demoTypeSelector);

    useEffect(() => {
        window.removeEventListener('scroll', previousListener);
        window.addEventListener('scroll', scrollListener);
        return () => window.removeEventListener('scroll', scrollListener);
    }, [scrollListener]);

    useEffect(() => {
        if (fullScreen) {
            setAnimate(true);
            return;
        }

        if (!scrollTarget)
            return;

        const listener = () => {
            const {y} = scrollTarget.getBoundingClientRect();
            if (viewportHeight >= y + 100) {
                setAnimate(true);
            }
        };
        listener();

        setScrollListener(() => listener);
    }, [scrollTarget, viewportHeight]);

    useEffect(() => {
        if (animate) {
            setTimeout(() => setAnimatePicture1(true), 250);
            setTimeout(() => setAnimatePicture2(true), 400);
            setTimeout(() => setAnimatePicture3(true), 600);
            setTimeout(() => setSlidingAnimation(true), 1500);
        }

        setTimeout(() => setAnimateCaption(true), 200);
        setTimeout(() => setAnimateDesc(true), 600);
        setTimeout(() => setAnimateLabel(true), 900);
    }, [animate]);

    useEffect(() => {
        if (slidingAnimation && !intervalId) {
            const intervalId = setInterval(() => {
                setSlidingImageIndex(current => current === 5 ? 1 : current + 1);
            }, 5000);
            setIntervalId(intervalId);
        }
        return () => clearInterval(intervalId);
    }, [slidingAnimation]);

    const slidingImages = [
        {path: '/Fluxima_icon-white.svg', index: 1, indexOverlap: 2},
        {path: '/Fluxima_icon-blue.svg', index: 2, indexOverlap: 3},
        {path: '/Fluxima_icon-violet.svg', index: 3, indexOverlap: 4},
        {path: '/Fluxima_icon-green.svg', index: 4, indexOverlap: 5},
        {path: '/Fluxima_icon-purple.svg', index: 5, indexOverlap: 1},
    ];

    const isVisible = imgData => imgData.index === slidingImageIndex || imgData.indexOverlap === slidingImageIndex;

    const determineZIndex = imgData => {
        if (imgData.index === slidingImageIndex)
            return 2;
        if (imgData.indexOverlap === slidingImageIndex)
            return 1;
        return 0;
    };

    return <Box sx={styles.container}>
        <Container maxWidth="lg">
            <Grid container
                  justifyContent="center"
                  alignItems="start">
                <Grid item xs={12} sx={{textAlign: 'center'}}>
                    <Typography sx={styles.label(animateLabel)}>
                        {intl.formatMessage({id: 'prerelease'})}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography sx={styles.caption(animateCaption)}>
                        {intl.formatMessage({id: `${demoType}_hero_caption`}, {br: <br/>})}
                    </Typography>
                    <Typography sx={styles.desc(animateDesc)}>
                        {intl.formatMessage({id: `${demoType}_hero_desc`})}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={styles.imageContentWrapper} ref={node => {
                    if (node) {
                        setScrollTarget(node);
                    }
                }}>
                    {slidingAnimation ? slidingImages.map(img => <Box
                        sx={styles.slidingImageWrapper(isVisible(img), determineZIndex(img))}>
                        <Box component="img" src={img.path} sx={styles.image}/>
                    </Box>) : <>
                        <Box sx={styles.imageWrapper(animatePicture1)}>
                            <Box component="img" src={'/Fluxima_icon-blue.svg'} sx={styles.image}/>
                        </Box>
                        <Box sx={styles.imageWrapper(animatePicture2)}>
                            <Box component="img" src={'/Fluxima_icon-purple.svg'} sx={styles.image}/>
                        </Box>
                        <Box sx={styles.imageWrapper(animatePicture3)}>
                            <Box component="img" src={'/Fluxima_icon-white.svg'} sx={styles.image}/>
                        </Box>
                    </>}
                </Grid>
            </Grid>
        </Container>
    </Box>;
};