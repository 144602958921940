export const mainGraphOptions = {
    tooltip: {
        marker: {
            fillColors: ["#5048e5", "#10b981", '#ff9800'],
        },
    },
    legend: {
        show: false
    },
    stroke: {
        curve: 'smooth',
        colors: ["#5048e5", "#10b981", '#ff9800']
    },
    markers: {
        size: 7,
        shape: 'circle',
        colors: ["#5048e5", "#10b981", '#ff9800']
    },
    chart: {
        toolbar: {
            show: false
        },
        type: 'line',
        zoom: {
            enabled: false
        }
    },
    xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    }
};

export const mainGraphSeries = [
    {
        name: "Facebook",
        data: [4598, 791, 4338, 2111, 2180, 4792, 2934, 1136, 2600, 1874, 1764, 1790]
    },
    {
        name: "Google",
        data: [1444, 3453, 559, 1878, 3727, 955, 4087, 2932, 3867, 1471, 1482, 2673]
    },
    {
        name: "Company blog",
        data: [642, 3494, 4885, 3762, 2997, 4682, 4215, 1619, 3346, 4643, 3827, 3873]
    }
];

export const minorGraphOptions = {
    tooltip: {
        enabled: false
    },
    legend: {
        show: false
    },
    stroke: {
        width: 2,
        curve: 'straight',
        colors: "#5048e5",
    },
    chart: {
        toolbar: {
            show: false
        },
        type: 'line',
        zoom: {
            enabled: false
        }
    },
    grid: {
        show: false,
    },
    xaxis: {
        labels: {
            show: false,
        }
    },
    yaxis: {
        labels: {
            show: false,
        }
    }
}

export const minorGraphSeries = [
    {
        data: [2, 10, 7, 10, 1, 5, 4, 5, 4, 9]
    }
];
