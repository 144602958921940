import React, {useEffect} from "react";
import {Outlet} from 'react-router-dom';
import {MainDrawer} from "./Drawer";
import Box from "@mui/material/Box";
import {BasicToolbar} from "./BasicToolbar";
import SpeedDialMenu from "../common/SpeedDialMenu";
import {useDispatch, useSelector} from "react-redux";
import {initState} from "../../reducers/savedWorkflows";
import {MainModal} from "../featureTour/MainModal";
import {isMainModalShownOnce, showMainModal} from "../../reducers/featureTour";

export const drawerWidth = 280;

const styles = {
    dashboard: {
        bgcolor: 'backgroundSecondary.main',
        minHeight: '100vh',
        display: 'flex'
    },
    drawerOffset: drawerWidth => [{
        left: drawerWidth,
        width: '100%'
    }],
    mainContent: {
        padding: '1.5rem',
        position: 'relative',
        width: '100%',
        height: 'calc(100% - 64px)'
    }
}

export const MainLayout = () => {

    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const dispatch = useDispatch();
    const mainModalShownOnce = useSelector(isMainModalShownOnce);

    useEffect(() => {
        dispatch(initState());
        if (!mainModalShownOnce)
            dispatch(showMainModal());
    }, []);

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    return <Box sx={styles.dashboard}>
        <MainDrawer drawerWidth={drawerWidth} drawerOpen={drawerOpen} toggleDrawer={toggleDrawer}/>
        <Box sx={styles.drawerOffset(drawerWidth)}>
            <BasicToolbar toggleDrawer={toggleDrawer}/>
            <Box component="main" sx={styles.mainContent}>
                <Outlet/>
            </Box>
        </Box>
        <SpeedDialMenu/>
        <MainModal/>
    </Box>;
};
