import * as React from 'react';
import Divider from "@mui/material/Divider";

const styles = {
    commonDivider: {
        margin: 0,
        flexShrink: 0,
        borderWidth: '0px 0px thin',
        borderStyle: 'solid',
        borderColor: '#e6e8f0'
    },
};

export const CommonDivider = props => {
    const {customStyles = {}} = props;

    return <Divider sx={{
        ...styles.commonDivider,
        ...customStyles
    }}/>;
};
