import * as React from 'react';
import {Container, Grid, Typography} from "@mui/material";
import 'reactflow/dist/style.css';
import {useIntl} from "react-intl";
import {RegularButton} from "../common/RegularButton";
import {TextInput} from "../common/TextInput";
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import {IconFactory} from "../automation/common";
import Box from "@mui/material/Box";
import {BeaconPopup} from "../featureTour/BeaconPopup";

const styles = {
    actionPaper: {
        borderRadius: '0.5rem',
        padding: '1rem',
        cursor: 'pointer',
        boxShadow: 'rgb(0 0 0 / 5%) 0rem 0.25rem 1.6875rem 0.5rem',
        height: '100px'
    },
    icon: {
        width: '80px',
        height: '80px',
        color: 'green.main',
        marginRight: '1rem'
    },
    connectorName: {
        fontWeight: 'bold',
        fontSize: '18px'
    },
    connectorDesc: {
        fontSize: '14px'
    },
    settingsLabel: {
        fontWeight: 'bold',
        fontSize: '16px',
    },
    deleteIcon: {
        color: 'orangered',
        cursor: 'pointer'
    },
    nameWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    beaconWrapper: {
        marginLeft: '20px'
    },
};

export const WorkflowSelectStartConfiguration = props => {

    const intl = useIntl();
    const {onSubmit, back, action} = props;
    const [configState, setConfigState] = React.useState({
        endpoint: '',
        timeInterval: '15'
    });

    const [headers, setHeaders] = React.useState([
        {
            headerName: 'Content-type',
            headerValue: 'application/json'
        },
        {
            headerName: 'Accept',
            headerValue: 'application/json'
        }
    ]);

    const handleSubmit = () => {
        const nodeConfig = {
            ...configState,
            headers
        }
        onSubmit(nodeConfig);
    };

    const handleInputChange = name => event => {
        setConfigState({
            ...configState,
            [name]: event.target.value
        });
    };

    const handleHeaderInputChange = (name, index) => event => {
        setHeaders(headers.map((h, i) => {
            if (i === index)
                return {
                    ...h,
                    [name]: event.target.value
                };
            return h;
        }));
    };

    const deleteHeader = index => () => {
        const newState = [...headers];
        newState.splice(index, 1);
        setHeaders(newState);
    };

    const addHeader = () => {
        const newState = [...headers];
        newState.push({
            headerName: '',
            headerValue: ''
        });
        setHeaders(newState);
    }

    const renderHeaderRow = (header, index) => <Grid item xs={12}
                                                     container
                                                     alignItems='center'
                                                     justifyContent='center'
                                                     spacing={2}>
        <Grid item xs={3}>
            <TextInput label={'header_name'} onChange={handleHeaderInputChange('headerName', index)}
                       value={header.headerName}/>
        </Grid>
        <Grid item xs>
            <TextInput label={'header_value'} onChange={handleHeaderInputChange('headerValue', index)}
                       value={header.headerValue}/>
        </Grid>
        <Grid item>
            <HighlightOffRoundedIcon sx={styles.deleteIcon} onClick={deleteHeader(index)}/>
        </Grid>
    </Grid>;

    return <Grid container
                 style={{height: '100%'}}
                 flexDirection={'column'}
                 alignItems="center">
        <Grid item xs/>
        <Grid item>
            <Container maxWidth="md">
                <Grid container
                      alignItems='center'
                      justifyContent='center'
                      spacing={2}>
                    <Grid item xs={12} container alignItems='center'>
                        <Grid item>
                            {IconFactory[action.workflowActionType](styles.icon)}
                        </Grid>
                        <Grid item xs>
                            <Box sx={styles.nameWrapper}>
                                <Typography sx={styles.connectorName}>
                                    {action.name}
                                </Typography>
                                <Box sx={styles.beaconWrapper}>
                                    <BeaconPopup placement={'right-start'}
                                                 arrowPlacement={'right'}
                                                 label={'feature_tour_new_workflow_step2_label'}
                                                 text={'feature_tour_new_workflow_step2_desc'}
                                                 current={{stepName: 'newWorkflowStep2', path: '/workflow/new'}}
                                                 next={{stepName: 'newWorkflowStep3', path: '/workflow/new'}}
                                                 onNext={handleSubmit}
                                                 previous={{stepName: 'newWorkflowStep1', path: '/workflow/new'}}
                                                 onPrevious={back}/>
                                </Box>
                            </Box>
                            <Typography sx={styles.connectorDesc}>
                                {action.description}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={styles.settingsLabel}>
                            {intl.formatMessage({id: 'settings'})}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput label={'endpoint'} onChange={handleInputChange('endpoint')}
                                   value={configState.endpoint}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput label={'time_interval'} onChange={handleInputChange('timeInterval')}
                                   value={configState.timeInterval} type={'number'}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={styles.settingsLabel}>
                            {intl.formatMessage({id: 'headers'})}
                        </Typography>
                    </Grid>
                    {headers.map(renderHeaderRow)}
                    <Grid item xs={12}>
                        <RegularButton text={'add_more'} onClick={addHeader} variant={'text'}
                                       startIcon={<AddCircleOutlineRoundedIcon/>}/>
                    </Grid>
                    <Grid item xs={12} style={{textAlign: 'right'}}>
                        <RegularButton text={'back'} onClick={back} variant={'text'}
                                       customStyles={{marginRight: '10px'}}/>
                        <RegularButton text={'confirm'} onClick={handleSubmit}/>
                    </Grid>
                </Grid>
            </Container>
        </Grid>
        <Grid item xs/>
    </Grid>;
};