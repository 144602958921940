import * as React from 'react';
import {Grid, List, ListItem, Paper, Typography} from "@mui/material";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {getLogs} from "../../reducers/logs";
import Box from "@mui/material/Box";
import {CommonDivider} from "../common/CommonDivider";

const styles = {
    paper: {
        boxShadow: 'rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px',
        borderRadius: '1rem',
        margin: '1.5rem 0'
    },
    activationLabelWrapper: {
        padding: '1.5rem'
    },
    activationLabel: {
        fontWeight: 600,
        fontSize: '18px'
    },
    list: {
        margin: 0,
        padding: 0
    },
    listItem: beforeLast => [
        {
            padding: '12px 24px'
        },
        beforeLast && {
            borderBottom: '1px solid rgb(230, 232, 240)'
        }
    ],
    statusCode: code => [
        {
            textAlign: 'center',
            borderRadius: '12px',
            color: 'white',
            bgcolor: '#14b8a6',
            fontSize: '0.75rem',
            lineHeight: 2,
            fontWeight: 600,
            letterSpacing: '0.5px',
            padding: '0 8px',
            width: '45px'
        },
        code >= 400 && {
            bgcolor: 'orangered'
        }
    ],
    tableCellText: {
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: 1.57
    }
};

export const ConnectorDetailLogs = () => {

    const logs = useSelector(getLogs);
    const intl = useIntl();

    return <Paper sx={styles.paper}>
        <Box sx={styles.activationLabelWrapper}>
            <Typography sx={styles.activationLabel}>
                {intl.formatMessage({id: 'recent_logs'})}
            </Typography>
        </Box>
        <CommonDivider/>
        <List sx={styles.list}>
            {logs.map((log, index) => <ListItem sx={styles.listItem(index !== logs.length - 1)}>
                <Grid container
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}>
                    <Grid item xs={12} container>
                        <Grid item xs={1}>
                            <Typography sx={styles.tableCellText}>
                                {log.method}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={styles.tableCellText}>
                            <Typography sx={styles.statusCode(log.statusCode)}>
                                {log.statusCode}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography sx={styles.tableCellText}>
                                {log.path}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography sx={styles.tableCellText}>
                                {log.ipAddress}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography sx={styles.tableCellText}>
                                {log.timestamp}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </ListItem>)}
        </List>
    </Paper>;
};