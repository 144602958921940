import * as React from 'react';
import {useState} from 'react';
import {Dialog, DialogActions} from "@mui/material";
import 'reactflow/dist/style.css';
import {WorkflowActionList} from "./WorkflowActionList";
import {RegularButton} from "../common/RegularButton";
import {WorkflowActionConfiguration} from "./WorkflowActionConfiguration";

const styles = {
    modal: {
        '& div.MuiDialog-paper': {
            borderRadius: '0.5rem', boxShadow: '0 4px 12px rgb(0 0 0 / 8%)', padding: '24px', height: '80vh',
        }
    },
    dialogActions: {
        marginTop: '1rem'
    }
};

export const ConfigureWorkflowStepDialog = props => {

    const {open, onClose, addNewNodeHandler} = props;
    const [index, setIndex] = useState(0);
    const [selectedAction, setSelectedAction] = useState({});

    const selectOptions = [
        {
            value: 'create_customer',
            label: 'create_customer'
        },
        {
            value: 'link_debit_card',
            label: 'link_debit_card'
        },
        {
            value: 'send_message',
            label: 'send_message'
        },
        {
            value: 'retrieve_message',
            label: 'retrieve_message'
        },
        {
            value: 'charge_a_debit_card',
            label: 'charge_a_debit_card'
        },
        {
            value: 'send_invoice',
            label: 'send_invoice'
        },
    ];

    const [selectedOption, setSelectedOption] = useState(selectOptions[0]);

    const actionSelect = action => () => {
        setIndex(1);
        setSelectedAction(action);
    }

    const addNode = () => {
        addNewNodeHandler(selectedAction, selectedOption);
        closeHandler();
    };

    const back = () => setIndex(0);

    const resetState = () => {
        setIndex(0);
        setSelectedAction({});
        setSelectedOption(selectOptions[0]);
    };

    const closeHandler = () => {
        onClose();
        resetState();
    };

    return <Dialog
        fullWidth
        maxWidth={'lg'}
        sx={styles.modal}
        open={open}
        onClose={closeHandler}>
        {index === 0 && <WorkflowActionList onClick={actionSelect}/>}
        {index === 1 && <WorkflowActionConfiguration action={selectedAction}
                                                     selectOptions={selectOptions}
                                                     selectedOption={selectedOption}
                                                     setSelectedOption={setSelectedOption}/>}
        {index > 0 && <DialogActions sx={styles.dialogActions}>
            <RegularButton text={'back'} onClick={back} variant={'text'}/>
            <RegularButton text={'confirm'} onClick={addNode}/>
        </DialogActions>}
    </Dialog>;
};