import * as React from 'react';
import {useEffect} from 'react';
import {Grid, Paper, Typography} from "@mui/material";
import 'reactflow/dist/style.css';
import {useSelector} from "react-redux";
import {getAllWorkflowActions} from "../../reducers/workflowActions";
import {useIntl} from "react-intl";
import {Search} from "../common/Search";
import Box from "@mui/material/Box";
import {WorkflowActionTypes} from "../../constants";
import {CommonTabs} from "../common/CommonTabs";
import {IconFactory} from "./common";

const styles = {
    actionPaper: {
        borderRadius: '0.5rem',
        padding: '1rem',
        cursor: 'pointer',
        boxShadow: 'rgb(0 0 0 / 5%) 0rem 0.25rem 1.6875rem 0.5rem',
        height: '100px'

    },
    actionImageWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '30px',
        borderRadius: '1rem',
        backgroundColor: 'white',
        marginRight: '1rem'
    },
    actionImage: {
        width: 'auto',
        height: 'auto',
        maxHeight: '100%',
        maxWidth: '30px'
    },
    icon: {
        width: '30px',
        height: '30px',
        color: 'green.main',
        marginRight: '1rem'
    },
    caption: {
        fontWeight: 'bold',
        fontSize: '18px',
        marginBottom: '1rem'
    },
    connectorName: {
        fontWeight: 'bold',
        fontSize: '16px'
    },
    connectorDesc: {
        fontSize: '14px'
    },
};

export const WorkflowActionList = props => {

    const {onClick} = props;
    const actions = useSelector(getAllWorkflowActions);
    const intl = useIntl();
    const [searchString, setSearchString] = React.useState('');
    const [searchFilteredActions, setSearchFilteredActions] = React.useState(actions);
    const [tabValue, setTabValue] = React.useState(0);

    const tabs = [
        {label: 'general'},
        {label: 'third_party_actions'},
    ];

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        let filteredItems = actions;
        if (searchString !== '') {
            const sanitizedSearchString = searchString.toLowerCase();
            filteredItems = actions.filter(action =>
                action.name.toLowerCase().includes(sanitizedSearchString) ||
                action.description.toLowerCase().includes(sanitizedSearchString) ||
                action.keywords.some(keyword => keyword.includes(sanitizedSearchString))
            );
        }
        setSearchFilteredActions(filteredItems);
    }, [searchString]);

    const trimDescription = description => {
        if (description.length >= 100)
            return description.substring(0, 100) + '...';
        return description;
    }

    const resolveIcon = action => {
        if (action.workflowActionType === WorkflowActionTypes.INTEGRATION) {
            return <Box sx={styles.actionImageWrapper}>
                <Box component={'img'} src={action.logoPath} sx={styles.actionImage}/>
            </Box>
        } else {
            return IconFactory[action.workflowActionType](styles.icon)
        }
    }

    return <Grid container
                 spacing={2}
                 alignItems="center">
        <Grid item xs={12}>
            <Typography sx={styles.caption}>
                {intl.formatMessage({id: 'pick_action'})}
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Search value={searchString} setValue={setSearchString} width={'50%'}/>
        </Grid>
        <Grid item xs={12}>
            <CommonTabs tabs={tabs} tabValue={tabValue} handleChange={handleTabChange}
                        customStyles={{marginTop: '10px'}}/>
        </Grid>
        {searchFilteredActions.filter(action => {
            if (tabValue === 0)
                return action.workflowActionType !== WorkflowActionTypes.INTEGRATION;
            else
                return action.workflowActionType === WorkflowActionTypes.INTEGRATION;
        }).map(action => <Grid item xs={6}>
            <Paper sx={styles.actionPaper} onClick={onClick(action)}>
                <Grid container
                      style={{height: '100%'}}
                      justifyContent="center"
                      alignItems="center">
                    <Grid item>
                        {resolveIcon(action)}
                    </Grid>
                    <Grid item xs>
                        <Typography sx={styles.connectorName}>
                            {action.name}
                        </Typography>
                        <Typography sx={styles.connectorDesc}>
                            {trimDescription(action.description)}
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>)}
    </Grid>;
};