import * as React from 'react';
import {Grid, Paper, Switch, Typography} from "@mui/material";
import {useParams} from "react-router-dom";
import {useIntl} from "react-intl";
import {useDispatch} from "react-redux";
import {integrationActivated} from "../../reducers/integrations";
import {TextInput} from "../common/TextInput";
import {RegularButton} from "../common/RegularButton";
import {ChoiceButton} from "../common/ChoiceButton";

const styles = {
    grid: {
        marginTop: 0
    },
    paper: {
        padding: '1.5rem',
        boxShadow: 'rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px',
        borderRadius: '1rem',
    },
    activationLabel: {
        fontWeight: 600,
        fontSize: '18px'
    },
    settingLabelPositioning: {
        display: 'flex',
        alignItems: 'center'
    },
};

export const ConnectorDetailGeneral = () => {

    const {connectorId} = useParams();
    const dispatch = useDispatch();
    const intl = useIntl();
    const [integrationImplementation, setIntegrationImplementation] = React.useState('http');
    const [resultsPerRequest, setResultsPerRequest] = React.useState(10);

    const integrationOptions = [
        {value: 'http', i18n: 'integration_implementation_http'},
        {value: 'websocket', i18n: 'integration_implementation_websocket'},
    ];

    const handleInputChange = event => {
        setResultsPerRequest(event.target.value);
    };

    return <Grid container
                 justifyContent="center"
                 alignItems="center"
                 sx={styles.grid}
                 spacing={3}>
        <Grid item xs={12}>
            <Paper sx={styles.paper}>
                <Grid container
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}>
                    <Grid item xs={12}>
                        <Typography sx={styles.activationLabel}>
                            {intl.formatMessage({id: 'activation'})}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput label={'username'}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput label={'password'} type={'password'}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput label={'api_key'}/>
                    </Grid>
                    <Grid item xs={12}>
                        <RegularButton text={'activate'} customStyles={{float: 'right'}}
                                       onClick={() => dispatch(integrationActivated(connectorId))}/>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
        <Grid item xs={12}>
            <Paper sx={styles.paper}>
                <Grid container
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}>
                    <Grid item xs={12}>
                        <Typography sx={styles.activationLabel}>
                            {intl.formatMessage({id: 'default_integration_settings'})}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} container>
                        <Grid item xs={6} sx={styles.settingLabelPositioning}>
                            <Typography>
                                {intl.formatMessage({id: 'results_per_request'})}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput type={'number'} value={resultsPerRequest} onChange={handleInputChange} textAlign={'end'}/>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container>
                        <Grid item xs={6} sx={styles.settingLabelPositioning}>
                            <Typography>
                                {intl.formatMessage({id: 'integration_implementation_type'})}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <ChoiceButton selectedOption={integrationImplementation} options={integrationOptions}
                                          setValue={setIntegrationImplementation}/>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container>
                        <Grid item xs={6} sx={styles.settingLabelPositioning}>
                            <Typography>
                                {intl.formatMessage({id: 'expand_nested_objects'})}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Switch/>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <RegularButton text={'save'} customStyles={{float: 'right'}}/>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    </Grid>;
};