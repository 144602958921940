import {createSlice} from '@reduxjs/toolkit';

export const appNotificationSlice = createSlice({
    name: 'appNotification',
    initialState: {
        variant: '',
        messageCode: '',
        duration: null
    },
    reducers: {
        appNotification: (state, action) => {
            const {variant, messageCode, duration} = action.payload;
            state.variant = variant;
            state.messageCode = messageCode;
            state.duration = duration;
        }
    },
});

export const appNotificationSelector = state => state.appNotification;

const {appNotification} = appNotificationSlice.actions;
export function emitAppNotification(variant, messageCode, duration = 3000) {
    // alert(messageCode);
    return appNotification({
        variant,
        messageCode,
        duration
    });
}

export default appNotificationSlice.reducer;