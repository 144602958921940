import * as React from 'react';
import {Collapse, Grid, List, ListItem, Paper, Typography} from "@mui/material";
import {useIntl} from "react-intl";
import Box from "@mui/material/Box";
import {CommonDivider} from "../common/CommonDivider";
import {CommonVerticalStepper} from "../common/CommonVerticalStepper";
import {RegularButton} from "../common/RegularButton";
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

const styles = {
    paper: {
        boxShadow: 'rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px',
        borderRadius: '1rem',
    },
    activationLabelWrapper: {
        padding: '1.5rem'
    },
    activationLabel: {
        fontWeight: 600,
        fontSize: '18px'
    },
    list: {
        margin: 0,
        padding: 0
    },
    listItem: beforeLast => [
        {
            padding: '12px 24px'
        },
        beforeLast && {
            borderBottom: '1px solid rgb(230, 232, 240)'
        }
    ],
    expandIcon: expanded => ([
        {
            color: 'textColorPrimary.main',
            transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        },
        expanded && {
            transform: 'rotate(-180deg)',
        }
    ]),
    actionPaper: {
        boxShadow: 'none',
        borderRadius: '1rem',
        bgcolor: '#f3f4f6',
        padding: '1rem',
        margin: '1rem 0'
    },
    requestBodyText: {
        fontSize: '12px',
        fontWeight: '600'
    },
    requestBodyParamName: {
        fontSize: '12px',
    },
    flowDescription: {
        fontSize: '14px',
        marginBottom: '1rem'
    },
    collapsibleContent: {
        margin: '1rem 0'
    },
    stepDescription: {
        fontSize: '14px'
    },
};

export const ConnectorDetailActionFlows = props => {

    const {commonActionFlows} = props;
    const intl = useIntl();
    const [activeStep, setActiveStep] = React.useState(0);
    const [expandedFlow, setExpandedFlow] = React.useState('');

    const onClick = flowName => () => {
        setActiveStep(0);
        setExpandedFlow(expandedFlow === flowName ? '' : flowName);
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const renderSteps = flow => flow.steps.map((step, index) => {
        return {
            label: intl.formatMessage({id: step.name}),
            content: <>
                {step.foreach && <Typography sx={styles.stepDescription}>
                        {intl.formatMessage({id: 'step_foreach'})}
                    </Typography>}
                <Paper sx={styles.actionPaper}>
                    <Grid container
                          justifyContent="center"
                          alignItems="center">
                        <Grid item xs={12}>
                            <Typography sx={styles.requestBodyText}>
                                {intl.formatMessage({id: 'request_params'})}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <List>
                                {Object.entries(step.params).map(([key, value]) =>
                                    <ListItem>
                                        <Typography sx={styles.requestBodyText} component={'span'}>
                                            {key}
                                        </Typography>
                                        <Typography sx={styles.requestBodyParamName}
                                                    component={'span'}>
                                            &nbsp;{`${value}`}
                                        </Typography>
                                    </ListItem>)}
                            </List>
                        </Grid>
                    </Grid>
                </Paper>
                {index > 0 && <RegularButton text={'back'} onClick={handleBack} variant={'text'}/>}
                {index < flow.steps.length - 1 &&
                    <RegularButton text={'continue'} onClick={handleNext} endIcon={<ArrowForwardRoundedIcon/>}/>}
            </>
        };
    });

    return <Paper sx={styles.paper}>
        <Box sx={styles.activationLabelWrapper}>
            <Typography sx={styles.activationLabel}>
                {intl.formatMessage({id: 'common_action_flows'})}
            </Typography>
        </Box>
        <CommonDivider/>
        <List sx={styles.list}>
            {commonActionFlows.map((flow, index) => <ListItem
                sx={styles.listItem(index !== commonActionFlows.length - 1)}>
                <Grid container
                      justifyContent="center"
                      alignItems="center">
                    <Grid item xs={12} container onClick={onClick(flow.label)} style={{cursor: 'pointer'}}>
                        <Grid item xs>
                            <Typography>
                                {intl.formatMessage({id: flow.label})}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <ExpandMoreRoundedIcon sx={styles.expandIcon(expandedFlow === flow.label)}/>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Collapse in={expandedFlow === flow.label}>
                            <Box sx={styles.collapsibleContent}>
                                <Typography sx={styles.flowDescription}>
                                    {intl.formatMessage({id: flow.description})}
                                </Typography>
                                <CommonVerticalStepper steps={renderSteps(flow)} activeStep={activeStep}/>
                            </Box>
                        </Collapse>
                    </Grid>
                </Grid>
            </ListItem>)}
        </List>
    </Paper>
};