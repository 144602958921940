import * as React from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import EditIcon from '@mui/icons-material/Edit';
import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import WebhookRoundedIcon from '@mui/icons-material/WebhookRounded';
import ImportantDevicesRoundedIcon from '@mui/icons-material/ImportantDevicesRounded';
import HubRoundedIcon from '@mui/icons-material/HubRounded';
import {useDispatch} from "react-redux";
import {showMainModal} from "../../reducers/featureTour";
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';

const styles = {
    speedDial: {
        position: 'fixed',
        bottom: 32,
        right: 32,
        '& button.MuiSpeedDial-fab': {
            bgcolor: 'violet.main',
        }
    },
    icon: {
        color: 'violet.main',
    }
};

const SpeedDialMenu = () => {

    const intl = useIntl();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const actions = [
        {
            icon: <QuestionMarkRoundedIcon sx={styles.icon}/>,
            name: 'feature_tour',
            handler: () => dispatch(showMainModal())
        },
        {icon: <HomeRoundedIcon sx={styles.icon}/>, name: 'home', handler: () => navigate('/')},
        {icon: <ImportantDevicesRoundedIcon sx={styles.icon}/>, name: 'early_adopter'},
        {
            icon: <HubRoundedIcon sx={styles.icon}/>,
            name: 'source_of_truth',
            handler: () => navigate('/')
        },
        {icon: <WebhookRoundedIcon sx={styles.icon}/>, name: 'integrations', handler: () => navigate('/usecase1')},
    ];

    return <SpeedDial
        ariaLabel={'fluxima_speed_dial'}
        sx={styles.speedDial}
        icon={<SpeedDialIcon openIcon={<EditIcon/>}/>}>
        {actions.map((action) => (
            <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={intl.formatMessage({id: action.name})}
                onClick={action.handler}
            />
        ))}
    </SpeedDial>
};

export default SpeedDialMenu;
