import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router} from "react-router-dom";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import {CssBaseline, ThemeProvider} from "@mui/material";
import {muiTheme} from "./style/theme";
import {Provider} from "react-redux";
import store from './store/configureStore'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <Router>
            <ThemeProvider theme={muiTheme}>
                <CssBaseline/>
                <React.StrictMode>
                    <App/>
                </React.StrictMode>
            </ThemeProvider>
        </Router>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
