import {createSlice} from '@reduxjs/toolkit'

const now = new Date();
const formattedDate = () => `${now.toLocaleDateString('en-GB')} ${now.toLocaleTimeString('en-GB')}`;

export const logsSlice = createSlice({
    name: 'logs',
    initialState: {
        data: [
            {
                method: "GET",
                statusCode: 200,
                path: 'api/customer',
                ipAddress: '95.102.177.170',
                timestamp: formattedDate()
            },
            {
                method: "GET",
                statusCode: 200,
                path: 'api/product',
                ipAddress: '95.102.177.170',
                timestamp: formattedDate()
            },
            {
                method: "POST",
                statusCode: 202,
                path: 'api/order',
                ipAddress: '95.102.177.170',
                timestamp: formattedDate()
            },
            {
                method: "DELETE",
                statusCode: 200,
                path: 'api/order/1234',
                ipAddress: '95.102.177.170',
                timestamp: formattedDate()
            },
            {
                method: "POST",
                statusCode: 400,
                path: 'api/verification/document',
                ipAddress: '95.102.177.170',
                timestamp: formattedDate()
            },
            {
                method: "GET",
                statusCode: 200,
                path: 'api/order-history?from=1-1-2022',
                ipAddress: '95.102.177.170',
                timestamp: formattedDate()
            },
            {
                method: "POST",
                statusCode: 402,
                path: 'api/payment/',
                ipAddress: '95.102.177.170',
                timestamp: formattedDate()
            },
            {
                method: "PUT",
                statusCode: 200,
                path: 'api/subscription/9999',
                ipAddress: '95.102.177.170',
                timestamp: formattedDate()
            },
            {
                method: "GET",
                statusCode: 200,
                path: 'api/creditcard/balance',
                ipAddress: '95.102.177.170',
                timestamp: formattedDate()
            },
            {
                method: "GET",
                statusCode: 500,
                path: 'api/article/7e3204a5ea6dd8ee92d69f66cea789a9',
                ipAddress: '95.102.177.170',
                timestamp: formattedDate()
            },
        ]
    }
});

export const getLogs = state => state.logs.data;

export default logsSlice.reducer;