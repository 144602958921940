import * as React from 'react';
import {useIntl} from "react-intl";
import {Box, TextField, Typography} from "@mui/material";

const styles = {
    input: {
        width: '100%',
        borderRadius: '0.5rem',
        backgroundColor: 'white',
        "& .MuiOutlinedInput-root:hover": {
            "& > fieldset": {
                borderColor: "violet.main"
            }
        },
        "& fieldset.MuiOutlinedInput-notchedOutline": {
            borderColor: "#6366f1 !important"
        },
        "& label.MuiFormLabel-root.Mui-focused": {
            color: "violet.main"
        },
        '& .MuiInputBase-root': {
            borderRadius: '0.5rem',
        },
    },
    errorWrapper: {
        marginLeft: '4px',
        lineHeight: 1,
        minHeight: '17px'
    },
    errorText: {
        color: 'orangered',
        fontSize: '10px'
    },
    align: textAlign => ({
        '& .MuiInputBase-input': {
            textAlign
        }
    }),
}

export const TextInput = props => {

    const intl = useIntl();
    const {
        name,
        label,
        value,
        autoComplete,
        type = '',
        onChange,
        startAdornment,
        endAdornment,
        customInputStyles = {},
        autoFocus,
        textAlign = 'start',
        error,
        placeholder
    } = props;

    const composedProperties = {
        ...(label && {label: intl.formatMessage({id: label})}),
        name,
        value,
        autoComplete,
        autoFocus,
        type,
        sx: {
            ...styles.input,
            ...customInputStyles,
            ...styles.align(textAlign),
        },
        startAdornment,
        endAdornment,
        onChange,
        variant: 'outlined',
        size: 'small',
        placeholder
    }

    return <>
        <TextField {...composedProperties}/>
        <Box sx={styles.errorWrapper}>
            <Typography sx={styles.errorText} component={'span'}>
                {error && intl.formatMessage({id: error})}
            </Typography>
        </Box>
    </>;
};

